import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import styles from "../../programs/addProgram/AddProgram.module.css"
import {GrClose} from "react-icons/gr";
import {Icon} from "@iconify/react";
import {Field, Form, Formik} from "formik";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import {IInvestorResponse} from "../../../../types/investor.type";
import fundProductService from "../../../../services/fundProduct.service";
import investorService from "../../../../services/investor.service";
import Modal from "../../../../components/Modal";
import utils from "../../../../utils/utils";
import {toast} from "react-toastify";
import {nameRegExp, nameValidationMessage} from "../../../../utils";

interface IProps {
    openModal: boolean;
    onClose?: () => void;
    buttonText: string;
}

interface Option {
    fundName: string;
    fundProductId: string;
    minimumInvestment: number;
    fundSize: number;
}

const phoneRegExp = /^(234[1-9][0-9]{9}|(0)[0-9]{10}$)/
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function AddInvestor(props: IProps) {

    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<Option[]>([]);
    const [selectedValue, setSelectedValue] = useState<string>('');
    const [minimumInvestment, setMinimumInvestment] = useState<any>("");
    const [maximumInvestment, setMaximumInvestment] = useState<any>("");


    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("First name is required").matches(nameRegExp, nameValidationMessage),
        lastName: Yup.string().required("Last name is required").matches(nameRegExp, nameValidationMessage),
        email: Yup.string().email("Invalid email address").required("Email Address is required").matches(emailRegex, "Invalid email address"),
        phoneNumber: Yup.string().required("Phone number is required").matches(phoneRegExp, "Invalid phone number."),
        amountInvested1: Yup.string()
            .transform((originalValue) => originalValue?.replace(/,/g, '')?.trim())
            .test(
                'is-positive-number',
                'Initial payment must be a positive number',
                (value) =>
                    typeof value === 'string' &&
                    !isNaN(parseFloat(value)) &&
                    parseFloat(value) > 0 &&
                    !value.startsWith('0'))
            .required("Investment amount is required")
            .test(
                'minimum-maximum-investment',
                `Amount invested must be between minimum ${minimumInvestment} and maximum ${maximumInvestment} investment`,
                (value) => {
                    const amount = parseFloat(value);
                    return amount >= minimumInvestment && amount <= maximumInvestment;
                }
            ),
        fundProductName: Yup.string().required("Fund Product is required"),
    });


    const data = {
      pageSize: 100,
    };

    const handleModalClose = () => {
        props.onClose && props?.onClose();
        resetDropdownStates();
    };

    const resetDropdownStates = () => {
        setSelectedValue("");
    };

    const fetchFunds = async () => {

        try {
            const response = await fundProductService.viewAllFundProduct(data);
            console.log("this is fund data",response)
            setOptions(response.content);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchFunds().then();
    }, [props.openModal]);


    const handleSelectChange = (
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    ) => (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setSelectedValue(selectedValue);
        setFieldValue("fundProductName", selectedValue);

        const selectedOption = options.find(option => option.fundName === selectedValue);

        if (selectedOption) {
            setMinimumInvestment(selectedOption.minimumInvestment);
            setMaximumInvestment(selectedOption.fundSize);
        }
    };


    const handleSubmit = async (values: any) => {
        console.log(values.fundProductName);

        values.amountInvested = values.amountInvested1.replace(/,/g, "");

        delete values.amountInvested1

        const investor: IInvestorResponse = values;
        try {
            setIsLoading(true);
            const response = await investorService.addInvestor(investor);
            if (response) {
                handleModalClose();
                toast.success("Investor Created Successfully!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            try {
                await investorService.viewAllInvestor({});
            } catch (error) {
                // console.error("Error fetching investor data", error)
            }
        } catch (error) {
            utils.handleRequestError(error);
        } finally {
            setIsLoading(false);
            props.onClose && props?.onClose();
        }
    };

    return (
        <Modal isOpen={props.openModal} closeModal={handleModalClose}>
            <div id={'ckajs'} className=" h-auto p-10 ">
                <div id={'cdnsjk'}>
                    <div
                        id={'caosi'}
                        className=" flex justify-between  items-center px-5 pl-9 py-5 mb-5 -m-10 border-b border-[#D0D5DD]">
                        <h2 id={'adpowe'} className="font-medium text-lg ">Add Investor</h2>
                        <div id={'apcod'} className="cursor-pointer"
                             onClick={handleModalClose}
                        >
                            <GrClose id={'aijds'}/>
                        </div>
                    </div>
                </div>

                <div id={'acdsjk'} className="w-">
                    <Formik
                        initialValues={{
                            firstName: "",
                            lastName: "",
                            email: "",
                            phoneNumber: "",
                            amountInvested1: "",
                            fundProductName: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({errors, touched, setFieldValue}) => (
                            <Form>
                                <div id={'ajdk'}>
                                    <div id={'dknskn'} className="grid md:grid-cols-2 grid-cols-1 gap-3">
                                        <div id={'acdjo'} className={styles.field}>
                                            <label id={'akjdhcj'}>First Name</label>
                                            <Field
                                                type="text"
                                                name="firstName"
                                                placeholder="Enter First Name"
                                            />
                                            {errors.firstName && touched.firstName && (
                                                <div id={'caeiwo'} className={styles.error}>{errors.firstName}</div>
                                            )}
                                        </div>

                                        <div id={'ajdksa'} className={styles.field}>
                                            <label id={'iojwejds'}>Last Name</label>
                                            <Field
                                                type="text"
                                                name="lastName"
                                                placeholder="Enter Last Name"
                                            />
                                            {errors.lastName && touched.lastName && (
                                                <div id={'osdjfk'} className={styles.error}>{errors.lastName}</div>
                                            )}
                                        </div>

                                        <div id={'difjlkd'} className={styles.field}>
                                            <label id={'ofeirnfd'}>Email Address</label>
                                            <Field
                                                type="email"
                                                name="email"
                                                placeholder="Enter Email Address"
                                            />
                                            {errors.email && touched.email && (
                                                <div id={'ioerjofd'} className={styles.error}>{errors.email}</div>
                                            )}
                                        </div>

                                        <div id={'apeoe'} className={styles.field}>
                                            <label id={'apoeed'}>Phone Number</label>
                                            <Field
                                                type="text"
                                                name="phoneNumber"
                                                placeholder="Enter Phone Number"
                                            />
                                            {errors.phoneNumber && touched.phoneNumber && (
                                                <div id={'daowehd'} className={styles.error}>{errors.phoneNumber}</div>
                                            )}
                                        </div>

                                        <div id={'aeijdk'} className={styles.field}>
                                            <label id={'aheiujd'}> Fund Name </label>
                                            <div id={'ajndjsk'}>
                                                <select
                                                    name="fundProductName"
                                                    id="fundName"
                                                    className=" p-2 border rounded-md w-[370px]"
                                                    value={selectedValue}
                                                    onChange={handleSelectChange(setFieldValue)}
                                                >
                                                    <option value="" disabled selected>Select a fund</option>
                                                    {options && options.map((option) => (
                                                        <option key={option.fundName} value={option.fundName}>
                                                            {option.fundName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {errors.fundProductName && touched.fundProductName && (
                                                <div id={"dsnkd"} className={styles.error}>{errors.fundProductName}</div>
                                            )}
                                        </div>


                                        <div id={'dkjf'} className={styles.field}>
                                            <label id={'asdjk'}>Investment Amount</label>
                                            <Field
                                                type="text"
                                                name="amountInvested1"
                                                placeholder="Enter Investment Amount"
                                                onChange={(e: any) => {
                                                    const value = utils.formatValueWithComma(e)
                                                    setFieldValue("amountInvested1", value);
                                                }}
                                            />
                                            {errors.amountInvested1 && touched.amountInvested1 && (
                                                <div className={`${styles.errors} w-[90%]`}>{errors.amountInvested1}</div>
                                            )}
                                        </div>

                                    </div>
                                    <div id={"gfhdg"} className=" w-[750px] flex justify-end mt-4">
                                        <button
                                            id={'asdhje'}
                                            className=" bg-green-600 text-[white] w-[200px] h-[50px] rounded-md mt-4 "
                                            type="submit"
                                        >
                                            {isLoading ? (
                                                <div id={'adsjk'} className="flex items-center justify-center">
                                                    <Icon icon={loadingLoop} width={24} height={24}/>
                                                </div>
                                            ) : (
                                                "Add Investor"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
}

export default AddInvestor;
