import React, {useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from "./styles/ProfileDropDown.module.css";
import pen from "./assets/profileDropdown/pen.svg";
import {LuLogOut} from "react-icons/lu";
import AccountIcon from "../../icons/dropdownProfile/AccountIcon";
import InviteColleagueIcon from "../../icons/dropdownProfile/InviteColleagueIcon";
import ActivityIcon from "../../icons/dropdownProfile/ActivityIcon";
import {useAppSelector} from "../../../redux/hooks/hooks";
import {getUserRole} from "../../../utils";
import {roles} from "../../../utils/roles";
import dummyProfileImage from "../../../features/dashboard/assets/images/dummyProfileImage.svg";

interface IProps {
    name?: string;
    onLogoutClick?: () => void;
    close?: ()=> void;
}

const buttons = [
    {
        title: "Account",
        id: "Account",
        icon: <AccountIcon/>,
        to: "",
        role: [roles.PORTFOLIO_MANAGER, roles.SUPER_ADMIN, roles.INSTITUTE_ADMIN, roles.TRAINEE]
    },
    {
        title: "Invite Colleague",
        id: "InviteColleague",
        icon: <InviteColleagueIcon/>,
        to: "/invite-colleague",
        role: [roles.INSTITUTE_ADMIN, roles.PORTFOLIO_MANAGER, roles.SUPER_ADMIN]
    },
    {
        title: "View Institute",
        id: "ViewInstitute",
        icon:  <ActivityIcon/>,
        to: "/view",
        role: [roles.INSTITUTE_ADMIN]
    },
    {
        title: "Activity Log",
        id: "ActivityLog",
        icon: <ActivityIcon/>,
        to: "",
        role: [roles.PORTFOLIO_MANAGER, roles.SUPER_ADMIN, roles.INSTITUTE_ADMIN, roles.TRAINEE]
    },
];

function ProfileDropdown({name, onLogoutClick, close}: IProps) {
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.auth);
    const fullName = user?.name;
    const email = user?.email;
    const profileDropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target as Node)) {
                close && close();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const handleButtonClick = (to: string) => {
        if (to) {
            navigate(to);
        }
    };

    const userRole = getUserRole();
    const filteredButtons = buttons.filter((button) => userRole && button.role.includes(userRole as roles));


    return (
        <div id={'ProfileDropdownMainDiv'} className={`${styles.top}`} ref={profileDropdownRef}>
            {/*<img id={`backgroundImg`} src={background} alt={`background photo`}/>*/}
            <div/>
            <div id={`profilePhotoDiv`} className={`${styles.profilePhoto}`}>
                <img id={`profilePhoto`} src={dummyProfileImage} alt={`profile photo`}/>
                <img id={`pen`} src={pen} alt={`edit icon`}/>
                <div id={`fullNameDiv`}>
                    <p id={`fullName`} className={styles.name}>{fullName}</p>
                    <p id={`email`} className={styles.email}>{email}</p>
                </div>
                <div id={`buttonsDiv`} className={`mt-[10px] absolute`}>
                    {filteredButtons.map((button, index) => (
                        <button
                            id={`button${button.id}`}
                            key={`button${button.id}aj`}
                            className={`${styles.buttons}`}
                            onClick={() => handleButtonClick(button.to)}
                        >
                            {button.icon}
                            <span id={`${button.id}spanTitle`} className={`pl-2`}>{button.title}</span>
                        </button>
                    ))}
                </div>
            </div>
            <div id={`logoutDiv`} className={styles.logout}>
                <button id={`onLogoutClick`} onClick={onLogoutClick}>
                    <LuLogOut id={`LuLogOut`} />
                    <span id={`logoutSpan`}>Logout</span>
                </button>
            </div>
        </div>
    );
}
export default ProfileDropdown;
