import React, {CSSProperties} from 'react';

interface CardProps {
  // children?: ReactNode;
  src?: any;
  title?: string;
  amount?: number;
  text?: string;
  style?: CSSProperties;
  id: string;
}

const TraineeCard: React.FC<CardProps> = ({ title, id, amount, src, style, text }) => {
  return (
    <div id={`skald${id}`} className="bg-white rounded-lg shadow-md p-6 h-[120px] mt-5 w-[70%]">
      <div id={`hvgv${id}`} >
        <img id={`nutcr${id}`} src={src} alt="" />
        <h2 id={`oiuhigd${id}`}>{title}</h2>
        <h1 id={`xszdsg${id}`}>{text}</h1>
        <p id={`${id}bhbhbj`}>{amount}</p>
        <div id={`${id}jdk`} style={style}/>
      </div>
    </div>
  );
};

export default TraineeCard;
