import {ApiResponse, IAllInstitute} from "../types/institute.type";
import ApiInstance from "./api";
import {IAddProgramRequest, IProgramData, IViewProgramRequest} from "../types/program.type";
import {TableData} from "../types/commons.type";
import {programAction} from "../redux/slices/programSlice";
import {store} from "../redux/store";
import apiRequest from "./apiRequest.service";


class ProgramService {
    private apiInstance: ApiInstance;

    constructor() {
        this.apiInstance = new ApiInstance();
    }

    public async addProgram(data: IAddProgramRequest): Promise<ApiResponse> {
        try {
            const apiInstance = new ApiInstance(sessionStorage.getItem("token"));
            const response = await apiInstance.getInstance().post("/program/add", data);
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async viewAllProgram(request: IAllInstitute): Promise<TableData<IProgramData>> {
        try {
            const apiInstance = new ApiInstance(sessionStorage.getItem("token"));
            const response = await apiInstance.getInstance().post("/program/view-all",  request);
            store.dispatch(programAction.setProgram(response.data.data));
            return response.data.data;
        } catch (err) {
            throw err;
        }
    }

    public async viewProgram(viewProgramRequest: IViewProgramRequest, token: string | undefined): Promise<any> {
        try {
            const apiInstance = new ApiInstance(sessionStorage.getItem("token"));
            const response = await this.apiInstance.getInstance().post("/program/view", viewProgramRequest, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    }
                }
            );
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async getAllPrograms(data: any): Promise<any>{
        const endpoint= `/program/view-all`
        return apiRequest.request(endpoint, "post", data)
    }

    public async editProgram(data: any): Promise<any>{
        const endpoint= `/program/edit`
        return apiRequest.request(endpoint, "post", data)
    }


}

const programService = new ProgramService();

export default programService;
