import React from 'react';
import logoutStyle from "./Logout.module.css";
import {GrClose} from "react-icons/gr";
import Modal from "../../../../components/Modal";
import authService from "../../../../services/auth.service";
import {useNavigate} from "react-router-dom";
import {clearData} from "../../../../storage";
import { persistor } from "../../../../redux/store";

interface IProps {
    showOpenModal: boolean;
    setShowOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function Logout({ showOpenModal, setShowOpenModal }: IProps) {
    const navigate = useNavigate();

    const handleCloseModal = () => {
        setShowOpenModal(false);
    };

    const handleLogout = () => {
        authService.logout();
        clearData();
        persistor.purge();
        localStorage.removeItem('persist:root');
        navigate('/login');
        setShowOpenModal(false);
    };

    return (
        <Modal
            isOpen={showOpenModal}
            closeModal={handleCloseModal}
        >
            <div id={'logoutModal'} className={logoutStyle.logoutModal}>
                <div id={'closeIconDiv'} className={logoutStyle.closeIconDiv}>
                    <GrClose
                        id={'GrClose'}
                        className={logoutStyle.closeIcon}
                        onClick={handleCloseModal}
                    />
                </div>
                <div id={'confirmationText'} className={logoutStyle.confirmationText}>
                    <p id={'LearnSpace'}>Log out from LearnSpace</p>
                    <h5 id={'leave'}> Are you sure you want to leave now? </h5>
                </div>

                <div id={'logoutButtons'} className={logoutStyle.logoutButtons}>
                    <button
                        id={'handleLogout'}
                        className={logoutStyle.logout}
                        onClick={handleLogout}
                    >
                        Logout
                    </button>

                    <button
                        id={'cancel'}
                        className={logoutStyle.cancel}
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default Logout;
