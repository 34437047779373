import apiRequest from "./apiRequest.service";

class TraineeService {

    // consider mapping later!
    public async referTrainee(data: any){
        const endpoint = `/learnspace/trainee/refer`;
        return apiRequest.request(endpoint, "post", data)
    }

    public async getCohorts(data: any){
        const endpoint = `/cohorts/view-all-institute`;
        return apiRequest.request(endpoint, "post", data);
    }

    public async getTrainee(data: any){
        const endpoint = `learnspace/trainee/view`;
        return apiRequest.request(endpoint, "post", data);
    }

    public async addTrainee(data: any){
        return apiRequest.request(`/learnspace/trainee/add`, "post", data);
    }

    public async allTraineesInCohort(data: any){
        return apiRequest.request(`/learnspace/trainee/trainees`, "post", data);
    }

    public async getTraineeLoanReferral(data: any){
        const endpoint = `/learnspace/trainee/loan-referral`;
        return apiRequest.request(endpoint, "post", data);
    }

    public async verifyBvn(data:any){
        return apiRequest.request(`/learnspace/trainee/verify-bvn`, "post", data);
    }

    public async verifyNin(data:any){
        return apiRequest.request(`/learnspace/trainee/verify-nin`, "post", data);
    }

    public async completeLoanApplication(data:any){
        return apiRequest.request(`/learnspace/trainee/add/additional-details`, "post", data);
    }


    public async verify(data: any){
        const endpoint = `/learnspace/trainee/verif`;
        return apiRequest.request(endpoint, "post", data)
    }

    public async acceptReferral(data: any){
        return apiRequest.request(`learnspace/trainee/accept-referral`, "post", data)
    }

    public async confirmIdentity(data: any){
        return apiRequest.request(`/learnspace/trainee/confirm-identity`, "post", data)
    }

    public async confirmLoanApplication(data: any){
        return apiRequest.request(`/learnspace/trainee/confirm-loan-application`, "post", data)
    }
}

const traineesService = new TraineeService();
export default traineesService;