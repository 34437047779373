import apiRequest from "./apiRequest.service";


class ColleagueService {
    public async getAllColleague(){
        const endpoint = `/user/invited`;
        return apiRequest.request(endpoint, "post", {})
    }

    public async inviteColleague(values: any): Promise<any> {
        const endpoint = "/user/invite";
        return apiRequest.request(endpoint, "post", values.members[0])
    }



}

const colleagueService = new ColleagueService();

export default colleagueService;