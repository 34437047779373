import {buttonNames} from "../../../utils/roles";
import {cohortActions} from "../../../redux/slices/cohortSlice";
import {loanActions} from "../../../redux/slices/loanSlice";
import {instituteActions} from "../../../redux/slices/institutesSlice";
import {store} from "../../../redux/store";
import {programAction} from "../../../redux/slices/programSlice";

export const cohortButtonName = (buttonName: string) => {
    switch (buttonName) {
        case buttonNames.INCOMING_COHORT:
        case buttonNames.CURRENT_COHORT:
        case buttonNames.GRADUATED_COHORT:
            store.dispatch(cohortActions.cohortSideBarButton(buttonName));
            break;
        case buttonNames.PROGRAMS:
            store.dispatch(programAction.programSideButton(buttonName));
    }
};

export const loanButtonName = (buttonName: string) => {
    switch (buttonName) {
        case buttonNames.AWAITING_DISBURSAL:
        case buttonNames.LOAN_REQUEST:
        case buttonNames.LOAN_OFFER:
        case buttonNames.DECLINED_REQUEST:
        case buttonNames.LOAN_PRODUCT:
           store.dispatch(loanActions.loanSideBarButton(buttonName));
            break;
    }
}

export const instituteButtonName = (buttonName: string) => {
    switch (buttonName) {
        case buttonNames.INSTITUTES:
        case buttonNames.INVITED_INSTITUTES:
        case buttonNames.DEACTIVATED_INSTITUTES:
        case buttonNames.INSTITUTES_DETAILS:
        case buttonNames.PROGRAMS:
        case buttonNames.ALL_COHORT:
        case buttonNames.TRAINEES:
        case buttonNames.ADMINS:
            store.dispatch(instituteActions.instituteSideBarButton(buttonName));
            break;
    }
}

 export const programsCohortButtonName = (buttonName: string) => {
    switch (buttonName) {
        case buttonNames.ALL_COHORT:
        case buttonNames.INCOMING_COHORT:
        case buttonNames.CURRENT_COHORT:
        case buttonNames.GRADUATED_COHORT:
            store.dispatch(cohortActions.programsCohort(buttonName));

    }
};
