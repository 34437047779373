import React, {useState} from 'react';
import ViewLoanOffer from "./ViewLoanOffer";


const Index = () =>{
    // const [showModal, setShowModal] = useState(false)
    return (
        <div id={"avjkd"}>
             <ViewLoanOffer/>
        </div>
    )
}
export default  Index;