import React from "react";
import Layout from "../../../../components/reusables/layout/Layout";
import InstituteSideBar from "./InstituteSideBar";

const Index = () => {
    return (
        <Layout>
            <InstituteSideBar/>
        </Layout>
    )


}
export default Index;