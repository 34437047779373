import React, {useEffect, useState} from 'react';
import IncomingCohortTable from "../IncomingCohortInProgram";
import {TableData} from "../../../../../types/commons.type";
import {emptyIncomingCohortPage, viewIncomingCohortDetails} from "../../../../../types/cohort/incomingCohort.type";
import {getInstituteId, getProgramId} from "../../../../../utils";
import cohortService from "../../../../../services/cohort.service";
import Skeleton from "@mui/material/Skeleton";
import {useAppSelector} from "../../../../../redux/hooks/hooks";
import utils from "../../../../../utils/utils";
import IncomingCohortInProgramTable from "./IncomingCohortInProgramTable";
import LoadingLoop from "../../../../../components/loadingLoop/LoadingLoop";


interface IProps {
}

function IncomingCohortInProgramIndex(props: IProps) {
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [viewIncomingCohortDetails, setIncomingCohortData] = useState<TableData<viewIncomingCohortDetails>>(emptyIncomingCohortPage);
    const instituteId = getInstituteId();
    const programId = useAppSelector(state => state.program.programId);
    const cohortStatus = "INCOMING";
    const pmInstituteId = useAppSelector(state => state.institute.instituteId)
    const data = {programId: programId, cohortStatus, instituteId: instituteId ?? pmInstituteId};




    function formatCohortInProgram(data: { content: any[] | null | undefined }) {
        return {
            ...data,
            content: Array.isArray(data?.content) ? data.content.map(item => ({
                ...item,
                tuition: utils.formatAmount(item.tuition),
                startDate: utils.formatDateFromArray(item.startDate),
                endDate: utils.formatDateFromArray(item.endDate),
                totalAmountDisbursed: utils.formatAmount(item.cohortLoanDetailResponse.totalAmountDisbursed),
                totalAmountRequested: utils.formatAmount(item.totalAmountRequested),
                totalAmountRepaid: utils.formatAmount(item.cohortLoanDetailResponse.totalAmountRepaid),
                totalOutstanding: utils.formatAmount(item.cohortLoanDetailResponse.totalOutstanding),
                repaymentPercentage: `${item.cohortLoanDetailResponse.repaymentPercentage} %`,
            })) : <LoadingLoop/>
        };
    }



    const viewIncomingCohortDetailsData = formatCohortInProgram(viewIncomingCohortDetails!);

    const fetchIncomingCohort = async () => {
        try {
            setSkeletonLoader(true);
            const incomingCohorts = await cohortService.viewIncomingCohortInProgram(data);
            setIncomingCohortData(incomingCohorts);
        } catch (error) {
            utils.handleRequestError(error);
        }finally{
            setSkeletonLoader(false);
        }
    };


    const tableData = useAppSelector(state => state.cohort.incomingCohortData)

    useEffect(() => {
        if (tableData === undefined) {
            fetchIncomingCohort().then();
        }
    }, []);

    return (
        <>
            {  skeletonLoader ?
                <div id={'skeletonLoader'} className={`flex flex-col gap-3`}>
                    <Skeleton variant="rectangular" height={40}/>
                    <Skeleton variant="rectangular" height={40}/>
                    <Skeleton variant="rectangular" height={40}/>
                    <Skeleton variant="rectangular" height={40}/>
                    <Skeleton variant="rectangular" height={40}/>
                </div>:
                <IncomingCohortInProgramTable incomingCohortInProgramTableData={viewIncomingCohortDetailsData}/>
            }
        </>
    );
}

export default IncomingCohortInProgramIndex;