import React, {useEffect, useState,} from 'react';
import LearnSpaceTable from "../../../../components/reusables/table/Table";
import {IColumnProps, TableData} from "../../../../types/commons.type";
import noInstitute from "../../assets/images/empty_state_institute.svg";
import ViewLoanProductIndex from "./LoanProductModal";
import {useAppSelector} from "../../../../redux/hooks/hooks";

interface IProps {
    //loanProductTableData: TableData<any>;
    loanProductTableData: any;
}

const LoanProductTable = (props: IProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [loanProductId, setLoanProductId] = useState("")


    function handleViewLoanProductDetails(loanProductId: string) {
        setLoanProductId(loanProductId);
        setIsOpen(true);

    }

        const columns: IColumnProps[] = [

            {
                title: 'Loan Product Name',
                selector: 'loanProductName',
                sortable: true,
            },
            {
                title: 'Start Date',
                selector: 'createdAtDate',
                sortable: true,
            },

            {
                title: 'Tenor',
                selector: 'tenor',
                sortable: true,
            },
            {
                title: 'Interest Rate',
                selector: 'interestRate',
                sortable: true,
            },

            {
                title: 'Amount Disbursed',
                selector: 'amountDisbursed',
                sortable: true,
            },


            {
                title: 'Amount repaid',
                selector: 'amountRepaid',
                sortable: true,
            },
            {
                title: 'Amount Earned',
                selector: 'amountEarned',
                sortable: true,
            }


        ]

const getSearchString = useAppSelector((state)=> state.search.searchString)

        return (
            <>
                <LearnSpaceTable
                    columns={columns}
                    tableData={props.loanProductTableData}
                    searchString={getSearchString}
                    searchColumn="loanProductName"
                    emptyTable={{
                        image: noInstitute,
                        title: "No Record Found",
                        subText: "No Loan Product Available",
                        text: "",
                    }}
                    path={""}
                    handleRowClick={(row: any) => {
                        handleViewLoanProductDetails(row.loanProductId);
                    }}
                />
                <ViewLoanProductIndex
                    isOpen={isOpen}
                    closeModal={() => {
                        setIsOpen(false);
                    }}
                    openEditModal={() => {
                        setIsOpenEdit(true);
                    }}
                    loanProductId={loanProductId}/>
            </>

        )
}


export default LoanProductTable;
