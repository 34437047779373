import styled from "styled-components";

export const ValidationContainer = styled.div`
  background-color: #101828;
  color: #ffffff;
  // opacity: 95%;
  padding: 14px;
  width: 17rem;
  border-radius: 8px;

  p {
    padding: 5px;
    color: #ffffff;
    font-size: 0.85rem;
  }

  @media (max-width: 769px) {
    width: 18rem;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 320px) {
    width: 14rem;
  }
`;

export const ValidationItem = styled.div`
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 0.2rem;

  img {
    width: 1.2rem;
    padding-left: 5px;
  }

  @media (max-width: 769px) {
  }
`;

export const ValidationSpan = styled.span`
  margin-left: 5px;
  font-family: "IBM Plex Sans", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;

  @media (max-width: 769px) {
  }

  @media (max-width: 320px) {
    font-size: 0.75rem;
  }
`;
