import React, {useMemo} from 'react';
import {useLocation} from "react-router-dom";
import { GiCheckMark } from "react-icons/gi";

function Stepper() {
    const {pathname} = useLocation();

    const steps = [
        {label: 'Loan Application Details'},
        {label: 'Verify Your Identity'},
        {label: 'Confirm Your Identity'},
        {label: 'Additional Information'},
        {label: 'Confirm Application Details'},
    ];

    const activeStep = useMemo(() => {
        switch (pathname) {
            case "/loan-application/id-verification":
                return 1;
            case "/loan-application/confirm-id":
                return 2;
            case "/loan-application/additional-info":
                return 3;
            case "/loan-application/confirm-details":
                return 4;
            case "/loan-application":
            default:
                return 0;
        }
    }, [pathname]);

    return (
        <div className="w-80">
            <div className="grid md:fixed">
                {
                    steps.map((step, index) => (
                            <StepperItem
                                key={index}
                                label={step.label}
                                last={index === steps.length - 1}
                                active={index === activeStep}
                                completed={activeStep > index}
                            />
                        )
                    )
                }
            </div>
        </div>
    );
}

interface StepperItemProps {
    last: boolean;
    label: string;
    completed?: boolean;
    active?: boolean;
}

function StepperItem({last, label, completed, active}: StepperItemProps) {
    return (
        <div id={`akfjhakjfkh`} className="flex gap-3 min-h-[80px]">
            <div id={`kjskdjd`} className="relative">
                <div id={`svkdhvkjhsk`}
                    className={`w-6 aspect-square rounded-full overflow-hidden  border-2 flex items-center justify-center ${completed || active ? "bg-primary-50 border-primary" : "bg-white border-zinc-300"}`}
                >
                    {
                        completed ? (
                            <GiCheckMark id={`njknskjkas`} className="text-primary"/>
                        ) : (
                            <div id={`hkshdkjf`} className={`w-2 aspect-square rounded-full ${completed || active ? "bg-primary" : "bg-zinc-300"}`}/>
                        )
                    }
                    {
                        last || (
                            <div id={`ksajdakljkds`} className={`absolute top-7 left-1/2 -translate-x-1/2 h-[calc(100%_-_32px)] w-[2px] ${completed || active ? "bg-primary" : "bg-zinc-300"}`}/>
                        )
                    }
                </div>
            </div>
            <div id={`hdskjhkl`} className="flex-1">
                <p id={`skhdfksjhkj`}>{label}</p>
            </div>
        </div>
    )
}

export default Stepper;