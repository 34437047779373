import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {buttonNames} from "../../utils/roles";
import {InstituteResponse} from "../../types/institute.type";
import {PURGE} from "redux-persist";

interface InstituteState {
    // institute?: TableData<InviteData>;
    institute?: any;
    buttonName: string;
    instituteId: string;
    instituteName: string;
    instituteDetails?: InstituteResponse | undefined;
    pageNumber?: number | undefined;
    anInstitute?: any;
    isInstituteUpdated?: boolean;
}

const initialState: InstituteState = {
    buttonName: buttonNames.INSTITUTES_DETAILS,
    instituteId: "",
    instituteName: "",
    instituteDetails: undefined,
};

const institutesSlice = createSlice({
    name: 'institute',
    initialState,
    reducers: {
        setInstitutes: (state, action: PayloadAction<any>) => {
            state.institute = action.payload;
        },

        instituteSideBarButton: (state, action: PayloadAction<buttonNames>) => {
            state.buttonName = action.payload;
        },
        setInstituteName: (state, action: PayloadAction<any>) => {
            state.instituteName = action.payload;
        },

        setInstituteId: (state, action: PayloadAction<any>) => {
            state.instituteId = action.payload;
        },

        setInstituteDetails: (state, action: PayloadAction<InstituteResponse>) => {
            state.instituteDetails = action.payload;
        },
        setPageNumber: (state, action: PayloadAction<any>) => {
            state.pageNumber = action.payload;
        },
        setAnInstitute: (state, action: PayloadAction<any>) => {
            state.anInstitute = action.payload;
        },
        setInstituteUpdate: (state, action: PayloadAction<boolean>) => {
            state.isInstituteUpdated = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.institute = undefined;
            state.buttonName= "";
            state.instituteName = "";
            state.instituteId = "";
            state.instituteDetails = undefined;
            state.pageNumber = undefined;
            state.anInstitute = undefined;
            state.isInstituteUpdated = false;
        });
    },
});
export const instituteActions = institutesSlice.actions;

export default institutesSlice.reducer;