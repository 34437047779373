import React, {useEffect, useRef, useState} from 'react';
import styles from './CustomDropdown.module.css';
import {BsChevronDown} from 'react-icons/bs';

const CustomDropdown = ({
                            options,
                            selectedOption,
                            onSelect,
                            isOpen,
                            toggleDropdown,
                            placeholder,
                            fieldName,
                            setFieldValue,
                            isError
                        }: any) => {
    const [ inputValue, setInputValue] = useState(selectedOption);
    const [isArrowRotated, setArrowRotation] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                toggleDropdown();
                setArrowRotation(false);
            }
        };
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, toggleDropdown]);

    const handleOptionSelect = (option: any) => {
        onSelect(option);
        toggleDropdown();
        setArrowRotation(false);
    };

    return (
        <div id={`customSelectContainer`} className={styles.customSelectContainer} ref={dropdownRef}>
            <div id={`inputContainer`} className={styles.inputContainer}>
                <input
                    id={`input`}
                    type="text"
                    autoComplete="off"
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onClick={toggleDropdown}
                    style={{
                        borderColor: isError ? 'red' : 'inherit',
                    }}
                />
                <div
                    id={'ArrowRotation'}
                    className={`${styles.arrowIcon} ${isArrowRotated ? styles.rotateIcon : ''}`}
                    onClick={() => {
                        setArrowRotation(!isArrowRotated);
                        toggleDropdown();
                    }}
                >
                    <BsChevronDown id={'BsChevronDown'}/>
                </div>
            </div>
            {isOpen && (
                <div id={'isOpen'} className={`${styles.modeDropdown} ${styles.alignRight}`}>
                    {options.map((option: any) => (
                        <div
                            id={`${option}optionsjubhn`}
                            key={option}
                            className={styles.modeOption}
                            onClick={() => {
                                handleOptionSelect(option);
                                fieldName && setFieldValue(fieldName, option);
                                setInputValue(option);
                            }}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;
