import React from 'react';
import {toast, ToastOptions} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

enum ToastTypes {
    SUCCESS = "success",
    ERROR = "error"
}

interface ToastProps{
    content: string,
    type: ToastTypes
}

const options: ToastOptions = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}
const Toast = ({content, type}:ToastProps) => {
    return (
        <>
            {type === ToastTypes.SUCCESS
                ? toast.success(content, options)
                : type === ToastTypes.ERROR
                    ? toast.error(content, options)
                        : null}
        </>
    );
}

export {Toast, ToastTypes};
