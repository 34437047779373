import React from "react";
import {IColumnProps} from "../../../../types/commons.type";
import {investments} from "./investmentMockData";
import LearnSpaceTable from "../../../../components/reusables/table/Table";
import {useAppSelector} from "../../../../redux/hooks/hooks";

interface IProps {}

export interface IInvestmentData {
  investmentId: string;
  productName: string;
  investmentDate: string;
  amountInvested?: string;
  maturityDate?: string;
  interestEarned?: string;
  amountPaidOut?: string;
}

const InvestmentDataTable: React.FC<IProps> = () => {
  const getSearchString = useAppSelector(state => state.search.searchString)

  const handleViewInvestmentDetails = (investment:any) => {
    console.log("Investments = ", investment)
  }

  const columns: IColumnProps[] = [
    {
      title: "Product Name ",
      selector: "productName",
      sortable: true,
    },
    {
      title: "Investment Date",
      selector: "investmentDate",
      sortable: true,
    },

    {
      title: "Maturity Date",
      selector: "maturityDate",
      sortable: true,
    },
    {
      title: "Amount Invested",
      selector: "amountInvested",
      sortable: true,
    },
    {
      title: "Interest Earned",
      selector: "interestEarned",
      sortable: true,
    },
    {
      title: "Amount Paid Out",
      selector: "amountPaidOut",
      sortable: true,
    },
  ];

  return (
    <>
      <LearnSpaceTable
        columns={columns}
        tableData={investments}
        searchString={getSearchString}
        searchColumn="productName"
        emptyTable={{
          image: "",
          title: "No Investments Yet",
          subText: "Investments added will show here",
          text: "",
        }}
        path={""}
        handleRowClick={(row: any) => {
          handleViewInvestmentDetails(row);
        }}
      />
    </>
  );
};

export default InvestmentDataTable;
