import React, {useEffect, useState} from "react";
import Modal from "../../../../components/Modal";
import styles from "../styles/CreateCohortModal.module.css";
import {GrClose} from "react-icons/gr";
import {Icon} from "@iconify/react";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import {Field, FieldArray, Form, Formik} from "formik";
import * as Yup from "yup";
import {CohortRequest} from "../../../../types/cohort/cohort.type";
import cohortService from "../../../../services/cohort.service";
import utils from "../../../../utils/utils";
import programService from "../../../../services/program.service";
import {Toast, ToastTypes} from "../../../../components/Toast";
import {RiDeleteBinLine} from "react-icons/ri";
import {useAppDispatch} from "../../../../redux/hooks/hooks";
import {cohortActions} from "../../../../redux/slices/cohortSlice";
import {getInstituteId} from "../../../../utils";
import {store} from "../../../../redux/store";

interface IProps {
    openModal: boolean;
    onClose?: () => void;
}

interface Option {
    programId: string;
    programName: string;
    programDuration: number;
    numberOfCohort: number;
    amountDisbursed: number | null;
    amountRepaid: number | null;
    outstandingAmount: number | null;
    programStatus: string;
    programMode: string;
    programType: string;
    deliveryType: string;
    description: string;
}

const validationSchema = Yup.object().shape({
    cohortName: Yup.string().required("Cohort name is required").matches(/^[a-zA-Z0-9\s\-_'']+$/g,"Character's allowed are -, a-z, A-Z, 0-9,  and spaces"),
    tuitionAmount:  Yup.string()
    .transform((originalValue) =>
        originalValue?.replace(/,/g, "")?.trim()
    )
    .test(
        "is-positive-number",
        "Tuition Amount must be a positive number",
        (value) =>
            typeof value === "string" &&
            !isNaN(parseFloat(value)) &&
            parseFloat(value) > 0 &&
            !value.startsWith("0")
    )
    .required("Tuition Amount is required"),
    loanBreakdowns: Yup.array().of(
        Yup.object().shape({
            itemName: Yup.string().required("Fee Name is required"),
            itemAmount: Yup.string()
                .transform((originalValue) =>
                    originalValue?.replace(/,/g,)?.trim()
                )
                .test(
                    "is-positive-number",
                    "Item amount must be a positive number",
                    (value) =>
                        typeof value === "string" &&
                        !isNaN(parseFloat(value)) &&
                        parseFloat(value) > 0 &&
                        !value.startsWith("0")
                )
                .required("Item amount is required")
                .min(0, "Amount must be greater than or equal to 0"),
        })
    ),
});

function CreateCohortModal(props: IProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<Option[]>([]);
    const [selectedValue, setSelectedValue] = useState<string>('');
    const [showLoanBreakdowns, setShowLoanBreakdowns] = useState(false);
    const [breakDownSelectError, setBreakDownSelectError] = useState("");
    const [loanBreakDownForError, setLoanBreakDownForError] = useState<any[] | undefined>([])
    const dispatch = useAppDispatch();

    const initialValues = {
        programId: "",
        description: "",
        cohortName: "",
        tuitionAmount: "",
        loanBreakdowns: [],
    };

    const fetchPrograms = async () => {
        const data = {instituteId: getInstituteId()};
        try {
            const response = await programService.getAllPrograms(data);
            setOptions(response.data.content);
        } catch (err) {
            utils.handleRequestError(err)
        }
    };

    useEffect(() => {
        fetchPrograms().then();
    }, [props.openModal]);

    const handleSelectChange = (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => (
        event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setSelectedValue(selectedValue);
        setFieldValue("programId", selectedValue);
    };

    const handleSubmit = async (values: any): Promise<void> => {
        const instituteId = getInstituteId();
        console.log(values);
        values.tuitionAmount = values.tuitionAmount.replace(/,/g, "");
        values.loanBreakdowns.forEach((item: any) => {
            item.itemAmount = item.itemAmount.replace(/,/g, "");
        });

        const requestBody: CohortRequest = {instituteId, ...values};

        if (requestBody?.loanBreakdowns?.length === 0 ){
            setBreakDownSelectError("Loan Break down is required. ")
        }
        try {
            setIsLoading(true);
            const response = await cohortService.createCohort(requestBody)
            if (response) {
                await fetchIncomingCohort();
                Toast({content: "Cohort Created Successfully", type: ToastTypes.SUCCESS});
                handleCloseModal()
            }
        } catch (err:any) {
            utils.handleRequestError(err);
        } finally {
            setIsLoading(false);
        }
    };


    const instituteId = getInstituteId();
    const cohortStatus = "INCOMING";
    const data = {instituteId: instituteId, cohortStatus};

    const fetchIncomingCohort = async () => {
        try {
            const incomingCohorts = await cohortService.viewIncomingCohort(data);
            dispatch(cohortActions.setIncomingCohortData(incomingCohorts));
        } catch (error) {
            utils.handleRequestError(error);
        }
    };

    // const fetchAllCohorts = async () => {
    //     try {
    //         const allCohorts = await cohortService.viewIncomingCohortInProgram(data);
    //         dispatch(cohortActions.setCohortDetails(allCohorts));
    //     } catch (error) {
    //         utils.handleRequestError(error);
    //     }
    // };

    const handleCloseModal = () => {
        setSelectedValue('');
        setBreakDownSelectError("")
        setShowLoanBreakdowns(false)
        setIsLoading(false);
        props.onClose?.();
    };


    return (
        <Modal isOpen={props.openModal}>
            <div id={'createCohortContainerOuterContainer'} className={styles.createCohortContainer}>
                <div id={'headingsOuterDiv'} className="border border-b-2 p-4 flex justify-between">
                    <div id={'headingsInnerDiv'} className={styles.headings}>
                        <h2 id={'CreateCohortH2Tap'}>Create Cohort</h2>
                    </div>
                    <div id={'onCloseDiv'} className="mr-5 mt-2 cursor-pointer"
                         onClick={handleCloseModal}
                    >
                        <GrClose id={'GrClose'}/>
                    </div>
                </div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({errors, touched, setFieldValue, values, resetForm}) => (
                        <Form id={'formTag'}>
                            <div id={'formMainDiv'} className="flex justify-between mb-[10px] items-baseline w-[700px]">
                                <div id={'formBaseline'} className="flex items-baseline"></div>
                            </div>
                            <div id={'formTextAreaSectionDiv'} className={styles.textAreaSection}>
                                <div id={'formFieldDiv'} className={styles.field}>
                                    <div id={'formFieldInnerDiv'} className="grid grid-row-1 grid-flow-col gap-5">
                                        <div id={'cohortNameDiv'}
                                             className={`my-3 ${styles.field} ${styles.cohortName}`}>
                                            <label id={'cohortNameLabel'}>Cohort Name</label>
                                            <Field
                                                type="text"
                                                name="cohortName"
                                                placeholder="E.g Maven"
                                                style={{borderColor: errors.cohortName && touched.cohortName ? "red" : "inherit"}}
                                            />
                                            {errors.cohortName && touched.cohortName && (<div id={'cohortNameErrorDiv'}
                                                                                              className={styles.error}>{errors.cohortName}</div>)}
                                        </div>
                                        <div id={'programDIv'} className="mt-4">
                                            <label id={'programLabel'}> Program </label>
                                            <div id={'selectProgramDiv'}>
                                                <select
                                                    name="program"
                                                    id="program"
                                                    className="p-2 border rounded-md w-[300px]"
                                                    value={selectedValue}
                                                    onChange={handleSelectChange(setFieldValue)}
                                                >
                                                    <option id={'selectedDisabled'} value="" disabled selected>Select a
                                                        program
                                                    </option>
                                                    {options?.map((option) => (
                                                        <option id={`${option.programId}${option.programName}`}
                                                                key={option.programId} value={option.programId}>
                                                            {option.programName}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.programId && touched.programId && (<div id={'cnsajdkkcjkla'}
                                                                                                           className={styles.error}>{errors.programId}</div>)}

                                            </div>
                                        </div>
                                    </div>
                                    <div id={'DescriptionMainDiv'} className="my-5">
                                        <label id={'DescriptionLabel'}>Cohort Description (Optional)</label>
                                        <Field
                                            as="textarea"
                                            name="description"
                                            placeholder="About this cohort"
                                            className={`${styles.textArea} ${styles.field}`}
                                        />
                                    </div>
                                    <div id={'setUpOuterDiv'}>
                                        <div id={'setUpCohortDiv'} className={`font-bold`}>Setup Cohort Fee Breakdown</div>
                                        <div className={`max-h-[220px] overflow-auto`}>
                                            {/*Immutable tuitiion*/}
                                            <div className="flex items-center justify-center gap-6 mr-[2.2rem]">
                                                <div className={`my-3 ${styles.field} ${styles.tuition}`}>
                                                    <label>Fee Name</label>
                                                    <Field
                                                        type="text"
                                                        name="tuition"
                                                        placeholder="Tuition"
                                                        readOnly
                                                        style={{backgroundColor: "#FAFBFC"}}
                                                    />
                                                </div>

                                                <div className={`my-3 ${styles.field} ${styles.tuitionAmount}`}>
                                                    <label> Fee Amount </label>
                                                    <Field
                                                        type="text"
                                                        name="tuitionAmount"
                                                        placeholder="Enter tuition amount"
                                                        style={{borderColor: errors.tuitionAmount && touched.tuitionAmount ? "red" : "inherit"}}
                                                        onChange={(e: any) => { setFieldValue(`tuitionAmount`, utils.formatValueWithComma(e)) }}
                                                    />
                                                    {errors.tuitionAmount && touched.tuitionAmount && (
                                                        <div className={styles.error}>{errors.tuitionAmount}</div>)}

                                                </div>
                                            </div>


                                            {/* FieldArray for cohortFees */}
                                            <FieldArray name="loanBreakdowns">
                                                {({remove, push}) => (
                                                    <>
                                                        {showLoanBreakdowns &&
                                                            <>
                                                                {values.loanBreakdowns.map((_, index) => (
                                                                    <div id={`${index}fieldInput`} key={index}
                                                                         className={`flex gap-6 justify-center items-center ${styles.fieldInput}`}>
                                                                        <div id={`${index}innerDiv`} className={`flex flex-col`}>
                                                                            <Field
                                                                                type="text"
                                                                                name={`loanBreakdowns.${index}.itemName`}
                                                                                placeholder="Enter fee name"
                                                                                style={{
                                                                                    borderColor:
                                                                                        (errors.loanBreakdowns as any)?.[index]?.itemName &&
                                                                                        (touched.loanBreakdowns as any)?.[index]?.itemName
                                                                                            ? "red"
                                                                                            : "inherit",
                                                                                }}
                                                                            />
                                                                            {(errors.loanBreakdowns as any)?.[index]?.itemName &&
                                                                                (touched.loanBreakdowns as any)?.[index]?.itemName && (
                                                                                    <div
                                                                                        id={`${index}loanBreakdownsErrorDiv`}
                                                                                        className={styles.error}>
                                                                                        {(errors.loanBreakdowns as any)[index].itemName}
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                        <div id={`${index}amountLoanBreakdownsDIv`}
                                                                             className={`flex flex-col gap-1`}>
                                                                            <Field
                                                                                type="text"
                                                                                name={`loanBreakdowns.${index}.itemAmount`}
                                                                                placeholder="Enter amount"
                                                                                style={{
                                                                                    borderColor:
                                                                                        (errors.loanBreakdowns as any)?.[index]?.itemAmount &&
                                                                                        (touched.loanBreakdowns as any)?.[index]?.itemAmount
                                                                                            ? "red"
                                                                                            : "inherit",
                                                                                }}
                                                                                onChange={(e: any) => {
                                                                                    const formattedValue = utils.formatValueWithComma(e)
                                                                                    setFieldValue(`loanBreakdowns.${index}.itemAmount`, formattedValue);
                                                                                }}
                                                                            />
                                                                            {(errors.loanBreakdowns as any)?.[index]?.itemAmount &&
                                                                                (touched.loanBreakdowns as any)?.[index]?.itemAmount && (
                                                                                    <div
                                                                                        id={`loanBreakdowns${index}errorDiv`}
                                                                                        className={styles.error}>
                                                                                        {(errors.loanBreakdowns as any)[index].itemAmount}
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                        <span
                                                                            id={`${index}spanDeleteButton`}
                                                                            className={styles.deleteButton}
                                                                            onClick={() => {
                                                                                remove(index);
                                                                            }}
                                                                        >
                                                                      <RiDeleteBinLine id={'RiDeleteBinLine'}/>
                                                                    </span>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        }
                                                        {/* Add Item button */}
                                                        <button
                                                            id={'setShowLoanBreakdownsButton'}
                                                            type="button"
                                                            onClick={() => {
                                                                setShowLoanBreakdowns(true);
                                                                push({itemName: "", itemAmount: ""});
                                                            }}
                                                            className={styles.addItem}
                                                        >
                                                            <span id={'SpanPlusSign'}
                                                                  className={styles.plusSign}> + </span>
                                                            {showLoanBreakdowns
                                                                ? values.loanBreakdowns.length > 0
                                                                    ? "Add another item"
                                                                    : "Add item"
                                                                : "Add item"}
                                                        </button>
                                                        { values.loanBreakdowns.length === 0 && breakDownSelectError !== '' &&(
                                                            <div className={styles.error}>{breakDownSelectError}</div>)}
                                                    </>
                                                )}
                                            </FieldArray>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id={'createCohortBtnDiv'}
                                 className="mt-[20px] flex justify-end align-middle border border-b-3 p-2">
                                <button id={'createCohortButton'} className={styles.createCohortBtn} type="submit">
                                    {isLoading ? (
                                        <div id={'loadingLoopDiv'} className="flex items-center justify-center">
                                            <Icon id={'loadingLoopIcon'} icon={loadingLoop} width={24} height={24}/>
                                        </div>
                                    ) : (
                                        "Create Cohort"
                                    )}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
}

export default CreateCohortModal;
