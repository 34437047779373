import React, {useEffect} from "react";
import styles from "./styles/InviteMidSection.module.css";
import {HiArrowLeft} from "react-icons/hi";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks/hooks";
import {RootState} from "../../../../redux/store";
import colleagueService from "../../../../services/colleague.service";
import {setColleagues} from "../../../../redux/slices/colleagueSlice";
import noRecord from "../../assets/images/noRecord.svg";
import utils from "../../../../utils/utils";

interface InviteData {
  id?: number;
  fullName: string;
  role: string;
  email: string;
  dateJoined: any;
}

interface InviteMidSectionProps {
  openModal: () => void;
}

function InviteMidSection({ openModal }: InviteMidSectionProps) {
  const dispatch = useAppDispatch();
  const foundColleagues = useAppSelector((state: RootState) => state.colleague.colleagues);

  function EmptyInvite() {
    return (
      <div id={"emptyInvitednfk"} className={styles.emptyInvite}>
        <img id={"noRecordImg"} src={noRecord} alt="no-record"/>
        <p id={"noRecordYet"}>No Record Yet</p>
        <p id={"inviteWillAppearHere"}>Colleagues invited will appear here</p>
      </div>
    );
  }

  useEffect(() => {
    const fetchColleagues = async () => {
      try {
        const response = await colleagueService.getAllColleague();
        dispatch(setColleagues(response.content));
      } catch (error) {
        utils.handleRequestError(error)
      }
    };
    fetchColleagues().then();
  }, [dispatch]);

  function InvitesPresent() {
    return (
      <div id={'ovnd'} className={styles.invitesPresent}>
        <table id={'innvd'} className={styles.table}>
          <thead id={'fnd'}>
            <tr id={'ijdfb'}>
              <th id={'jdb'}>Full Name</th>
              <th id={'ate'}>Email</th>
              <th id={'nsdnb'}>Role</th>
              <th id={'djfb'}>Date Joined</th>

            </tr>
          </thead>
          <tbody id={'ksbd'}>
            {foundColleagues && foundColleagues.map((row: InviteData) => (
              <tr id={'sdm'} key={row.id}>
                <td id={'akmo'}>{`${row.fullName}` || "User"}</td>
                <td id={'adkkk'}>{row.email}</td>
                <td id={'kbi'}>{row.role}</td>
                <td id={'kbif'}>
                  {utils.formatColleaguesDate(row.dateJoined)}
                </td>
               </tr>
             ))}
          </tbody>
        </table>
      </div>
    );
  }

  const navigate = useNavigate();

  return (
    <div id={'hdf'} className={styles.wrapper}>
      <div id={'dfibe'} className={styles.top}>
        <div
            id={'kabfe'}
          className={styles.backArrowContainer}
          onClick={() => navigate("/")}
        >
          <HiArrowLeft id={'fnre'} className={styles.backArrow} />
        </div>
        <button id={'mopr'} className={styles.inviteColleague} onClick={openModal}>
          Invite Colleague
        </button>
      </div>

      <div id={'dnir'} className={styles.inviteMidContent}>
        <div id={'nivr'} className={styles.inviteColleaguePrompt}>
          <p id={'nioce'}>Invite Colleague</p>
          <p id={'noer'}>
            Invite Colleague, this will help them add more value to your
            institution and oversight function
          </p>
        </div>

        {foundColleagues?.length ? <InvitesPresent/> : <EmptyInvite />}
      </div>
    </div>
  );
}

export default InviteMidSection;
