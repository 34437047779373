import React, {ChangeEvent, useEffect, useLayoutEffect, useRef, useState} from "react";
import camera from "../../assets/camera.svg";
import exlink from "../../assets/external-link.svg";
import pen from "../../assets/green pen.svg";
import frame from "../../assets/Frame 1000005084.svg";
import ViewAllField from "./ViewTrainingField";
import InstituteLoanDetails from "./InstituteLoanDetails";
import instituteService from "../../../../services/institute.service";
import {Link} from "react-router-dom";
import {InstituteDetails} from "../ViewAllTrainingInstitute/InstituteData";
import utils from "../../../../utils/utils";
import UpdateInstitute from "../updateInstitute/UpdateInstitute";
import BreadCrumbs from "../../../../components/breadCrumb/BreadCrumbs";
import {getInstituteId, getUserRole} from "../../../../utils";
import {roles} from "../../../../utils/roles";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks/hooks";
import {instituteActions} from "../../../../redux/slices/institutesSlice";

const ViewTraining: React.FC = () => {
    const cameraInputRef = useRef<HTMLInputElement | null>(null);
    const frameInputRef = useRef<HTMLInputElement | null>(null);
    const [cameraImage, setCameraImage] = useState<string | null>(null);
    const [frameImage, setFrameImage] = useState<string | null>(null);
    const [fieldName, setFieldName] = useState("InstituteDetails");
    const [open, setOpen] = useState(false);

    const dispatch = useAppDispatch();
    const close = () => {
        setOpen(false);
    }

    const openEdit = () => {
        setOpen(true);
    }

    const handleCameraClick = () => {
        if (cameraInputRef.current) {
            cameraInputRef.current.click();
        }
    };

    const handleFrameClick = () => {
        if (frameInputRef.current) {
            frameInputRef.current.click();
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>, setImage: React.Dispatch<React.SetStateAction<string | null>>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };



    const pmInstituteId = useAppSelector(state => state.institute.instituteId);
    const instituteId = getInstituteId() ?? pmInstituteId;

    const data = useAppSelector(state => state.institute.anInstitute);
    const status = useAppSelector(state => state.institute.isInstituteUpdated);
    const fetchData = async () => {
        const requestBody: InstituteDetails = {instituteId: instituteId};
        try {
            await instituteService.getInstitute(requestBody);
        } catch (error) {
            utils.handleRequestError(error);
        }
    };

    useEffect(() => {
        fetchData().then();
        dispatch(instituteActions.setInstituteUpdate(false))
    }, [status]);

    return (
        <>
            {/*<div id={"coidl"} className={`ml-8 p-8 pb-0`}>*/}
            {/*    <BreadCrumbs/>*/}
            {/*</div>*/}
            <div id={'MainDiv'} className="flex mt-8 mx-auto max-w-7xl gap-7 overflow-hidden">
                <div id={'InnerDiv'} className=" relative w-[100%] p-8 pt-0">
                    <div id={'ThirdDiv'} className="bg-white h-[250px] p-3 rounded-sm relative ml-5">
                        <div
                            id={'rounded'}
                            className="bg-[#D9DDE4]  h-[150px] rounded-lg flex flex-col justify-center items-center relative overflow-hidden">
                            {/*<img id={'penImg'} src={pen} alt="" className="ml-auto absolute right-5 top-1"/>*/}
                            {cameraImage && (
                                <img
                                    id={'cameraImage'}
                                    src={cameraImage}
                                    alt="Selected Camera"
                                    className="ml-auto w-[100%]"
                                />
                            )}
                            {!cameraImage && (
                                <>
                                    <img
                                        id={'cameraImageTag'}
                                        src={camera}
                                        alt=""
                                        onClick={handleCameraClick}
                                        style={{cursor: "pointer"}}
                                    />
                                    <input
                                        id={'fileInput'}
                                        type="file"
                                        style={{display: "none"}}
                                        ref={cameraInputRef}
                                        accept="image/*"
                                        onChange={(e) => handleFileChange(e, setCameraImage)}
                                    />
                                </>
                            )}

                            {cameraImage && <p id={"csndjk"}>Image size 749 x 136 </p> && ""}
                        </div>
                        <div id={'between'} className={`w-full flex justify-between items-end`}>
                            <div id={'frameImage'}
                                 className="absolute top-[8rem] left-4 w-[10rem] flex justify-between items-end gap-5 ">
                                {frameImage ? (
                                    <img id={'cameraImageTag'} src={frameImage} alt="Selected Frame" className="w-36"/>
                                ) : (
                                    <img
                                        id={'frame'}
                                        src={frame}
                                        alt=""
                                        onClick={handleFrameClick}
                                        style={{cursor: "pointer"}}
                                    />
                                )}
                                <input
                                    id={'fileInputRef'}
                                    type="file"
                                    style={{display: "none"}}
                                    ref={frameInputRef}
                                    accept="image/*"
                                    onChange={(e) => handleFileChange(e, setFrameImage)}
                                />
                                {/*<img id={'penImage'} src={pen} alt="" className="absolute top-24 left-32"/>*/}
                                <div id={'gap'} className="mt-auto flex flex-col  gap-1.5">
                                    <h2 id={'instituteNameH'}
                                        className="w-60 size-[24px] font-sans font-[700] ">{data?.instituteName}</h2>
                                    <div id={'flexDiv'} className="flex flex-row gap-6">
                                        <Link id={'link'} className="flex flex-row gap-1" to={data?.website}>
                                            <p id={'website'} className="font-sans text-[#0EAA4F]">Visit website</p>
                                            <img id={'exLink'} src={exlink} alt={``}/>
                                        </Link>
                                        {data?.status && (
                                            <p id={'statusPTag'} className="font-ibm text-[#0EAA4F]">
                                                {data?.status.toLowerCase().replace(/\b\w/g, (c: string) => c.toUpperCase())}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {getUserRole() === roles.INSTITUTE_ADMIN ?
                                <button
                                    id={'btnINSTITUTE_ADMIN'}
                                    className={`absolute right-0 bottom-0 px-4 py-3 bg-[#0D9B48] text-white border rounded-[4px] mx-3`}
                                    onClick={openEdit}
                                >
                                    Edit Institute Details
                                </button> : <div id={'djaks'}/>
                            }
                        </div>
                    </div>
                    <div
                        id={'DivFieldName'}
                        className="flex gap-1.5 justify-items-start ml-5 bg-white border-b-2 border-[#D0D5DD] pl-3 pt-[20px]">
                        <button id={'fieldNameButton'} onClick={() => setFieldName("InstituteDetails")}
                                className={`font-sans font-[500] text-${fieldName === "InstituteDetails" ? "#0D9B48" : "gray-700"} text-size-[16] p-2 ${fieldName === "InstituteDetails" ? "border-b-2 border-[#0D9B48] text-[#0D9B48]" : ""}`}>
                            Institute Details
                        </button>
                        <button id={'LoanDetailsButton'} onClick={() => setFieldName("LoanDetails")}
                                className={`font-sans font-[500] text-${fieldName === "LoanDetails" ? "#0D9B48" : "gray-700"} text-size-[16] p-2 ${fieldName === "LoanDetails" ? "border-b-2 border-[#0D9B48] text-[#0D9B48]" : ""}`}>
                            Loan Details
                        </button>
                    </div>
                    <div id={'ViewAllField'}>
                        {
                            fieldName === "InstituteDetails" ? <ViewAllField/> : <InstituteLoanDetails/>
                        }
                    </div>
                </div>
                <UpdateInstitute open={open} close={close}/>
            </div>
        </>
    );
};

export default ViewTraining;
