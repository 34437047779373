import {redirect, useNavigate} from "react-router-dom";
import {useAppSelector} from "../redux/hooks/hooks";
import React, {useEffect} from "react";
import AdminRoutes from "./AdminRoutes";
import ManagerRoutes from "./ManagerRoutes";
import {roles} from "../utils/roles";
import TraineeRoutes from "./TraineeRoutes";


function Protected() {
    const navigate = useNavigate();
    const { isAuthenticated, user, param } = useAppSelector((state) => state.auth);
    const userRoles = user?.realm_access.roles;
    // const userRole = userRoles?.[0];
    const path = param?.path

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }

        if(!!path && isAuthenticated){
            redirect(`/${path}`);
        }
    }, [isAuthenticated, navigate]);

    return isAuthenticated ? (
        <>
            {Array.isArray(userRoles) && userRoles.includes(roles.TRAINEE) && <TraineeRoutes />}
            {Array.isArray(userRoles) && userRoles.includes(roles.PORTFOLIO_MANAGER) && <ManagerRoutes />}
            {Array.isArray(userRoles) && userRoles.includes(roles.SUPER_ADMIN) && <ManagerRoutes />}
            {Array.isArray(userRoles) && userRoles.includes(roles.INSTITUTE_ADMIN) && <AdminRoutes />}
            {/*{userRole === roles.SUPER_ADMIN && <ManagerRoutes />}*/}
            {/*{userRole === roles.INSTITUTE_ADMIN && <AdminRoutes />}*/}
        </>
    ) : null;
}

export default Protected;



