import React from 'react';
import LearnSpaceTable from "../../../../components/reusables/table/Table";
import {IColumnProps} from "../../../../types/commons.type";
import {useNavigate} from "react-router-dom";
import noTrainee from "../../assets/images/empty_state_institute.svg";

interface IProps{
    tableData: any;
}


const columns: IColumnProps[] = [
    {
        title: 'Trainee Name',
        selector: 'traineeName',
        sortable: true,
    },
    {
        title: 'Program Name',
        selector: 'programName',
        sortable: true,
    },
    {
        title: 'Cohort Name',
        selector: 'cohortName',
        sortable: true,
    },
    {
        title: 'Program Start Date',
        selector: 'programStartDate',
        sortable: true,
    },
    {
        title: 'Amount Requested',
        selector: 'amountRequested',
        sortable: true,
    },
    {
        title: 'Referral Date',
        selector: 'referralD',
        sortable: true,
    },
    {
        title: 'Identity Verified',
        selector: 'identityVerified',
        sortable: true,
    },
    {
        title: 'Credit Status Check',
        selector: 'creditStatusCheck',
        sortable: true,
    },
    {
        title: 'Status',
        selector: 'status',
        sortable: true,
    },
]
function LoanRequestTable(props: IProps) {

    const navigate = useNavigate();
    const handleViewTraineeRequest = (row: {id: string}) =>{
        navigate("/trainee-details")
    }


    return (
            <LearnSpaceTable
                columns={columns}
                emptyTable={{
                    image: noTrainee,
                    title: "No Trainees Record",
                    subText: "",
                    text: "",
                }}
                path={""}
                handleRowClick={(row: any) => handleViewTraineeRequest(row)}
                tableData={props.tableData}
            />
    );
}

export default LoanRequestTable;