import React, {useState} from 'react';
import TraineeForm from "../form/TraineeForm";
import styles from "../styles/ConfirmIdentity.module.css";
import {Icon} from "@iconify/react";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/hooks/hooks';
import traineeService from "../../../../services/trainee.service";
import {getUserAssociatedData} from "../../../../utils";
import utils from "../../../../utils/utils";

function ConfirmIdentity() {
    
    const [isLoading, setLoading] = useState(false);

    const renderDetail = (label: string, value: any) => (
        <div id={`dfnkvnk`} className={``} key={label}>
            <div id={`bdfjbjdf`}>
                <p id={`vbjdhdf`} className={styles.label}>{label}</p>
                <div id={`cjbjdvbkbjkd`} className={styles.value}>{value || 'N/A'}</div>
            </div>
        </div>
    );

    const traineeId = getUserAssociatedData()?.trainee?.traineeId;

    const confirmIdentity = async(confirmation: boolean)=> {
        try{
            const response = await traineeService.confirmIdentity({
                traineeId: traineeId,
                isConfirmed: confirmation,
            })
        }
        catch (e:any){
            utils.handleRequestError(e);
        }
    }

    const navigate = useNavigate();
    const handleBackButton = () => {
        confirmIdentity(false).then(navigate("/loan-application/id-verification")!);
    }

    const goToNextPage = () => {
        confirmIdentity(true).then(navigate("/loan-application/additional-info")!);
    }

    const traineeDetails: any = useAppSelector(state => state.trainee.bvnResponse?.data);

    const details = [
        {label: 'First Name', value: traineeDetails?.firstName},
        {label: 'Last Name', value: traineeDetails?.lastName},
        {label: 'Middle Name', value: traineeDetails?.middleName},
        {label: 'Gender', value: traineeDetails?.gender},
        {label: 'Date of Birth', value: traineeDetails?.dateOfBirth},
        {label: 'Marital Status', value: traineeDetails?.maritalStatus},
        {label: 'Nationality', value: traineeDetails?.nationality},
        {label: 'State of Origin', value: traineeDetails?.stateOfOrigin},
        {label: 'State of Residence', value: traineeDetails?.stateOfResidence},
        {label: 'Phone Number', value: traineeDetails?.phoneNumber1},
        {label: 'Residential Address', value: traineeDetails?.residentialAddress},
    ]

    return (
        <TraineeForm formName={`Confirm Identity`}>
            <div id={`cjsljdhbl`}>
                <div id={`ncsljvei`} className={`p-6`}>
                    <div id={`cnsijkd`}>
                        <div id={`cwoeuibfj`} className={`${styles.detail}`}>
                            {details.map(({label, value}) => renderDetail(label, value))}
                        </div>
                    </div>
                </div>
                <div id={`cjdbivrijfk`} className="mt-[5px] flex md:flex-row flex-col-reverse justify-between align-middle border border-b-3 p-4">
                    <button
                        id={`coisjdivnfjdnk`}
                        className={styles.back}
                        onClick={handleBackButton}
                    >
                        No, this isn't me
                    </button>
                    <button
                        id={`cjdnjnoifdkjl`}
                        className={styles.continue}
                        onClick={goToNextPage}
                    >
                        {isLoading ? (
                            <div id={`dfjkvecaad`} className="flex items-center justify-center">
                                <Icon id={`bvisedjkm`} icon={loadingLoop} width={24} height={24}/>
                            </div>
                        ) : (
                            "Yes, this is me"
                        )}
                    </button>
                </div>
            </div>
        </TraineeForm>
    );
}

export default ConfirmIdentity;