import React from 'react';

interface IProps{
    color?: string;
}
function ActivityIcon({color}: IProps) {
    const colorPalette = !!color ? color : "#72757A";
    return (
        <svg id={`cgjghgfx`} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id={`sdfvkljkhbcvv`} d="M18.5462 9.21323H15.0655L12.455 17.0449L7.23386 1.38159L4.62331 9.21323H1.14258" stroke="#5F646F" stroke-width="1.74037" stroke-linecap="round" stroke-linejoin="round" fill={colorPalette}/>
            <path id={`ghjkljhn`} d="M18.5462 9.21323H15.0655L12.455 17.0449L7.23386 1.38159L4.62331 9.21323H1.14258" stroke="black" stroke-opacity="0.2" stroke-width="1.74037" stroke-linecap="round" stroke-linejoin="round" fill={colorPalette}/>
        </svg>

    );
}
export default ActivityIcon;