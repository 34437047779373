import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TableData} from "../../types/commons.type";
import {IInvestorResponse} from "../../types/investor.type";
import {PURGE} from "redux-persist";

interface InvestorState {
    // investor?: TableData<IInvestorResponse>;
    investor?: any;
    investorDetail?: any;
}

const initialState: InvestorState = {};

const investorSlice = createSlice({
    name: 'investor',
    initialState,
    reducers: {
        setInvestor: (state, action: PayloadAction<any>) => {
            state.investor = action.payload;
        },
        setInvestorDetail:(state, action: PayloadAction<any>)=>{
            state.investorDetail = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.investor = undefined;
            state.investorDetail = undefined;
        });
    },
});

export const investorActions = investorSlice.actions;

export default investorSlice.reducer;