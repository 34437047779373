import { createApi } from "@reduxjs/toolkit/query/react";
import {baseQuery, POST} from "../requestTypes";

export const inviteColleagueApi = createApi({
    reducerPath: "inviteColleagueApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        inviteColleague: builder.mutation({
            query: (body) => POST("user/invite", body),
        }),
        getAllColleagues: builder.mutation({
            query:(body) => POST("user/invited", body)
        }),
    }),
});

export const {useInviteColleagueMutation, useGetAllColleaguesMutation} = inviteColleagueApi
