import {styled} from "styled-components";

export const ButtonDiv = styled.button`
  display: block;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  width: 100%;
  height: 43px;
`;
