import React, {ReactNode, useEffect, useState} from "react";
import SideBar from "../../../../components/reusables/layout/SideBar";
import {useAppDispatch} from "../../../../redux/hooks/hooks";
import styles from "../../../../components/reusables/layout/styles/layout.module.css";

import TraineeTopBar from "./traineeTopBar";

interface IProps {
    children?: ReactNode;
    page?: any;
}

function  ViewTraineeLayout({children, page}: IProps) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [barCollapse, setBarCollapse] = useState(false);
    const [selectedName, setSelectedName] = useState<string | null>(null);

    const dispatch = useAppDispatch()
    const toggleSidebar = () => {
        setSidebarOpen((prev) => !prev);
        setBarCollapse(false);
    };

    useEffect(() => {
        if (barCollapse) {
            setSidebarOpen(false);
        }
    }, [barCollapse]);

    useEffect(() => {
        setSelectedName(page);
    }, [page]);

    return (
        <div id={`shiidnfingeroi`}>
            <div id={`n8udfnw3ie0o`}>
                <TraineeTopBar />
            </div>
            <div
                id={`nsnojinosd`}
                className={`${styles.wrapper} ${barCollapse ? styles.barCollapse : ""}`}
            >
                <SideBar
                    mobileOpen={sidebarOpen}
                    className={styles.sidebar}
                    barCollapse={barCollapse}
                    setBarCollapse={setBarCollapse}
                    selectedName={selectedName}
                />
                <div id={`soidunvjsfdk`} className={`min-w-full`}>{children}</div>
            </div>
        </div>
    );
}

export default ViewTraineeLayout;
