import React, {useRef, useState, Fragment} from 'react';
import authService from '../../../../services/auth.service';
import AuthBackground from "../../reuseables/background/AuthBackground";
import styles from "./Otp.module.css";
import utils from "../../../../utils/utils";
import {useAppSelector} from "../../../../redux/hooks/hooks";
import {useNavigate} from "react-router-dom";
import {Toast, ToastTypes} from "../../../../components/Toast";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import {Icon} from "@iconify/react";

function Otp() {
    const [otp, setOtp] = useState<string[]>(Array(6).fill(""));
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null));
    const userEmail = useAppSelector(state => state.auth?.resetPasswordEmail);
    const navigate = useNavigate();

    const handleInputChange = (index: number, value: string) => {
        const newOtp = [...otp];
        if (!isNaN(Number(value))) {
            newOtp[index] = value;
            setOtp(newOtp);
            setIsButtonDisabled(newOtp.some(val => val === ""));
            if (value && inputRefs.current[index + 1]) {
                inputRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Backspace" && index > 0 && e.currentTarget.value === "") {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedData = e.clipboardData.getData('Text').trim();
        const pastedOtp = pastedData.split('').filter(val => !isNaN(Number(val))).slice(0, 6);
        const newOtp = [...otp];
        pastedOtp.forEach((value, index) => {
            newOtp[index] = value;
            if (inputRefs.current[index + 1]) {
                inputRefs.current[index + 1]?.focus();
            }
        });
        setOtp(newOtp);
        setIsButtonDisabled(newOtp.some(val => val === ""));
    };

    const handleVerify = async () => {
        const otpCode = otp.join('');
        const data = {
            email: userEmail,
            token: otpCode,
        }
        try {
            setLoading(true);
            const response = await authService.validateOtp(data);
            if (response) {
                navigate("/create-password");
            }
        } catch (e) {
            utils.handleRequestError(e);
        } finally {
            setLoading(false);
        }
    }

    const handleResendEmail = async () => {
        try {
            const response = await authService.resendEmail({email: userEmail});
            response && (
                Toast({content: "OTP Resent Successfully", type: ToastTypes.SUCCESS})
            )
        } catch (e) {
            utils.handleRequestError(e);
        }
    }

    return (
        <AuthBackground>
            <div id="otp-container" className={`min-w-[27rem] min-h-[23rem] bg-white rounded-[6px] p-[40px]`}>
                <p id="verify-otp" className={`font-bold mb-3 text-[1.2rem]`}>Verify OTP</p>
                <p id="sent-message" className={`text-[0.9rem]`}>We've sent a One-Time Password recovery code to<br/>
                    <span className={`font-semibold`} id="user-email">{userEmail}</span>
                </p>
                <p id="enter-code" className={`text-[0.9rem] font-semibold mt-[1.5rem] mb-[0.6rem]`}>Enter Code</p>
                <div className={styles.inputBoxes}>
                    {otp.map((value, index) => (
                        <Fragment key={index}>
                            <input
                                type={`text`}
                                maxLength={1}
                                value={value}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                onPaste={(e) => handlePaste(e)}
                                placeholder={"0"}
                                ref={(input) => {
                                    inputRefs.current[index] = input;
                                }}
                                id={`otp-input-${index}`}
                            />
                            {index === 2 && <p className={`text-[2.3rem] text-[#CDD5DF]`}> - </p>}
                        </Fragment>
                    ))}
                </div>
                <button
                    id="verify-otp-button"
                    className={`w-full text-center text-white py-[0.6rem] rounded-[4px] ${isButtonDisabled ? 'bg-[#C9C9D4]' : 'bg-[#0EAA4F]'}`}
                    onClick={handleVerify}
                    disabled={isButtonDisabled}
                >
                    {isLoading ? (
                        <div id={'loadingLoopDiv'} className="flex items-center justify-center">
                            <Icon id={'loadingLoopIcon'} icon={loadingLoop} width={24} height={24}/>
                        </div>
                    ) : (
                        "Verify OTP"
                    )}
                </button>

                <p id="resend-message" className={`text-center mt-[0.8rem]`}>Didn't receive any code?
                    <span onClick={handleResendEmail} id="resend-link" className={`text-[#0EAA4F] cursor-pointer`}> Click to resend </span>
                </p>

            </div>
        </AuthBackground>
    );
}

export default Otp;
