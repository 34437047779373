import {createSlice, PayloadAction} from "@reduxjs/toolkit";


import {buttonNames} from "../../utils/roles";
import {LoanManagementRequest} from "../../features/dashboard/loan/loanOffer/allLoanOffer/loanOfferData";
import {ViewALoanOfferAsAPMResponse, ViewALoanRequestDetailsAsAPMResponse} from "../../types/loan/traineeLoan.type";
import {PURGE} from "redux-persist";



interface LoanState {
   loan?: LoanManagementRequest | undefined;
   traineeLoanOffer?: ViewALoanOfferAsAPMResponse | undefined;
    traineeLoanRequest?:ViewALoanRequestDetailsAsAPMResponse | undefined;
    buttonName: string;
    loanOfferDetails?: any;
    loanId: string;
    loanRequestId: string;
}
const initialState: LoanState = {
    loan: {},
    traineeLoanOffer:{},
    traineeLoanRequest:{},
    buttonName: buttonNames.LOAN_REQUEST,
    loanOfferDetails:{},
    loanId: "",
    loanRequestId: "",
}


const loanSlice = createSlice({
    name: "loan",
    initialState,
    reducers:{
        setLoan: (state, action: PayloadAction<any>)=>{
            state.loan = action.payload;
        },

        setTraineeLoanOffer: (state, action: PayloadAction<any>)=>{
            state.traineeLoanOffer = action.payload;
        },

        setTraineeLoanRequest: (state, action: PayloadAction<any>)=>{
            state.traineeLoanRequest = action.payload;
        },
        
        loanSideBarButton: (state, action: PayloadAction<buttonNames>) => {
            state.buttonName = action.payload;
            // console.log('Button Name:', action.payload);
        },

        setLoanOfferDetails:(state, action: PayloadAction<any>)=> {
            state.loanOfferDetails = action.payload;
        },
        setLoanRequestId: (state, action: PayloadAction<any>) =>{
            state.loanRequestId = action.payload;
        },
        setLoanId: (state, action: PayloadAction<any>) =>{
            state.loanId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.loan = undefined;
            state.traineeLoanOffer = undefined;
            state.traineeLoanRequest = undefined;
            state.buttonName = "";
            state.loanOfferDetails = undefined;
            state.loanRequestId = "";
            state.loanId = "";
        });
    },
})

export const loanActions = loanSlice.actions;

export default loanSlice.reducer