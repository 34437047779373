import {createSlice} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";

interface SearchTextState{
    searchString: string;
    searchColumn?:string
}

const initialState : SearchTextState ={
    searchString:""
}

const searchSlice = createSlice({
    name:"search",
    initialState,
    reducers:{
        setSearchString:(state, action)=>{
            state.searchString = action.payload;
        },
        setSearchColumn:(state, action)=>{
            state.searchColumn = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.searchString = "";
            state.searchColumn = "";
        });
    },
})

export const searchActions = searchSlice.actions;
export default searchSlice.reducer;