import {Icon} from "@iconify/react";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import React from "react";

interface IProps{
    width?: number;
    height?: number;
}
const LoadingLoop = ({width, height}:IProps) => {
    const loaderWidth = !!width ? width : 24;
    const  loaderHeight= !!height ? height: 24

    return(
        <div id={`jshfjkldhjfh`} className="flex items-center justify-center">
            <Icon id={`sjvkjkldjkjskjf`} icon={loadingLoop} width={loaderWidth} height={loaderHeight}/>
        </div>
    )
}

export default LoadingLoop;