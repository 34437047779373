import React, {ReactNode} from 'react';
import dashStyles from "./DashboardTable.module.css"
import {AiOutlineAlert} from "react-icons/ai";
import {GrClose} from "react-icons/gr";
import Modal from "../../../components/Modal";

interface IProps {
    isOpen: boolean;
    closeModal: () => void;
    title: string;
    message: ReactNode;
    confirmText: string;
    onConfirm: () => void;
    cancelText: string;
    onCancel: () => void;
}

function RemoveModal(props : IProps) {

    return (
        <Modal isOpen={props.isOpen} closeModal={props.closeModal}>
            <div id={`removeConfirmationModal`} className={dashStyles.removeConfirmationModal}>
                <div id={`removeIcons`} className={dashStyles.removeIcons}>
                    <AiOutlineAlert id={`alertIcon`} className={dashStyles.alertIcon} />
                    <GrClose id={`closeModal`}
                        className={dashStyles.closeIcon}
                        onClick={props.closeModal}
                    />
                </div>
                <p id={`removeInstitute`} className={dashStyles.removeInstitute}>{props.title}</p>
                <p id={`removeInstituteText`} className={dashStyles.removeInstituteText}>
                    {props.message}
                </p>
                <div id={`buttons`}>
                    <button id={`cancelText`} onClick={props.onCancel}>{props.cancelText}</button>
                    <button id={`confirmText`} onClick={props.onConfirm}>{props.confirmText}</button>
                </div>
            </div>
        </Modal>
    );
}

export default RemoveModal;