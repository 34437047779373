import {LuBook} from "react-icons/lu";
import React, {useState} from "react";
import {buttonNames, pathnames} from "../../../utils/roles";
import {useLocation, useNavigate} from "react-router-dom";
import inbox from "./assets/inbox.svg";
import users from './assets/users.svg'
import currentCohort from "./assets/current-cohort.svg";
import cohort from "./assets/Cohort.svg";
import institute from "./assets/book.svg";
import graduatedCohorts from "./assets/graduated-cohorts.svg";
import incomingCohort from "./assets/incoming-cohort.svg";
import nonPerformingLoan from "./assets/non-performing-loan.svg";
import performingLoan from "./assets/performing-loan.svg";
import program from './assets/program.svg'
import styles from "./styles/SideBar.module.css";
import Recycle from "../../../features/dashboard/assets/images/recircle.svg"
import Graph from "../../../features/dashboard/assets/images/bar-chart-2.svg"
import carbon from "../../../features/dashboard/assets/images/carbon_request-quote.svg";
import handLoan from "../../../features/dashboard/assets/images/hand-loan.svg";
import decline from "../../../features/dashboard/assets/images/Declined.svg";
import loan_product_icon from "../../../features/dashboard/assets/images/loan_product_icon.svg";
import hand_cash_icon from "../../../features/dashboard/assets/images/hand-cash-icon.svg";
import cohortSummary from "../../../features/dashboard/assets/Group.svg";
import trainees from "../../../features/dashboard/assets/Icon.svg";
import bankDetails from "../../../features/dashboard/assets/images/investor-bank-details.svg";
import barChart from "../../../features/dashboard/assets/images/bar-chart-2.svg";
import bioData from "../../../features/dashboard/assets/images/bioData.svg"
import {assignPathNameForCohort, assignPathNameForPrograms, handleTitle, assignPathNameForInvestor} from "../../../utils";
import {useAppSelector} from "../../../redux/hooks/hooks";
import {cohortButtonName, instituteButtonName, loanButtonName, programsCohortButtonName} from "./buttonNames";

// import {}
interface IProps {
    selectedName?: string | null;
    barCollapse?: boolean;
}

const SideNavButton = ({selectedName, barCollapse}: IProps): any => {
    const [activeButton, setActiveButton] = useState(handleTitle);

    const navigate = useNavigate();
    // const dispatch = useAppDispatch();
    const handleButtonClick = (title: string) => {
        setActiveButton(title);
    };

    const handleSideButtonClick = (title: string, to: string, buttonName?: string, icon?: string | JSX.Element) => {
        setActiveButton(title);
        navigate(to);

        if (buttonName) {
            cohortButtonName(buttonName);
            // InstituteButtonName(buttonName);
            instituteButtonName(buttonName);
            // cohortButtonName(buttonName);
            loanButtonName(buttonName);
            programsCohortButtonName(buttonName);
        }
    };


    const programName = useAppSelector(state => state.program.programName);
    const instituteName = useAppSelector(state => state.institute.instituteName);


// console.log("the institute name ", instituteName);

    const buttonData = [
        // {
        //     title: "Awaiting Disbursal",
        //     id: "AwaitingDisbursalakjbsnm",
        //     icon: <img src={Recycle} alt="recircle"/>,
        //     to: "",
        //     buttonName: buttonNames.AWAITING_DISBURSAL,
        //     pageName: pathnames.LOANS,
        // },
        {
            title: "Loan Request",
            id: "LoanRequestkjbas",
            icon: <img src={carbon} alt="carbon"/>,
            to: "",
            buttonName: buttonNames.LOAN_REQUEST,
            pageName: pathnames.LOANS,
        },
        {
            title: "Loan Offer",
            id: "LoanOfferaoikj",
            icon: <img src={handLoan} alt="handloan"/>,
            to: "",
            buttonName: buttonNames.LOAN_OFFER,
            pageName: pathnames.LOANS,
        },
        {
            title: "Disbursal",
            id: "AwaitingDisbursalakjbsnm",
            icon: <img src={hand_cash_icon} alt="recircle"/>,
            to: "",
            buttonName: buttonNames.DISBURSAL,
            pageName: pathnames.LOANS,
        },
        {
            title: "Declined Request",
            id: "DeclinedRequestajbdsm",
            icon: <img src={decline} alt="decline"/>,
            to: "",
            buttonName: buttonNames.DECLINED_REQUEST,
            pageName: pathnames.LOANS,
        },
        {
            title: "Loan Product",
            id: "LoanProductaekjhws",
            icon: <img src={loan_product_icon} alt="graph"/>,
            // icon: <img src={Graph} alt="graph"/>,
            to: "",
            buttonName: buttonNames.LOAN_PRODUCT,
            pageName: pathnames.LOANS,
        },

        // {
        //   title: "Loan Requests",
        //   icon: <img src={loanRequest} alt="loan-request" />,
        //   to: "",
        //   pageName: pathnames.TRAINEES,
        // },
        {
            title: "Non-Performing Loan",
            id: "Non-PerformingLoanajsdb",
            icon: <img src={nonPerformingLoan} alt="non-performing-loan"/>,
            to: "",
            pageName: pathnames.TRAINEES,
        },
        {
            title: "Performing Loan",
            id: "PerformingLoansakjbh",
            icon: <img src={performingLoan} alt="performing-loan"/>,
            to: "",
            pageName: pathnames.TRAINEES,
        },
        {
            title: "Institutes",
            id: "Institutesakhjbj",
            icon: <LuBook/>,
            to: "",
            buttonName: buttonNames.INSTITUTES,
            pageName: pathnames.INSTITUTES,
        },
        {
            title: "Invited Institutes",
            id: "InvitedInstituteskjasbn",
            icon: <LuBook/>,
            to: "",
            buttonName: buttonNames.INVITED_INSTITUTES,
            pageName: pathnames.INSTITUTES,
        },
        {
            title: "Deactivated Institutes",
            id: "DeactivatedInstitutesandm",
            icon: <LuBook/>,
            to: "",
            buttonName: buttonNames.DEACTIVATED_INSTITUTES,
            pageName: pathnames.INSTITUTES,
        },
        {
            title: "Incoming Cohorts",
            id: "IncomingCohortshfdj",
            icon: <img src={incomingCohort} alt="incoming-cohort"/>,
            to: "",
            buttonName: buttonNames.INCOMING_COHORT,
            pageName: pathnames.COHORTS,
        },
        {
            title: "Current Cohorts",
            id: "CurrentCohortsjkfadnd",
            icon: <img src={currentCohort} alt="current-cohort"/>,
            to: "",
            buttonName: buttonNames.CURRENT_COHORT,
            pageName: pathnames.COHORTS,
        },
        {
            title: "Graduated Cohorts",
            id: "GraduatedCohortsjabdmn",
            icon: <img src={graduatedCohorts} alt="graduated-cohorts"/>,
            to: "",
            buttonName: buttonNames.GRADUATED_COHORT,
            pageName: pathnames.COHORTS,
        },
        {
            title: "Bio Data",
            id: "BioDataskjbdn",
            icon: <img src={bioData} alt="biodata"/>,
            to: "/investor/biodata",
            pageName: assignPathNameForInvestor(),
        },

        {
            title: "Investments",
            id: "Investmentsvbjd",
            icon: <img src={barChart} alt="investments"/>,
            to: "/investor/investments",
            pageName: assignPathNameForInvestor(),
        },

        {
            title: "Bank Details",
            id: "BankDetailsjafbsn",
            icon: <img src={bankDetails} alt="bank-details"/>,
            to: "/investor/bank-details",
            pageName: assignPathNameForInvestor(),
        },
        {
            title: "Cohort Summary",
            id: "CohortSummarykfjsd",
            icon: <img src={cohortSummary} alt="cohort-summary"/>,
            to: "/cohort/cohort-details",
            pageName: assignPathNameForCohort()
        },

        {
            title: "Trainees",
            id: "Traineesskjbdn",
            icon: <img src={trainees} alt="trainees"/>,
            to: "/cohort/cohort-trainees",
            pageName: assignPathNameForCohort()
        },
        {
            title: instituteName,
            // title: "Institute name",
            id: "instituteActualNamekbsdj",
            icon: <img src={institute} alt={``}/>,
            to: "",
            buttonName: buttonNames.INSTITUTES_DETAILS,
            pageName: pathnames.INSTITUTE_DETAILS,
        },
        {
            title: "Programs",
            id: "Programsewbjks",
            icon: <img src={program} alt={`programs`}/>,
            to: "",
            buttonName: buttonNames.PROGRAMS,
            pageName: pathnames.INSTITUTE_DETAILS,
        },
        {
            title: "Cohorts",
            id: "Cohortsakj",
            icon: <img src={cohort} alt={`cohorts`}/>,
            to: "",
            buttonName: buttonNames.ALL_COHORT,
            pageName: pathnames.INSTITUTE_DETAILS,
        },
        {
            title: "Trainees",
            id: "Traineesajkds",
            icon: <img src={trainees} alt="trainees"/>,
            to: "",
            buttonName: buttonNames.TRAINEES,
            pageName: pathnames.INSTITUTE_DETAILS,
        }, {
            title: "Admins",
            id: "Adminsjaskd",
            icon: <img src={users} alt={users}/>,
            to: "",
            buttonName: buttonNames.ADMINS,
            pageName: pathnames.INSTITUTE_DETAILS,
        },
        {
            title: programName,
            id: "programActualNamejdabsk",
            icon: <img src={program} alt={`program`}/>,
            to: "/program/view",
            // to: "",
            // buttonName: buttonNames.PROGRAMS,
            pageName: assignPathNameForPrograms(),
        },
        {
            title: "Cohorts",
            id: "Cohortsdsbl",
            icon: <img src={cohort} alt={`cohorts`}/>,
            to: "/program/view/cohorts",
            // to: "",
            // buttonName: buttonNames.PROGRAMS,
            pageName: assignPathNameForPrograms(),
        },
    ]

    const location = useLocation();

    const filteredButtons = buttonData.filter(
        (button) => button.pageName === location.pathname
    );

    return (
        <div id={'mainDiv'} className="mx-[8px] p-[10px]">
            <button
                id={'handleButtonClickDiv'}
                className={`flex content-center items-center gap-2 border-b p-3 w-full mb-4 ${styles.sideBarButtonsTexts}`}
                onClick={() => handleButtonClick("Inbox")}
                style={{
                    backgroundColor: activeButton === "Inbox" ? "#E7F7ED" : "",
                    color: activeButton === "Inbox" ? "#0D9B48" : "",
                }}
            >
                <img id={'inboxImage'} src={inbox} alt="inbox"/>
                {!barCollapse && <p id={'InboxPTag'}>Inbox</p>}
            </button>
            {filteredButtons.map((button, index) => (
                <button
                    id={`${button.id}barCollapsebutton `}
                    key={button.id}
                    className={
                        `flex items-center space-x-2 w-full py-2 my-1 pl-2 rounded-[8px] ${styles.sideBarButtonsTexts} 
                ${barCollapse ? "justify-center text-2xl" : ""}`
                    }
                    onClick={() => handleSideButtonClick(button.title, button.to, button.buttonName, button.icon)}
                    style={{
                        backgroundColor: activeButton === button.title ? "#E7F7ED" : "",
                        color: activeButton === button.title ? "#0D9B48" : "#72757A",
                        fontWeight: activeButton === button.title ? "bold" : "normal",
                    }}
                >
                    {button.icon}
                    {!barCollapse && <span id={`${button.id}span`} className={`text-left`}>{button.title}</span>}
                </button>
            ))}
        </div>
    );
};

export default SideNavButton;
