import {ApiResponse, InstituteResponse} from "../types/institute.type";
import ApiInstance from "./api";
import {InviteData} from "../features/dashboard/institute/InviteTrainingInstitute/InviteData.type";
import {InstituteDetails} from "../features/dashboard/institute/ViewAllTrainingInstitute/InstituteData";
import {instituteActions} from "../redux/slices/institutesSlice";
import {store} from "../redux/store";
import apiRequest from "./apiRequest.service";


class InstituteService {

    private apiInstance: ApiInstance;

    constructor() {
        this.apiInstance = new ApiInstance();
    }


    public async inviteInstitute(data: InviteData): Promise<ApiResponse> {
        try {
            const apiInstance = new ApiInstance(sessionStorage.getItem("token"));
            const response = await apiInstance.getInstance().post("/finance/invite", data);
            return response.data;
        } catch (err) {
            throw err;
        }
    }
    public async deactivateInstitute(data: any ): Promise<ApiResponse> {
        try {
            const apiInstance = new ApiInstance(sessionStorage.getItem("token"));
            const institute = await apiInstance.getInstance().post(`/finance/deactivate`, data);
            return institute.data
        } catch (err) {
            throw err;
        }
    }

    public async getInstitute(data:InstituteDetails): Promise<InstituteResponse> {
        // @ts-ignore
        const test_Api = new ApiInstance(store.getState().auth.token?.access_token);
        try {
            const institute = await test_Api.getInstance().post(`/finance/view`, data);
            store.dispatch(instituteActions.setAnInstitute(institute.data));
            return institute.data
        } catch (err) {
            throw err;
        }
    }
    public async viewAllInstituteAdmins(data:InstituteDetails): Promise<any> {
        try {
            const apiInstance = new ApiInstance(sessionStorage.getItem("token"));
            const response = await apiInstance.getInstance().post("/finance/view-all-admins", data);
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async getAllInstitutesWithLoan(data: any): Promise<any> {
        try {
            const api = new ApiInstance(sessionStorage.getItem("token"))
            const response = await api.getInstance().post("/finance/institutes", data);
            store.dispatch(instituteActions.setInstitutes(response.data));
            return response.data;
        } catch (err) {
            throw err;
        }
    }


    public async getAllInstitutes(data: any): Promise<any> {
        try {
            const api = new ApiInstance(sessionStorage.getItem("token"))
            const response = await api.getInstance().post("/finance/viewAll", data);
            store.dispatch(instituteActions.setInstitutes(response.data));
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async updateInstitute(data: any){
        const endpoint = `finance/edit`;
        return apiRequest.request(endpoint, "post", data)
    }


}

const instituteService = new InstituteService();

export default instituteService;