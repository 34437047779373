import React, {useMemo} from "react"
import {useAppSelector} from "../../../../redux/hooks/hooks";
import Modal from "../../../../components/Modal";
import styles from "./styles/LoanProductModal.module.css";
import {GrClose} from "react-icons/gr";
import utils from "../../../../utils/utils";

interface ILoanProductProps {

    isOpen: boolean;
    closeModal?: () => void;
    openEditModal?: () => void;
    loanProductId: string;

}

function ViewLoanProductIndex(props: ILoanProductProps) {
    const storedLoanProduct = useAppSelector((state) => state.loanProduct.loanProduct);
    const loanProductDetails = useMemo(() => storedLoanProduct?.content.find((lp: any) => lp.loanProductId === props.loanProductId), [storedLoanProduct, props.loanProductId]);
    const loanDetails = [
        {
            key: 'Loan Product Name',
            value: loanProductDetails?.loanProductName,
        },
        {
            key: 'Obiligor Loan Limit',
            value: loanProductDetails?.obligorLoanLimit,
        },
        {
            key: 'Tenor',
            value: `${loanProductDetails?.tenor} ${loanProductDetails?.tenureStatus}`,
        },
    ]

    
    return (
        <Modal isOpen={props.isOpen}>
            <div id={`kjdvfbt`} className={styles.wrapper}>
                <div id={`lkgnfgh`} className={styles.top}>
                    <h1 id={`jhvkjdf`} className={styles.label2}>Loan Product Details</h1>
                    <div id={`kjgshdj`} onClick={props.closeModal} className={`cursor-pointer`}>
                        <GrClose id={`sjfbjrfg`}/>
                    </div>
                </div>

                <div id={`kdjk`} className={styles.centre}>
                    <div id={`kdfnsk`} className={styles.inner}>
                        {loanDetails.map(value => (
                            <div id={`${value.key}skj`} className={styles.label}>
                                <h1 id={`${value.key}iajsdkd`} className={styles.label1}>
                                    {value.key}
                                </h1>
                                <p id={`${value.key}kjdl`} className={styles.label2}>{value.value}</p>
                            </div>
                        ))}
                    </div>
                    <div id={'akjh'} className={styles.inner2}>
                        <div id={'ksnk'} className={styles.label}>
                            <h2 id={'ajnbd'} className={styles.label1}>
                                Loan Product Size
                            </h2>
                            <p id={'afhgd'}
                               className={styles.label2}>{utils.formatAmount(loanProductDetails?.loanProductSize as unknown as number)}</p>
                        </div>
                        <div id={'labelIv'} className={styles.label}>
                            <h2 id={'fmngd'} className={styles.label1}>
                                Interest Rate
                            </h2>
                            <p id={'kgkf'} className={styles.label2}>{loanProductDetails?.interestRate}%</p>
                        </div>
                        <div id={'lkfo'} className={styles.label}>
                            <h2 id={'faln'} className={styles.label1}>
                                Moratorium
                            </h2>
                            <p id={'mlokd'} className={styles.label2}>{`${loanProductDetails?.moratorium} ${loanProductDetails?.moratoriumStatus}`}</p>
                        </div>
                    </div>

                </div>
                <div className={styles.bottom}>
                    <div id={'t&cDiv'}>
                        <h2 id={'t&c'} className={styles.label1}>
                            Terms And Conditions
                        </h2>
                        <textarea cols={20} rows={10}  disabled id={'mlokd'} className={styles.label2}>{loanProductDetails?.termsAndCondition}</textarea>
                    </div>
                </div>
            </div>

        </Modal>
    )
}

export default ViewLoanProductIndex