import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";
import {IParam, ITokenResponse, TokenPayload} from "../../types/auth.type";

export interface IAuthSlice {
    isAuthenticated: boolean;
    token?: ITokenResponse;
    user?: TokenPayload;
    associatedData?: any;
    param?: IParam;
    resetPasswordEmail?: string;
}

const initialState: IAuthSlice = {
    isAuthenticated: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action: PayloadAction<ITokenResponse>) => {
            state.isAuthenticated = true;
            state.token = action.payload;
            sessionStorage.setItem("token", state.token.access_token);
        },

        logout: (state) => {
            state.isAuthenticated = false;
            state.token = undefined;
            state.user = undefined;
            state.associatedData = undefined;
            state.param = undefined;
            sessionStorage.clear();
            localStorage.clear();
        },

        setUserDetails: (state, action: PayloadAction<TokenPayload>) => {
            state.user = action.payload;
        },

        setAssociatedData: (state, action: PayloadAction<any>) => {
            state.associatedData = action.payload;
            sessionStorage.setItem("userAssociatedData", JSON.stringify(state.associatedData));
        },

        setParam: (state, action: PayloadAction<IParam>) => {
            state.param = action.payload;
        },
        removeParam: (state) => {
            state.param = undefined;
        },
        setResetPasswordEmail:(state, action: PayloadAction<string>) => {
            state.resetPasswordEmail = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.isAuthenticated = false;
            state.token = undefined;
            state.user = undefined;
            state.associatedData = undefined;
            state.param = undefined;
        });
    },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
