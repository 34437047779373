import React from 'react';
import LearnSpaceTable from "../../../../../components/reusables/table/Table";
import {IColumnProps} from "../../../../../types/commons.type";
import {useNavigate} from "react-router-dom";
import noInstitute from "../../../assets/images/empty_state_institute.svg";
import {useAppSelector} from '../../../../../redux/hooks/hooks';
import {cohortActions} from "../../../../../redux/slices/cohortSlice";
import {store} from "../../../../../redux/store";

interface IProps {
    incomingCohortInProgramTableData?: any;
}


const columns: IColumnProps[] = [
    {
        title: 'Cohort Name',
        selector: 'name',
        sortable: true,
    },
    {
        title: 'Program Name',
        selector: 'programName',
        sortable: true,
    },

    {
        title: 'Number of Trainees',
        selector: 'numberOfTrainee',
        sortable: true,
    },
    {
        title: 'Start Date',
        selector: 'startDate',
        sortable: true,
    },
    {
        title: 'Tuition Amount',
        selector: 'tuition',
        sortable: true,
    },

    {
        title: 'Total Amount Requested',
        selector: 'totalAmountRequested',
        sortable: true,
    },

    {
        title: 'Total Amount Received',
        selector: 'totalAmountDisbursed',
        sortable: true,
    },
    {
        title: 'Total Amount Outstanding',
        selector: 'totalOutstanding',
        sortable: true,
    },

]

function ViewIncomingCohortInProgramTable(props: IProps) {
    const navigate = useNavigate();
    function handleViewCohortDetails(row: { cohortId: string; programId:string; name:string; programName:string; cohortStartDate:string; cohortEndDate:string; tuitionAmount:number}) {
        console.log("row ", row);
        store.dispatch(cohortActions.setCohortDetails({
            cohortId:row.cohortId,
            programId:row.programId,
            cohortName: row.name,
            programName:row.programName,
            cohortStartDate:row.cohortStartDate,
            cohortEndDate:row.cohortEndDate,
            tuitionAmount:row.tuitionAmount
        }));

        navigate("/cohort/cohort-details")
    }

    const tableData = useAppSelector(state => state.cohort.incomingCohortData)
    const getSearchString = useAppSelector((state)=> state.search.searchString)

    return (
        <LearnSpaceTable
            columns={columns}
            searchString={getSearchString}
            searchColumn="name"
            emptyTable={{
                image: noInstitute,
                title: "No Record Found",
                subText: "Registration for Incoming Cohort Ongoing",
                text: "",
            }}
            path={""}
            handleRowClick={(row: any) => handleViewCohortDetails(row)}
            tableData={!!tableData ? tableData : props.incomingCohortInProgramTableData}
        />
    );
}

export default ViewIncomingCohortInProgramTable;