import ApiInstance from "./api/index";
import {ApiResponse, LoanOfferRequest, ViewALoanOfferAsATraineeResponse} from "../types/loan/loan.type";
import {store} from "../redux/store";
import {loanActions} from "../redux/slices/loanSlice";
import apiRequest from "./apiRequest.service";
import {ILoanProductRequest} from "../types/loan/loanProduct.type";

class LoanService {
    private apiInstance: ApiInstance;
    // @ts-ignore
    private token : string | undefined = store.getState().auth.token?.access_token;


    constructor() {
        // @ts-ignore
        this.apiInstance = new ApiInstance(store.getState().auth.token?.access_token);
    }

    public async getAllLoanRequestByInstitute(data: any): Promise<any> {
        // @ts-ignore
        const test_Api = new ApiInstance(store.getState().auth.token?.access_token)

        try {
            const response = await test_Api.getInstance().post(`/loan/loan_requests/view-all-by-institute` , data)
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async getAllLoanRequestByCohort(data: any): Promise<any> {
        // @ts-ignore
        const test_Api = new ApiInstance(store.getState().auth.token?.access_token)

        try {
            const response = await test_Api.getInstance().post(`/loan/loan_requests/view-all-by-cohort` , data)
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async getAllLoanOfferByInstitute(data: any): Promise<any> {
        // @ts-ignore
        const test_Api = new ApiInstance(store.getState().auth.token?.access_token)

        try {
            const response = await test_Api.getInstance().post(`/loan/loan_offers/view-all-by-institute` , data)
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async getAllLoanOfferByCohort(data: any): Promise<any> {
        // @ts-ignore
        const test_Api = new ApiInstance(store.getState().auth.token?.access_token)

        try {
            const response = await test_Api.getInstance().post(`/loan/loan_offers/view-all-by-cohort` , data)
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async getDisbursedLoanByInstitute(data: any): Promise<any> {
        // @ts-ignore
        const test_Api = new ApiInstance(store.getState().auth.token?.access_token)

        try {
            const response = await test_Api.getInstance().post(`/loan/loan_offers/view-all-by-institute` , data)
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async getDisbursedByCohort(data: any): Promise<any> {
        // @ts-ignore
        const test_Api = new ApiInstance(store.getState().auth.token?.access_token)

        try {
            const response = await test_Api.getInstance().post(`/loan/loan_offer/view-all-by-cohort` , data)
            return response.data;
        } catch (err) {
            throw err;
        }
    }



    public async viewLoanRequest(data: any): Promise<ApiResponse> {
        // @ts-ignore
        const test_Api = new ApiInstance(store.getState().auth.token?.access_token)

        try {
            const response = await test_Api.getInstance().post(`/loan/loan_request` , data)
            return response.data;
        } catch (err) {
            throw err;
        }

    }
    public async viewLoanOffer(data: any): Promise<ApiResponse> {
        // @ts-ignore
        const test_Api = new ApiInstance(store.getState().auth.token?.access_token)

        try {
            const response = await test_Api.getInstance().post(`/loan/loan_offer` , data)
            return response.data;
        } catch (err) {
            throw err;
        }

    }



    public async viewLoanOfferAsATrainee(data: ViewALoanOfferAsATraineeResponse): Promise<any> {
        // @ts-ignore
        const test_Api = new ApiInstance(store.getState().auth.token?.access_token)

        try {
            const response = await test_Api.getInstance().post(`/loan/loan-offer/trainee-view` , data)
            return response.data;
        } catch (err) {
            throw err;
        }

    }

    public async createLoanOffer(data: { loanRequestId: any; traineeId: any; loanProductId: any; loanAmountApproved: any }): Promise<ApiResponse> {
        try {
            const apiInstance = new ApiInstance(sessionStorage.getItem("token"));
            const response = await apiInstance.getInstance().post("/loan/loan_offer/create", data);
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async disburseLoan(data: { loanId: any; traineeId: any }): Promise<ApiResponse> {
        try {
            const apiInstance = new ApiInstance(sessionStorage.getItem("token"));
            const response = await apiInstance.getInstance().post("/loan/disburse-loan", data);
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async loanOfferDetails(data:any){
        return apiRequest.request(``, "post", data);
    }
}
const loanService = new LoanService();

export default loanService;