import {combineReducers, configureStore} from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
// @ts-ignore
import {encryptTransform} from "redux-persist-transform-encrypt";
import {persistReducer, persistStore} from "redux-persist";
import authSlice from "./slices/authSlice";
import traineeSlice from "./slices/traineeSlice";
import colleagueSlice from "./slices/colleagueSlice";
import instituteSlice from "./slices/institutesSlice";
import loanSlice from './slices/loanSlice';
import programSlice from './slices/programSlice';
import fundProductSlice from './slices/fundProductSlice';
import cohortSlice from "./slices/cohortSlice";
import loanProductSlice from "./slices/loanProductSlice";
import searchTextSlice from "./slices/searchTextSlice";
import tableSlice from "./slices/tableSlice";
import investorSlice from "./slices/investorSlice";
import {inviteColleagueApi} from "./features/portfolioManager/api";


const persistConfig = {
    key: "root",
    storage,
    transforms: [
        encryptTransform({
            secretKey: process.env.REACT_APP_REDUX_SECRET_KEY as string,
            onError: function (error: any) {
                // Handle the error.
            },
        }),
    ],
    whitelist: ["auth", "institute", "investor", "trainee", "colleague", "fund", "cohort", "program"]
}

const persistedReducer = persistReducer(persistConfig, combineReducers({
    auth: authSlice,
    institute: instituteSlice,
    trainee: traineeSlice,
    colleague: colleagueSlice,
    loan: loanSlice,
    program: programSlice,
    fund: fundProductSlice,
    cohort: cohortSlice,
    investor: investorSlice,
    loanProduct: loanProductSlice,
    search: searchTextSlice,
    table: tableSlice,
    [inviteColleagueApi.reducerPath]: inviteColleagueApi.reducer
}))


export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat([
                inviteColleagueApi.middleware
            ]),
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const persistor = persistStore(store);

