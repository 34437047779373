import React, {useLayoutEffect, useState} from "react";
import instituteService from "../../../../services/institute.service";
import {InstituteResponse} from "../../../../types/institute.type";
import {useAppDispatch, useAppSelector,} from "../../../../redux/hooks/hooks";
import {InstituteDetails} from "../ViewAllTrainingInstitute/InstituteData";
import {instituteActions} from "../../../../redux/slices/institutesSlice";
import {getInstituteId} from "../../../../utils";
import utils from "../../../../utils/utils";


const ViewAllField = () => {
    const pmInstituteId = useAppSelector(state => state.institute.instituteId);
    const instituteId = getInstituteId() ?? pmInstituteId;
    console.log(instituteId);
    const dispatch = useAppDispatch();
    const [data, setData] = useState<InstituteResponse>({} as InstituteResponse);
    const fetchData = async () => {
        const requestBody: InstituteDetails = {instituteId: instituteId};
        try {
            const instituteResponse = await instituteService.getInstitute(requestBody);
            dispatch(instituteActions.setInstituteDetails(instituteResponse));
            setData(instituteResponse);
        } catch (error) {
            utils.handleRequestError(error);
        }
    };
    useLayoutEffect(() => {
        fetchData().then();
    }, []);


    return (
        <>
        <div id={'MainContainer'} className="bg-white  p-5 pt-8 rounded-sm rounded-b-lg  grid grid-cols-3 grid-rows-2 gap-4 ml-5">
            <div id={'EmailaddressDiv'}>
                <h2 id={'addressEmailH'} className="font-sans font-[400] size-[14] text-[#72757A]">Email Address</h2>
                <p id={'addressEmailP'} className="font-sans font-[500] size-[16] text-[#101828]">{data.instituteEmail}</p>
            </div>
            <div id={'NumberInner'}>
                <h2 id={'NumberH'} className="font-sans font-[400] size-[14] text-[#72757A]">Phone Number</h2>
                <p id={'NumberP'} className="font-sans font-[500] size-[16] text-[#101828]">{data.institutePhoneNumber || "N/A"}</p>
            </div>
            <div id={'AddressInner'}>
                <h2 id={'AddressH'} className="font-sans font-[400] size-[14] text-[#72757A]">Address</h2>
                <p id={'AddressP'} className="font-sans font-[500] size-[16] text-[#101828]">{data.instituteAddress}</p>
            </div>
            <div id={'instituteAdminFirstNameInner'}>
                <h2 id={'instituteAdminFirstNameH'} className="font-sans font-[400] size-[14] text-[#72757A]">Admin First Name</h2>
                <p id={'instituteAdminFirstNameP'} className="font-sans font-[500] size-[16] text-[#101828]">{data.instituteAdminFirstName}</p>
            </div>
            <div id={'instituteAdminLastNameInner'}>
                <h2 id={'instituteAdminLastNameH'} className="font-sans font-[400] size-[14] text-[#72757A]">Admin Last Name </h2>
                <p id={'instituteAdminLastNameP'} className="font-sans font-[500] size-[16] text-[#101828]">{data.instituteAdminLastName}</p>
            </div>
            <div id={'instituteAdminEmailInner'}>
                <h2 id={'instituteAdminEmailH'} className="font-sans font-[400] size-[14] text-[#72757A]">Admin Email Address</h2>
                <p id={'instituteAdminEmailP'} className="font-sans font-[500] size-[16] text-[#101828]">{data.instituteAdminEmail}</p>
            </div>
            <div id={'numberOfProgramInner'}>
                <h2 id={'numberOfProgramH'} className="font-sans font-[400] size-[14] text-[#72757A]">Programs</h2>
                <p id={'numberOfProgramP'} className="font-sans font-[500] size-[16] text-[#101828]">{data.numberOfProgram}</p>
            </div>
            <div id={'numberOfCohortInner'}>
                <h2 id={'numberOfCohortH'} className="font-sans font-[400] size-[14] text-[#72757A]">Cohorts</h2>
                <p id={'numberOfCohortP'} className="font-sans font-[500] size-[16] text-[#101828]">{data.numberOfCohort}</p>
            </div>
            <div id={'numberOfCurrentTraineesInner'}>
                <h2 id={'numberOfCurrentTraineesH'} className="font-sans font-[400] size-[14] text-[#72757A]">Trainees</h2>
                <p id={'numberOfCurrentTraineesP'} className="font-sans font-[500] size-[16] text-[#101828]">{data.numberOfTrainees || "0"} </p>
            </div>
            <div id={'numberOfCurrentTraineesInner'}>
                <h2 id={'numberOfCurrentTraineesH'} className="font-sans font-[400] size-[14] text-[#72757A]">Still in Training</h2>
                <p id={'numberOfCurrentTraineesP'} className="font-sans font-[500] size-[16] text-[#101828]">{data.numberOfCurrentTrainees || "0"}</p>
            </div>
        </div>
    </>
    );
};



export default ViewAllField;
