import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {InviteData} from "../../types/colleague.type";
import {PURGE} from "redux-persist";

interface ColleagueState {
    colleagues: InviteData[] | undefined;
}

const initialState: ColleagueState = {
    colleagues: [],
};

const colleagueSlice= createSlice({
    name: 'colleague',
    initialState,

    reducers: {
        setColleagues: (state, action: PayloadAction<InviteData[]>) => {
            state.colleagues = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.colleagues = undefined;
        });
    },
});

export const { setColleagues } = colleagueSlice.actions;
export default colleagueSlice.reducer;