import {TableData} from "../commons.type";

export interface viewIncomingCohortDetails{

    cohortName: string,
    programName: string,
    numberOfTrainees: number;
    startDate: Date,
    tuitionAmount:number,
    totalAmountRequested: number;
    totalAmountReceived: number;
    totalAmountOutstanding: number;




}


export const emptyIncomingCohortData: viewIncomingCohortDetails = {

    startDate: new Date(),
    tuitionAmount: 0,
    programName: "",
    cohortName: "",
    numberOfTrainees: 0,
    totalAmountRequested: 0,
    totalAmountReceived: 0,
    totalAmountOutstanding: 0,


}

const viewIncomingCohortMock: viewIncomingCohortDetails[] = [
    {
        "cohortName": "Cohort21",
        "programName": "Software Engineering",
        "numberOfTrainees": 50,
        "startDate": "13th Feb. 2024" as unknown as Date,
        "tuitionAmount": 4500000,
        "totalAmountRequested": 0,
        "totalAmountReceived": 0,
        "totalAmountOutstanding": 0
    },
    {
        "cohortName": "Cohort22",
        "programName": "Software Engineering",
        "numberOfTrainees": 68,
        "startDate": "10th may. 2024" as unknown as Date,
        "tuitionAmount": 4800000,
        "totalAmountRequested": 0,
        "totalAmountReceived": 0,
        "totalAmountOutstanding": 0

    }]

export const emptyIncomingCohortPage: TableData<viewIncomingCohortDetails> = {
    pageNo: 1,
    pageSize: 10,
    totalElement: 0,
    total: 0,
    last: true,
   // content: viewIncomingCohortMock,
    content: [],
}

