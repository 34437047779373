import ApiInstance from "./api";
import {loanProductActions} from "../redux/slices/loanProductSlice";
import {store} from "../redux/store";
import {ApiResponse} from "../types/institute.type";
import {ILoanProductRequest} from "../types/loan/loanProduct.type";

class LoanProductService {

    private apiInstance: ApiInstance;

    private apiInstance2: ApiInstance;

    private accessToken = sessionStorage.getItem("token" || "")

    constructor() {
        this.apiInstance = new ApiInstance();
        this.apiInstance2 = new ApiInstance(this.accessToken);

    }

    public async createLoanProduct(data: ILoanProductRequest): Promise<ApiResponse> {
        try {
            const apiInstance = new ApiInstance(sessionStorage.getItem("token"));
            const response = await apiInstance.getInstance().post("/loan/loan-product/create", data);
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async viewLoanProduct(data: any): Promise<any> {
        try {
            const apiInstance = new ApiInstance(sessionStorage.getItem("token"));
            const response = await apiInstance.getInstance().post("/loan/loan-product/view-all", data);
            store.dispatch(loanProductActions.setLoanProduct(response.data));
            return response.data;

        } catch (err) {
            throw err;
        }
    }
}

const loanProductService = new LoanProductService();
export default loanProductService;



