import React, {useState} from "react";
import AuthBackground from "../../reuseables/background/AuthBackground";
import PasswordInput from "../../reuseables/password/PasswordInput";
import PageForm from "../../reuseables/form/Form";
import TextInput from "../../reuseables/input/TextInput";
import Button from "../../reuseables/button/Button";
import {FooterNote, InnerPageFormDiv, MobileButtonDiv,} from "../forgetPassword/Index.style";
import errorIcon from "../../../userManagement/assests/error.png";
import "./Login.css";
import {Link, useNavigate} from "react-router-dom";
import {ILoginRequest} from "../../../../types/auth.type";
import authService from "../../../../services/auth.service";
import utils from "../../../../utils/utils";
import {useAppDispatch} from "../../../../redux/hooks/hooks";


interface IProps {}

function Login(props: IProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [loginError, setLoginError] = useState<JSX.Element | string>("");
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [errorType, setErrorType] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    const enteredEmail = event.target.value;
    setEmail(enteredEmail);
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    setIsEmailValid(emailRegex.test(enteredEmail));
  }

  const handlePasswordChange = (value: string) => {
    setPassword(value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  }


  const handleButtonClick = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    const values: ILoginRequest = {email: email, password: password};

    if (email !== '' && password.length >= 5) {
      setIsLoading(true);
      try {
        const response = await authService.login(values);
        if (response.access_token !== null) {

          navigate("/");
        }
      } catch (err: any) {
        if (err.response) {
          setLoginError(
            <div id={'setLoginError'} className="setLoginError">
              <img id={'ErrorIcon'} src={errorIcon} alt="ErrorIcon" />
              <span id={'credentials'}>Invalid user credentials</span>
            </div>
          );
          setErrorType("input-error");
        } else {
          utils.handleRequestError(err);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <AuthBackground>
        <PageForm>
          <InnerPageFormDiv>
            <h5 id={'login'}>Login</h5>
            <p id={'Welcome'}>Welcome, It’s good to see you again!</p>

            {loginError && <div id={'LoginError'} className="LoginError">{loginError}</div>}
            <div id={'emailDiv'}>
              <label id={'emailLabel'}>Email</label>
              <TextInput
                text={email}
                onTextChange={handleEmailChange}
                style={{ borderColor: "inherit" }}
              />
            </div>
            <div id={'PasswordLabel'} className={`mt-3`}>
              <label id={'Password'}>Password</label>
              <PasswordInput
                value={password}
                onChange={handlePasswordChange}
                showPassword={showPassword}
                togglePasswordVisibility={togglePasswordVisibility}
                style={{ borderColor: "inherit" }}
              />
            </div>
          </InnerPageFormDiv>

          <FooterNote></FooterNote>

          <MobileButtonDiv>
            <Button
              text={"Login"}
              style={{backgroundColor: "#45a049"}}
              onClick={handleButtonClick}
              loading={isLoading}
            />
          </MobileButtonDiv>

          <div id={'forgotPasswordDiv'} className="flex justify-end py-1.5">
            <Link id={'forgotLink'} className="footer-link" to="/forgot-password">
              Forgot Password?{" "}
            </Link>
          </div>
        </PageForm>
      </AuthBackground>
    </div>
  );
}

export default Login;
