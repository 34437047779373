import React, {useEffect, useState} from 'react';
import {TableData} from "../../../../types/commons.type";
import {getInstituteId} from "../../../../utils";
import cohortService from "../../../../services/cohort.service";
import utils from "../../../../utils/utils";
import Skeleton from "@mui/material/Skeleton";
import {emptyViewAllCohortPage, viewAllCohortDetailsInProgram} from "../../../../types/cohort/cohort.type";
import AllCohortsInProgramTable from "./AllCohortsInProgramTable";
import {useAppSelector} from "../../../../redux/hooks/hooks";
import LoadingLoop from "../../../../components/loadingLoop/LoadingLoop";


interface IProps {
}

function AllCohortIndex(props: IProps) {
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [viewAllProgramCohortDetails, setAllCohortData] = useState<TableData<viewAllCohortDetailsInProgram>>(emptyViewAllCohortPage);
    const instituteId = useAppSelector(state => state.institute.instituteId);
    const data = {instituteId: instituteId};


    function formatCohort(data: { content: any[] | null | undefined }) {
        return {
            ...data,
            content: Array.isArray(data?.content) ? data.content.map(item => ({
                ...item,
                tuitionAmount: utils.formatAmount(item.tuitionAmount),
                endDate: item.endDate === "0000-00-00" ? "N/A" :
                    new Date(item.endDate).toLocaleString(undefined, {year: "numeric", month: "long", day: "numeric"}),
                totalAmountDisbursed: utils.formatAmount(item.totalAmountDisbursed),
                totalAmountRequested: utils.formatAmount(item.totalAmountRequested),
                totalAmountReceived: utils.formatAmount(item.totalAmountReceived),
                totalAmountOutstanding: utils.formatAmount(item.totalAmountOutstanding),
                repaymentPercentage: `${item.repaymentPercentage}%`,
            })) : <LoadingLoop/>
        };
    }

    const viewAllCohortInProgramDetailsData = formatCohort(viewAllProgramCohortDetails!);

    const fetchAllCohort = async () => {
        try {
            setSkeletonLoader(true);
            const allCohorts = await cohortService.viewAllCohortInProgram(data);
            setAllCohortData(allCohorts);
            setSkeletonLoader(false);
        } catch (error) {
            utils.handleRequestError(error);
        }
    };

    const tableData = useAppSelector(state => state.cohort.allCohortData)

    useEffect(() => {
        if (tableData === undefined) {
            fetchAllCohort().then();
        }
    }, [])

    return (
        <>
            {
                skeletonLoader ?
                    <div id={'skeletonDiv'} className={`flex flex-col gap-3`}>
                        <Skeleton id={"jwhwjdk"} variant="rectangular" height={40}/>
                        <Skeleton id={"nfaojnd"} variant="rectangular" height={40}/>
                        <Skeleton id={"cnuienje"} variant="rectangular" height={40}/>
                        <Skeleton id={"caiweo"} variant="rectangular" height={40}/>
                        <Skeleton id={"cnidsj"} variant="rectangular" height={40}/>
                    </div> :

                    <AllCohortsInProgramTable allCohortTableData={viewAllCohortInProgramDetailsData}/>
            }
        </>
    );
}

export default AllCohortIndex;