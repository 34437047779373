import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "../features/userManagement/auth/login/Login";
import ForgetPassword from "../features/userManagement/auth/forgetPassword/Index";
import CreatePassword from "../features/userManagement/auth/createPassword/createPassword";
import ResetPassword from "../features/userManagement/auth/resetPassword";
import React from "react";
import Protected from "./Protected";
import Redirect from "./Redirect";
import Investor from "../features/dashboard/investor/investorDashboard/index";
import LoanRequest from "../features/dashboard/trainee/loanRequest/index";
import Index from "../features/dashboard/trainee/viewLoanOffer";
import ViewTraineeDetails from "../features/dashboard/trainee/viewTraineeDetailsAsATrainee/index";
import InviteColleague from "../features/dashboard/portfolioManager/inviteColleague/index";
import LoanProduct from "../features/dashboard/loan/LoanProduct";
import AllCohortsInProgram from "../features/dashboard/cohort/AllCohortsInProgram/allCohortIndex";
import ViewLoanRequestIndex from "../features/dashboard/loan/loanRequest/viewLoanRequestDetails";
import ViewLoanOfferIndex from "../features/dashboard/loan/loanOffer/allLoanOffer/loanOfferDetailPage";
import CohortDetail from "../features/dashboard/cohort/cohort/CohortDetail";
import Otp from "../features/userManagement/auth/otp/otp";


interface IProps {
}


const RootNavigation = (props: IProps) => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/forgot-password" element={<ForgetPassword/>}/>
                <Route path="/reset-password" element={<ResetPassword/>}/>
                <Route path="/create-password" element={<CreatePassword/>}/>
                <Route path="/investor" element={<Investor/>}/>
                <Route path="/view-loan-offer-as-a-trainee" element={<Index/>}/>
                <Route path="/cohort/cohort-details" element={<CohortDetail/>}/>
                <Route path="/request" element={<LoanRequest/>}/>
                <Route path="/all-cohort" element={<AllCohortsInProgram/>}/>
                <Route path="/details" element={<ViewTraineeDetails/>}/>
                <Route path={`/colleague`} element={<InviteColleague/>}/>
                <Route path={`/view-loan-product`} element={<LoanProduct/>}/>
                <Route path={`/loan/view_loan_request`} element={<ViewLoanRequestIndex/>}/>
                <Route path={`/loan/view_loan_offer`} element={<ViewLoanOfferIndex/>}/>
                <Route path={`/redirect`} element={<Redirect/>}/>
                <Route path={`/otp`} element={<Otp/>}/>
                <Route path="/*" element={<Protected/>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default RootNavigation;
