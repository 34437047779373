import React from "react";
import {FiDatabase} from "react-icons/fi";
import {NavLink} from "react-router-dom";
import {LuBook, LuBriefcase, LuPanelTop} from "react-icons/lu";
import {GoPerson} from "react-icons/go";
import {TbUsersGroup} from "react-icons/tb";
import {HiOutlineBookOpen} from "react-icons/hi";
import {names, roles} from "../../../utils/roles";


const TopNavButton = ({role, setSelectedName}: any) => {


    const buttonData = [
        {
            name: names.FUNDS,
            icon: <FiDatabase/>,
            to: "/fund",
            role: [roles.PORTFOLIO_MANAGER, roles.SUPER_ADMIN]
        },
        {
            name: names.INVESTORS,
            icon: <LuBriefcase/>,
            to: "/investor",
            role: [roles.PORTFOLIO_MANAGER, roles.SUPER_ADMIN]
        },
        {
            name: names.PROGRAMS,
            icon: <HiOutlineBookOpen/>,
            to: "/program",
            role: roles.INSTITUTE_ADMIN,
        },
        {
            name: names.COHORTS,
            icon: <TbUsersGroup/>,
            to: "/cohort",
            role: roles.INSTITUTE_ADMIN,
        },
        {
            name: names.LOANS,
            icon: <LuPanelTop/>,
            to: "/loan",
            role: [roles.PORTFOLIO_MANAGER, roles.SUPER_ADMIN],
        },
        {
            name: names.INSTITUTES,
            icon: <LuBook/>,
            to: "/institute",
            role: [roles.PORTFOLIO_MANAGER, roles.SUPER_ADMIN],
        },
        {
            name: names.TRAINEES,
            icon: <GoPerson/>,
            to: "/trainees",
            role: [roles.PORTFOLIO_MANAGER, roles.INSTITUTE_ADMIN, roles.SUPER_ADMIN],
        },
    ];

    const filteredButtons = buttonData.filter((button) => button.role.includes(role))
    return (
        <div
            id={'mainDivTopNav'}
            className="flex justify-center items-stretch z-2000 border-t border-solid border-[#D0D5DD] md:border-none md:relative fixed bottom-0 w-full pl-0 md:bottom-[unset] md:right-[unset] md:left-[unset] md:w-auto bg-white">
            {filteredButtons.map((button, index) => (
                <NavLink
                    id={`NavLink${button.name}bhj`}
                    to={button.to}
                    key={index}
                    end
                    className={({isActive}) => isActive || window.location.pathname.startsWith(button.to)
                        ? "mx-2 md:px-4 px-1 py-2 hover:text-[#0EAA4F] flex flex-col items-center justify-center text-center md:py-6 md:text-base text-[#0EAA4F] border-b-2 border-[#0EAA4F] "
                        : "mx-2 md:px-4 px-1 py-2 hover:text-[#0EAA4F] flex flex-col items-center justify-center text-center md:py-6 md:text-base"
                    }
                >
                    <div id={`icon${button.name}jh`} className="text-2xl md:text-[unset]">{button.icon}</div>
                    <p id={`name${button.name}jh`}>{button.name}</p>
                </NavLink>
            ))}
        </div>
    );
};

export default TopNavButton;