import React, {useEffect, useState} from 'react';
import TableTop from "../../../../components/reusables/tableTop/TableTop";
// import LoanProductTable from "./LoanProductTable";
import loanProductService from "../../../../services/loanProduct.service";
import CreateLoanProduct from "./CreateLoanProduct";
import {useAppSelector} from "../../../../redux/hooks/hooks";
import utils from "../../../../utils/utils";
import LoadingLoop from "../../../../components/loadingLoop/LoadingLoop";
// @ts-ignore
import LoanProductTable from "./LoanProductTable.tsx";


interface IProps {
}

function Index(props: IProps) {
    const [openModal, setOpenModal] = React.useState(false);
    const [loanProductData, setLoanProductData] = useState<any>({});
    const closeModal = () => {
        setOpenModal(false);
    }

    const pageNumber = useAppSelector(state => state.table?.pageNumber);
    const data = {pageNumber}

    const fetchLoanProductData = async () => {
        try {
            const response = await loanProductService.viewLoanProduct(data);
            setLoanProductData(response);
        }
        catch(error){
            utils.handleRequestError(error)
        }
    }

    useEffect(() => {
        fetchLoanProductData().then();
    }, [pageNumber, openModal]);

    function formatLoanProduct(data: { content: any[] | null | undefined }) {
        return {
            ...data,
            content: Array.isArray(data?.content) ? data.content.map(item => ({
                ...item,
                createdAtDate: utils.formatDate(item.createdAtDate),
                interestRate: `${item.interestRate}%`,
                amountDisbursed: utils.formatAmount(item.amountDisbursed),
                amountRepaid: utils.formatAmount(item.amountRepaid),
                amountEarned: utils.formatAmount(item.amountEarned),
                tenor: `${item.tenor} ${item.tenureStatus}`,
                moratorium: `${item.moratorium} ${item.moratoriumStatus}`
            })) : <LoadingLoop/>
        };
    }

    return (
        <>
            <div id={'tableTopDiv'} className={"flex flex-col h-full py-[20px] px-[40px] w-screen md:w-auto"}>
                <TableTop
                    placeholder={"Find Loan Product"}
                    buttonClick={() => setOpenModal(true)}
                    title={"Create Loan Product"}
                    setButtonNeeded={true}
                />
                <LoanProductTable loanProductTableData={formatLoanProduct(loanProductData)}/>
                <CreateLoanProduct
                    openModal={openModal}
                    title={"Create"}
                    closeModal={closeModal}/>
            </div>
        </>
    );
}

export default Index;
