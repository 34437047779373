import React, {useState} from 'react';
import Layout from "../../../../components/reusables/layout/Layout";
import LoanRequestTable from "./LoanRequestTable";
import {TableData} from "../../../../types/commons.type";
import {emptyLoanRequest, loanRequestData} from "../../../../types/trainee/trainee.type";
import TableTop from "../../../../components/reusables/tableTop/TableTop";
import ReferTraineeModal from "../referTraineeModal/ReferTraineeModal";

interface IProps {
}

function Index(props: IProps) {
    const [openModal, setOpenModal] = useState(false);
    const [loanRequestData, setLoanRequestData] = useState<TableData<loanRequestData>>(emptyLoanRequest);
    const closeModal = () => {
        setOpenModal(false);
    }



    return (
        <Layout>
            <div id={"cjsdk"} className={`flex flex-col h-full py-[20px] px-[40px] w-screen md:w-auto`}>
                    <TableTop
                        placeholder={"Find Cohort"}
                        buttonClick={() => setOpenModal(true)}
                        title={"Refer Trainee"}
                        setButtonNeeded={false}
                    />
                <div id={"dsnjb"} className={`flex-1`}>
                    <LoanRequestTable tableData={loanRequestData}/>
                </div>
                <ReferTraineeModal close={closeModal} isOpen={openModal}/>
            </div>
        </Layout>
    );
}

export default Index;