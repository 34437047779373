import React from 'react';
import {useAppSelector} from "../../../../redux/hooks/hooks";
import {buttonNames} from "../../../../utils/roles";
import CurrentCohortTable from "../CurrentCohort/index";
import IncomingCohortIndex from "../IncomingCohort/index";
import GraduatedCohortTable from "../GraduatedCohort/index";
import AllCohortsInProgram from "../AllCohortsInProgram/allCohortIndex";
import IncomingCohortInProgramIndex from "./IncomingCohortInProgram";

interface IProps {
}

function SelectCohortsInProgram(props: IProps) {
    const selectedCohort = useAppSelector((state) => state.cohort.buttonName);
    switch (selectedCohort) {

        case buttonNames.INCOMING_COHORT:
            return (
                <IncomingCohortInProgramIndex/>
            );
        default:
            return null;
    }
}

export default SelectCohortsInProgram;