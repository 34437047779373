import styles from "./styles/SideBar.module.css";
import {FiChevronLeft, FiChevronRight} from "react-icons/fi";
import {useNavigate} from "react-router-dom";
import React from "react";
import SideNavButton from "./SideNavButton";

interface IProps {
    className: string;
    mobileOpen: any;
    barCollapse: boolean;
    setBarCollapse: React.Dispatch<React.SetStateAction<boolean>>;
    selectedName: string | null;
}

function SideBar({className, mobileOpen, barCollapse, setBarCollapse, selectedName}: IProps) {

    const navigate = useNavigate()

    return (
        <>
            <div id={'sideBarMainDiv'} className="hidden sm:block"/>
            <div
                id={'barCollapse'}
                className={`hidden sm:flex fixed z-[1001] left-0 top-[102px] bottom-0 h-[calc(100%_-_102px)] bg-white transition-all duration-200 ease-in ${barCollapse ? "w-[80px]" : "w-[250px]"} ${className}`}
            >
                <div
                    id={'toggleContainer'}
                    className={`${styles.toggleContainer}`}
                    onClick={() => setBarCollapse(!barCollapse)}
                >
                    {barCollapse ? (
                        <FiChevronRight className={styles.rightToggle}/>
                    ) : (
                        <FiChevronLeft className={styles.leftToggle}/>
                    )}
                </div>
                <div id={'SideNavButtonDiv'} className={`w-full`}>
                    <SideNavButton
                        selectedName={selectedName}
                        barCollapse={barCollapse}
                    />
                </div>
            </div>


        </>
    )
}

export default SideBar;
