import styled from "styled-components";

export const InputDiv = styled.div`
  position: relative;
  height: 3rem;

  input {
    display: block;
    padding: 10px 14px;
    width: 100%;
    height: 40px;
    border: 1px solid #d0d5dd;
    border-radius: 4px;
    outline: none;
  }

  img {
    position: absolute;
    width: 1rem;
    top: 40%;
    right: 1%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
  }
  @media (max-width: 769px) {
  }
`;
