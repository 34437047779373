import React from 'react';
import LearnSpaceTable from "../../../../components/reusables/table/Table";
import {IColumnProps} from "../../../../types/commons.type";
import {useNavigate} from "react-router-dom";
import noInstitute from "../../assets/images/empty_state_institute.svg";
import {useAppSelector} from "../../../../redux/hooks/hooks";
import {store} from "../../../../redux/store";
import {cohortActions} from "../../../../redux/slices/cohortSlice";

interface IProps{
    allCohortTableData: any;
}


const columns: IColumnProps[] = [
    {
        title: 'Cohort Name',
        selector: 'cohortName',
        sortable: true,
    },
    {
        title: 'Program Name',
        selector: 'programName',
        sortable: true,
    },
    {
        title: 'Number of Trainees',
        selector: 'numberOfTrainee',
        sortable: true,
    },
    {
        title: 'Start Date',
        selector: 'cohortStartDate',
        sortable: true,
    },
    {
        title: 'End Date',
        selector: 'endDate',
        sortable: true,
    },
    {
        title: 'Tuition Amount',
        selector: 'tuitionAmount',
        sortable: true,
    },

    {
        title: 'Total Amount Requested',
        selector: 'totalAmountRequested',
        sortable: true,
    },

    {
        title: 'Total Amount Received',
        selector: 'totalAmountReceived',
        sortable: true,
    },
    {
        title: 'Total Amount Outstanding',
        selector: 'totalOutstanding',
        sortable: true,
    },

]
function AllCohortsInProgramTable(props: IProps) {
    const navigate = useNavigate();

    function handleViewCohortDetails(row: { cohortId: string; programId:string; cohortName:string; programName:string; cohortStartDate:string; cohortEndDate:string; tuitionAmount:number}) {
        console.log(row, "rows i");
        store.dispatch(cohortActions.setCohortDetails({
            cohortId:row.cohortId,
            programId:row.programId,
            cohortName: row.cohortName,
            programName:row.programName,
            cohortStartDate:row.cohortStartDate,
            cohortEndDate:row.cohortEndDate,
            tuitionAmount:row.tuitionAmount
        }));

        navigate("/institute/view/cohort")
    }
    const tableData = useAppSelector(state => state.cohort.allCohortData)
    const getSearchString = useAppSelector((state)=> state.search.searchString)

    return (
        <LearnSpaceTable
            columns={columns}
            searchString={getSearchString}
            searchColumn="cohortName"
            emptyTable={{
                image: noInstitute,
                title: "No Cohort Found",
                subText: "Registration for  Cohort Ongoing",
                text: "",
            }}
            path={""}
            handleRowClick={(row: any) => handleViewCohortDetails(row)}
            tableData={!!tableData ? tableData : props.allCohortTableData}
        />
    );
}

export default AllCohortsInProgramTable;
