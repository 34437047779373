import React, {MouseEvent, useState} from "react";
import AuthBackground from "../../reuseables/background/AuthBackground";
import PasswordInput from "../../reuseables/password/PasswordInput";
import Button from "../../reuseables/button/Button";
import {InnerPageFormDiv} from "../forgetPassword/Index.style";
import {useLocation, useNavigate} from "react-router-dom";
import PasswordValidator from "../../reuseables/password/checker/PasswordValidator";
import authService from "../../../../services/auth.service";
import {IPasswordRequest} from "../../../../types/auth.type";
import utils from "../../../../utils/utils";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("ref");

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    setIsPopoverOpen(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const checkPasswordConditions = () => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;
    return regex.test(password);
  };

  const handleButtonClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const values: IPasswordRequest = {
      authorization: token,
      authentication: password,
    };
    try {
      if (checkPasswordConditions()) {
        setIsLoading(true);
        const response = await authService.resetPassword(values);
        if (response.successful) {
          console.log("Successful"); // Set reset success
        }
      }
    } catch (err: any) {
      utils.handleRequestError(err)
    } finally {
      setResetSuccess(true);
    }
  };

  return (
    <AuthBackground>
      <div id={'InnerPageFormDivRounded'} className="bg-white rounded-lg p-6">
        <InnerPageFormDiv id={'InnerPageFormDiv'}>
          <h5 id={'resetPassword'}>Reset Password?</h5>
          <small id={'fewSeconds'} className="text-[#57595D]">Just a few seconds !</small>
          <div id={'emailDiv'} className="mt-4">
            <label
                id={'emailLabel'}
              htmlFor="email"
              className=" text-sm font-medium text-[#101828"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full mt-1 p-2 border rounded"
            />
          </div>
          <div id={'passwordDiv'} className="mt-4">
            <label id={'passwordLabel'}>Password</label>
            <PasswordInput
              value={password}
              onChange={handlePasswordChange}
              showPassword={showPassword}
              togglePasswordVisibility={togglePasswordVisibility}
            >
              <PasswordValidator
                password={password}
                isOpen={isPopoverOpen}
                setIsOpen={setIsPopoverOpen}
              />
            </PasswordInput>
          </div>
        </InnerPageFormDiv>

        {resetSuccess ? (
          <Button
            text={"Reset Password Successful"}
            style={{
              backgroundColor: "#45a049",
              marginTop: "15%",
            }}
          />
        ) : (
          <Button
            text={"Send"}
            style={{
              backgroundColor: checkPasswordConditions()
                ? "#45a049"
                : "#90d8ae",
              marginTop: "15%",
            }}
            onClick={handleButtonClick}
            disabled={!checkPasswordConditions() || isLoading}
            loading={isLoading}
          />
        )}
      </div>
    </AuthBackground>
  );
}

export default ResetPassword;
