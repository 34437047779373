import React, {useState} from "react";
import Layout from "../../../../components/reusables/layout/Layout";
import ProgramsCohortTableTop from "./ProgramsCohortTableTop";
import CreateCohortModal from "../cohort/CreateCohortModal";
import SelectCohortsInProgram from "./SelectCohortsInProgram";
import {buttonNames} from "../../../../utils/roles";


function Index() {
    const [openModal, setOpenModal] = useState(false);

    const closeModal = () => {
        setOpenModal(false);
    };

    const isModalOpen = () => {
        setOpenModal(!openModal);
    }

    function handleBackClick() {
        window.history.back();
    }

    const handleTopButtonClick = () => {
        setOpenModal(true);
    };

    const buttons = [
        {label: "All", className: "customBtn", name: buttonNames.ALL_COHORT},
        {label: "Incoming", className: "customBtn", name: buttonNames.INCOMING_COHORT},
        {label: "Current", className: "customBtn", name: buttonNames.CURRENT_COHORT},
        {label: "Graduated", className: "customBtn", name: buttonNames.GRADUATED_COHORT},
    ];

    const topButtons = [
        {label: "Create Cohort", onClick: handleTopButtonClick, className: "topBtn"},
    ];
    return (
        <Layout>
            <div id={'programsCohortTableTop'}
                 className={`flex flex-col h-full py-[20px] px-[40px] w-screen md:w-auto`}>
                <ProgramsCohortTableTop
                    title="Cohorts"
                    onBackClick={handleBackClick}
                    buttons={buttons}
                    topButtons={topButtons}
                />
                <CreateCohortModal
                    openModal={openModal}
                    onClose={closeModal}
                />
                <SelectCohortsInProgram/>
            </div>
        </Layout>
    )
}

export default Index;