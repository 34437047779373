export interface ILoanApplicationDetails{

}

interface BvnVerificationData {
    firstName: string;
    middleName: string;
    lastName: string;
    dateOfBirth: string;
    phoneNumber1: string;
    residentialAddress: string;
    gender: string;
    email: string;
    phoneNumber2: string;
    levelOfAccount: string;
    lgaOfOrigin: string;
    lgaOfResidence: string;
    maritalStatus: string;
    nationality: string;
    stateOfOrigin: string;
    stateOfResidence: string;
    nin: string;
    nameOnCard: string;
    registrationDate: string;
    watchListed: string;
    title: string;
    enrollmentBank: string;
    enrollmentBranch: string;
    bvn: string;
    number: string;
}

export interface BvnVerificationResponse {
    status: boolean;
    detail: string;
    data: BvnVerificationData;
    verification: {
        reference: string;
    };
    response_code: string;
}


export interface PersonalInfo {
    traineeId?: string;
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    middleName?: string;
    dateOfBirth?: Date | null;
    phoneNumber?: string;
    address?: string;
    gender?: string;
    maritalStatus?: string;
    stateOfOrigin?: string;
    bvn?: string;
    nin?: string;
    nationality?: string;
    picture?:string;
}

export interface AdditionalInfo {
    alternateEmailAddress: string;
    alternatePhoneNumber: string;
    alternateContactAddress: string;
    nextOfKinFirstName: string;
    nextOfKinLastName: string;
    nextOfKinEmailAddress: string;
    nextOfKinPhoneNumber: string;
    nextOfKinAddress: string;
    nextOfKinRelationship: string;
}

// personalInformation: {
//     firstName: "",
//         lastName: "",
//         emailAddress: "",
//         middleName: "",
//         dateOfBirth: null,
//         phoneNumber: "",
//         address: "",
//         gender: "",
//         maritalStatus: "",
//         stateOfOrigin: "",
//         bvn: "",
//         nin: "",
// },

export enum TraineeStatus {
    ADDED = "ADDED",
    REFERRED = "REFERRED",
    INVITED = "INVITED",
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
    IN_REVIEW = "IN_REVIEW",
    APPLIED = "APPLIED",
}

export interface NINVerificationResponse {
    status: boolean;
    detail: string;
    verification: {
        reference: string;
    };
    response_code: string;
    nin_data: NINData;
}

interface NINData {
    birthcountry: string;
    birthdate: string;
    educationallevel: string;
    employmentstatus: string;
    firstname: string;
    gender: string;
    heigth: string;
    maritalstatus: string;
    middleName: string | null;
    nin: string;
    nok_address1: string;
    nok_address2: string;
    nok_firstname: string;
    nok_lga: string;
    nok_middlename: string;
    nok_state: string;
    nok_surname: string;
    nok_town: string;
    spoken_language: string;
    ospokenlang: string;
    profession: string;
    religion: string;
    residence_address: string;
    residence_town: string;
    residence_lga: string;
    residence_state: string;
    residencestatus: string;
    surname: string;
    telephoneno: string;
    title: string;
    trackingId: string;
    vnin: string;
    pfirstname: string;
    psurname: string;
    nok_postalcode: string;
    pmiddlename: string;
    birthlga: string;
    self_origin_state: string;
    email: string;
    self_origin_place: string;
    userid: string;
    self_origin_lga: string;
    birthstate: string;
}
