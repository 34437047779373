import styled from "styled-components";

export const FormDiv = styled.form`
  background-color: white;
  padding: 40px 50px 29px 50px;
  min-height: 418px;
  border-radius: 10px;

  h5 {
    margin-bottom: 4px;
    font-family: "IBM Plex Sans", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #101828;
  }

  p {
    color: #57595d;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 25px;
  }

  @media (max-width: 769px) {
    justify-content: center;
    align-items: center;
    padding: 0;
  }
`;

export const MobileLearnSpaceLogo = styled.div`
  position: absolute;
  width: 25%;
  top: 1.5rem;
  //left: 3rem;
  margin-bottom: 5rem;

  @media (min-width: 769px) {
    display: none;
  }
`;
