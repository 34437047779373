import React, {useEffect, useState} from "react";
// @ts-ignore
import styles from "../styles/CohortDetail.module.css";
// @ts-ignore
import wallet from "../../assets/entypo_wallet.svg";
import EditCohortModal from "./EditCohortModal";
import cohortService from "../../../../services/cohort.service";
import {CohortDetails, CohortEmptyData} from "../../../../types/cohort/cohort.type";
import RemoveModal from "../../../../components/reusables/removeModal/RemoveModal";
import Layout from "../../../../components/reusables/layout/Layout";
import {getInstituteId, getSelectedCohort} from "../../../../utils";
import utils from "../../../../utils/utils";
import BreadCrumbs from "../../../../components/breadCrumb/BreadCrumbs";
import {Toast, ToastTypes} from "../../../../components/Toast";
import {useNavigate} from "react-router-dom";
import {cohortActions} from "../../../../redux/slices/cohortSlice";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks/hooks";

interface IProps {

}

function Card({title, amount}: any) {
    return (
        <div id={`cardMainDiv${title}`} className="bg-white rounded-lg shadow-md p-6 w-[220px] h-[150px]">
            <div id={`cardInnerDiv${title}`}>
                <img id={'wallateImg'} src={wallet} alt=""/>
                <p id={'titlePTagTop'} className={`mt-[20px]`}>{title}</p>
                <p id={'titlePTagButton'} className={`font-bold text-xl`}>{amount}</p>
            </div>
        </div>
    );
}

function CohortDetail(props: IProps) {

    const [cohortId, programId] = getSelectedCohort()
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const pmInstituteId = useAppSelector(state => state.institute.instituteId);
    const instituteId = getInstituteId() ?? pmInstituteId;
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [removeModalOpen, setDeleteModalOpen] = useState(false);

    const [cohortData, setCohortData] = useState<CohortDetails>(CohortEmptyData);

    const pageNumber = useAppSelector(state => state.table?.pageNumber)
    const data = {instituteId, programId, cohortId};


    useEffect(() => {
      const fetchData = async () => {
            try {
                const response = await cohortService.getCohort(data);
                setCohortData(response);

            } catch (error) {
                utils.handleRequestError(error)
            }
        };
        fetchData().then((r) => r);
    }, [editModalOpen]);


    const openEditModal = () => {
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
    };

    const openDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    const deleteCohort = async () => {
        try {
            const response = await cohortService.delete(data);
            if (response) {
                Toast({content: "Cohort Deleted Successfully", type: ToastTypes.SUCCESS})
                await fetchIncomingCohort();
                closeDeleteModal()
                navigate("/cohort")
            }
        } catch (error) {
            utils.handleRequestError(error);
        }
    }

    const reFetchData = {cohortStatus: "INCOMING", instituteId}

    const fetchIncomingCohort = async () => {
        try {
            const incomingCohorts = await cohortService.viewIncomingCohort(reFetchData);
            dispatch(cohortActions.setIncomingCohortData(incomingCohorts));
        } catch (error) {
            utils.handleRequestError(error);
        }
    };


    const [showDropdown, setShowDropdown] = useState(false);

    const handleToggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleEdit = () => {
        openEditModal();
        setShowDropdown(false);
    };

    const handleDelete = () => {
        openDeleteModal();
        setShowDropdown(false);
    };

    // console.log("cohort date", cohortData.startDate);

    const startDate = utils.formatDateFromArray(cohortData?.startDate);
    const endDate = utils.formatDateFromArray(cohortData?.endDate);

    return (
        <Layout>
            <div id={'cohortIdMainDiv'}>
                <div id={'BreadCrumbsDiv'} className={`flex justify-between items-center m-[50px]`}>
                    <BreadCrumbs/>
                    <div id={'handleToggleDropdownDiv'} className="relative">
                        <button
                            id={'handleToggleDropdown'}
                            className="rounded-full bg-white w-[40px] h-[40px] text-lg font-bold"
                            onClick={handleToggleDropdown}
                        >
                            ⋮
                        </button>
                        {showDropdown && (
                            <div id={'showDropdown'}
                                 className={`absolute bg-white rounded-md shadow-lg mt-3.5 w-[100px] transform -translate-x-1/2 left-1/2 ${styles.dropdown}`}
                            >
                                <button
                                    id={'handleEdit'}
                                    className="block px-4 py-2 text-sm text-gray-800 hover:text-[#0D9B48] hover:font-bold"
                                    onClick={handleEdit}
                                >
                                    Edit
                                </button>
                                <button
                                    id={'handleDelete'}
                                    className="block px-4 py-2 text-sm text-gray-800 hover:text-red-600 hover:font-bold"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                <div id={'backgroundOuterDiv'} className={`mr-[50px] ml-[100px]`}>
                    <div id={'backgroundInnerDiv'} className={styles.background}>
                        <div id={'items'} className={styles.items}>
                            {/*rewrite using map!*/}
                            <div id={'Cohort'}>
                                <p id={'cohortName'}>Cohort Name</p>
                                {/*<p>Maven</p>*/}
                                <p id={'cohortData'}>{!!cohortData.name ? cohortData.name : "N/A"}</p>
                            </div>
                            <div id={'Program'}>
                                <p id={'programName'}>Program Name</p>
                                {/*<p>Design Thinking</p>*/}
                                <p id={'programName'}>{!!cohortData.programName ? cohortData.programName : "N/A"}</p>
                            </div>
                            <div id={'Trainees'}>
                                <p id={'TraineesPTag'}>Number of Trainees</p>
                                <p id={'numberOfTrainees'}>{!!cohortData.numberOfTrainee ? cohortData.numberOfTrainee : "N/A"}</p>
                            </div>
                            <div id={'TuitionMainDiv'}>
                                <p id={'TuitionPTag'}>Tuition Amount</p>
                                <p id={'formatAmountButton'}>{!!cohortData.tuition ? utils.formatAmount(cohortData.tuition) : "₦0.00"}</p>
                            </div>
                            <div id={'startDateDIiv'}>
                                <p id={'startDAtePTag'}>Start Date</p>
                                {/*<p id={'toDateString'}>{startDate.toDateString() ?? "N/A"}</p>*/}
                                {/*<p id={'toDateString'}>{!!cohortData.startDate ? cohortData.startDate as unknown as string : "N/A"}</p>*/}
                                <p id={'toDateString'}>{startDate ?? "N/A"}</p>
                            </div>
                            <div id={'endDateDiv'}>
                                <p id={'endDate'}>End Date</p>
                                <p id={'endDatetoDateString'}>{endDate ?? "N/A"}</p>
                            </div>
                            <div id={'statusDiv'}>
                                <p id={'cohortStatus'}>Cohort Status</p>
                                <p id={'Active'} style={{color: "green"}}>Active</p>
                            </div>
                        </div>
                    </div>

                    {/*Second Container*/}

                    <div id={'backgroundMainDiv'} className={styles.background}>
                        <div id={'itemsBackgroundInnerDIv'} className={styles.items}>
                            <div id={'completionRateOuterDIv'}>
                                <p id={'completionRatePTag'}>Completion Rate</p>
                                <p id={'NumberPercent '}>0%</p>
                            </div>
                            <div id={'numberEmployedOuterDIv'}>
                                <p id={'NumberEmployedPTag'}>Number Employed</p>
                                <p id={'numberPTag'}>0</p>
                            </div>
                            <div id={'DropoutsDIv'}>
                                <p id={'DropoutsPTag'}>Number of Dropouts</p>
                                <p id={'DropoutsNumberPTag'}>0</p>
                            </div>
                            <div id={'StartingDiv'}>
                                <p id={'StartingPtagAverage'}>Average Starting Salary</p>
                                <p id={'empytNAPTag'}>N/A</p>
                            </div>
                            <div id={'EmploymentSecondDiv'}>
                                <p id={'EmploymentPTagInner'}>Employment Rate</p>
                                <p id={'EmploymentInnerEmptyNAPTag'}>N/A</p>
                            </div>
                            <div id={'RateDropoutMainDiv'}>
                                <p id={'secondDropoutRate'}>Dropout Rate</p>
                                <p id={'RateDropoutPtagEmptyNA'}>N/A</p>
                            </div>
                        </div>
                    </div>

                    {/*Third Container*/}

                    <div id={'cardsOutterDIv'} className={styles.background}>
                        <div id={'cardsInnerDIv'} className={styles.items}>
                            <Card title={"Amount disbursed"}
                                  amount={utils.formatAmount(cohortData.cohortLoanDetailResponse.totalAmountDisbursed!)}/>
                            <Card title={"Amount Repaid"}
                                  amount={utils.formatAmount(cohortData.cohortLoanDetailResponse.totalAmountRepaid!)}/>
                            <Card title={"Amount Outstanding"}
                                  amount={utils.formatAmount(cohortData.cohortLoanDetailResponse.totalOutstanding!)}/>
                            <Card title={"Repayment Rate"}
                                  amount={(cohortData.cohortLoanDetailResponse.repaymentPercentage!)}/>
                        </div>
                    </div>
                </div>
            </div>
            <EditCohortModal
                open={editModalOpen}
                close={closeEditModal}
                cohortId={cohortId!}
            />
            <RemoveModal
                isOpen={removeModalOpen}
                closeModal={closeDeleteModal}
                title={"Delete Cohort"}
                message={<p> Are you sure you want to delete this cohort? </p>}
                confirmText={"Yes"}
                onConfirm={deleteCohort}
                cancelText={"No"}
                onCancel={closeDeleteModal}
            />
        </Layout>
    );
}

export default CohortDetail;
