import ApiInstance from "./api";
import {TableData} from "../types/commons.type";
import {IInvestorResponse, IViewInvestorRequest} from "../types/investor.type";
import {store} from "../redux/store"
import {investorActions} from "../redux/slices/investorSlice";

class InvestorService {
    private apiInstance: ApiInstance;
    // @ts-ignore
    private token: string | undefined = store.getState().auth.token?.access_token;


    constructor() {
        // @ts-ignore
        this.apiInstance = new ApiInstance(store.getState().auth.token?.access_token);
    }

    public async addInvestor(data: any): Promise<any> {
        // @ts-ignore
        const test_api = new ApiInstance(store.getState().auth.token?.access_token);
        try {
            const response = await test_api.getInstance().post("/investors/add", data);
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async viewAllInvestor(data: any): Promise<TableData<IInvestorResponse>> {
        try {
            // @ts-ignore
            const test_api = new ApiInstance(store.getState().auth.token?.access_token);
            const response = await test_api.getInstance().post(`/investors/viewAll`, data)
            store.dispatch(investorActions.setInvestor(response.data))
            return response.data;

        } catch (err) {
            throw err;
        }
    }

    public async viewInvestor(viewInvestorRequest: IViewInvestorRequest): Promise<any> {
        try {
            // @ts-ignore
            const test_api = new ApiInstance(store.getState().auth.token?.access_token);
            const response = await test_api.getInstance().get("/program/view")
            return response.data;
        } catch (err) {
            throw err;
        }
    }
}

const investorService = new InvestorService();

export default investorService;
