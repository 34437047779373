import React, {useState} from 'react';
import {HiArrowLeft} from "react-icons/hi";
import {useNavigate} from "react-router-dom";
import {GrClose} from "react-icons/gr";
import styles from "../../trainee/cohortTrainees/styles/TraineesTableTop.module.css";
import search from "../../assets/images/search.svg";
import {programsCohortButtonName} from "../../../../components/reusables/layout/buttonNames";
import {buttonNames} from "../../../../utils/roles";

interface Button {
    label: string;
    name?: buttonNames;
    className?: string;
}

interface IProps {
    setButtonNeeded?: boolean;
    buttonClick?: () => void | any
    title: string;
    onBackClick?: () => void;
    buttons?: Button[];
    topButtons?: Button[];
    buttonName?: string;
}

function ProgramsCohortTableTop({ title,buttonName, onBackClick,setButtonNeeded, buttons, topButtons, buttonClick}: IProps) {
    const navigate = useNavigate();
    const [hideButtons, setHideButtons] = useState(false);
    const [inputFocus, setInputFocus] = useState(false);
    const [closeIcon, setCloseIcon] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        if (inputValue.length > 0) {
            setHideButtons(true);
            setCloseIcon(true);
        } else {
            setHideButtons(false);
            setCloseIcon(false);
        }
        setInputValue(inputValue);
    }

    const handleCloseIcon = () => {
        setInputValue('');
        setCloseIcon(false);
        setHideButtons(false);
    }
    const handleButtonClick = (button: any) => {
        programsCohortButtonName(button.name);
    }
    const [isButtonNeeded, setIsButtonNeeded] = useState(setButtonNeeded);
    return (
        <>
            <div id={'topButtonsTitle'} className="flex justify-between py-4 items-center ">
                <p id={'titlePTag'} className={`text-[25px] font-[500]`}>{title}</p>
                {buttonName && <button
                    id={`titleIsButtonNeededButton`}
                    className={`text-white text bg-[#0D9B48] w-[220px] rounded-[4px] cursor-pointer border-0 px-10 py-2 ml-3
                            ${!isButtonNeeded ? 'hidden' : ''}`}
                    onClick={buttonClick}
                >
                    <p id={`titlePtag`} className='w-[150px]'>{buttonName}</p>
                </button>}
            </div>

            <div id={'threeBtnsOutterDiv'} className={`flex justify-between items-center w-full border rounded-md border-solid border-[#D0D5DD] my-2 py-3 px-2 bg-[#fff]`}>

                <div id={'threeBtnsMainDiv'} className={hideButtons ? styles.off : styles.threeBtns}>
                    {/*<button className="border border-solid border-green-600 rounded-md px-3 py-1 text-green-600 mr-2">*/}
                    {buttons && buttons.map((button, index) => (
                        <button id={`${button.label} button`}  key={index} className="border border-solid border-green-600 rounded-md px-3 py-1 text-green-600 mr-2" onClick={()=>handleButtonClick(button)}>
                            {button.label}
                        </button>
                    ))}
                </div>

                <div id={'inputFocus'} className={`flex items-center border rounded-md border-solid ${inputFocus ? 'border-[#0D9B48]' : 'border-[#D0D5DD]'} w-[300px] px-2 bg-white mr-3`}>
                    <img id={'searchImg'} src={search} alt="search" className="w-6 h-4 ml-2" />
                    <input
                        id={'findTrainee'}
                        type="text"
                        placeholder={`Find Trainee`}
                        className="bg-transparent outline-none border-none flex-1"
                        value={inputValue}
                        onChange={handleChange}
                        onFocus={() => setInputFocus(true)}
                        onBlur={() => setInputFocus(false)}
                    />
                    {closeIcon &&
                        <GrClose
                            id={'GrClose'}
                            onClick={handleCloseIcon}
                            className={`cursor-pointer`}
                        />
                    }
                </div>

            </div>
        </>
    );
}

export default ProgramsCohortTableTop;
