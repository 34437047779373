import React, {useEffect, useState} from 'react';
import Form from "../form/TraineeForm"
import styles from "../styles/LoanApplicationDetails.module.css";
import {Icon} from "@iconify/react";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import {useNavigate} from "react-router-dom";
import utils from "../../../../utils/utils";
import traineeService from "../../../../services/trainee.service";
import {getUserAssociatedData} from "../../../../utils";

function Index() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const traineeId = getUserAssociatedData()?.trainee?.traineeId;
    const data = {
        instituteId: getUserAssociatedData()?.institute?.instituteId,
        programId: getUserAssociatedData()?.program?.programId,
        cohortId: getUserAssociatedData()?.cohort?.cohortId,
        traineeId: traineeId,
    };

    const [details, setDetails] = useState<any>("")

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await traineeService.getTraineeLoanReferral(data);
                setDetails(response);
            } catch (error) {
                utils.handleRequestError(error);
            }
        };
        fetchData().then();
    }, []);

    const acceptReferral = async()=> {
        try {
            const response = await traineeService.acceptReferral({traineeId});
            sessionStorage.setItem("loanReferralId", response?.data?.loanReferralId);
        } catch (error) {
            utils.handleRequestError(error);
        }
    }

    const renderDetail = (label: string, value: any) => (
        <div id={`nlsnlkdkjske`} className={``} key={label}>
            <div id={`bsbdhjkeire`}>
                <p id={`shiejwibahbjewu`} className={styles.label}>{label}</p>
                <div id={`bhjkdbwuehj`} className={styles.value}>{value || 'N/A'}</div>
            </div>
        </div>
    );

    const instituteDetails = [
        {label: "Training Institute Name", value: details?.instituteName},
        {label: "Program Name", value: details?.programName},
        {label: "Cohort Name", value: details?.cohortName},
    ]

    const loanDetails = [
        {label: "Loan Amount Requested", value: utils.formatAmount(details?.loanAmountRequested)},
        {label: "Tuition Amount", value: utils.formatAmount(details?.tuition)},
        {label: "Initial Deposit", value: utils.formatAmount(details?.initialDeposit)}
    ]

    const handleBtnClick = () => {
        acceptReferral().then();
        navigate("/loan-application/id-verification")
    }

    return (
        <Form formName={`Loan Application Details`}>
            <div id={`bchskdbkueh`}>
                <div id={`sbifbjfdre`} className={`p-6`}>
                    <div id={`sjkdijdnjkdf`}>
                        <p id={`sjknfivemnckd`} className={styles.detailName}>INSTITUTE DETAILS</p>
                        <div id={`sjdvliejkeik`} className={`${styles.detail}`}>
                            {instituteDetails.map(({label, value}) => renderDetail(label, value))}
                        </div>
                    </div>

                    <div id={`nkcajdsie`}>
                        <p id={`nsjnaklnieksdjn`} className={styles.detailName}>LOAN DETAILS</p>
                        <div id={`sdjknkaljnfiewur`} className={`${styles.detail}`}>
                            {loanDetails.map(({label, value}) => renderDetail(label, value))}
                        </div>
                    </div>
                </div>
                <div id={`bcjabibjsdbb`} className="mt-[20px] md:flex md:justify-end align-middle border border-b-3 p-3">
                    <button id={`cjnajknljnlsn`} className={styles.continue} onClick={handleBtnClick}>
                        {isLoading ? (
                            <div id={`sihneriyr`} className="flex items-center justify-center">
                                <Icon id={`cndjnejndfknk`} icon={loadingLoop} width={24} height={24}/>
                            </div>
                        ) : (
                            "Continue"
                        )}
                    </button>
                </div>
            </div>
        </Form>
    );
}

export default Index;