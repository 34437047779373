import React, {Dispatch, FC, useEffect} from "react";
import {Popover} from "react-tiny-popover";
import checked from "../assets/checked.png";
import unchecked from "../assets/unchecked.png";
import {ValidationContainer, ValidationItem, ValidationSpan,} from "./PasswordValidator.style";
import "./PasswordValidatorStyle.css";

interface IProps {
  password: string;
  isOpen: boolean;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
}

const PasswordValidator: FC<IProps> = ({ password, isOpen, setIsOpen }) => {
  useEffect(() => {
    const hasMinimumLength = password.length >= 8;
    const hasLowerCaseCharacter = /[a-z]/.test(password);
    const hasUpperCaseCharacter = /[A-Z]/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*]/.test(password);
    const hasNumber = /\d/.test(password);

    if (
      hasMinimumLength &&
      hasLowerCaseCharacter &&
      hasUpperCaseCharacter &&
      hasSpecialCharacter &&
      hasNumber
    ) {
      setIsOpen(false);
    }
  }, [password, setIsOpen]);

  return (
    <div id={'popOver'} className="pop-over-container">
      <Popover
        isOpen={isOpen}
        positions={["top", "right", "left", "bottom"]}
        onClickOutside={() => setIsOpen(false)}
        content={
          <ValidationContainer id={'ValidationContainer'}>
            <ValidationItem id={'ValidationItem'}>
              <div className={`text-[12px] font-[500] line-height-6`}>New Password;</div>
            </ValidationItem>

            <ValidationItem id={'least'}>
              <ValidationSpan id={'lower'}>At least 1 lower case e.g ab</ValidationSpan>
              {/[a-z]/.test(password) ? (
                <img id={'checked'} src={checked} alt="Valid" />
              ) : (
                <img id={'unchecked'} src={unchecked} alt="Invalid" />
              )}
            </ValidationItem>

            <ValidationItem id={'upper'}>
              <ValidationSpan id={'case'}>At least 1 upper case e.g AB</ValidationSpan>
              {/[A-Z]/.test(password) ? (
                <img id={'Valid'} src={checked} alt="Valid" />
              ) : (
                <img id={'Invalid'} src={unchecked} alt="Invalid" />
              )}
            </ValidationItem>

            <ValidationItem id={'alphanumeric'}>
              <ValidationSpan>Must be alphanumeric E.g pear3214</ValidationSpan>
              {/\d/.test(password) ? (
                <img id={'checkedValid'} src={checked} alt="Valid" />
              ) : (
                <img id={'uncheckedInvalid'} src={unchecked} alt="Invalid" />
              )}
            </ValidationItem>

            <ValidationItem id={'MustValidationItem'}>
              <ValidationSpan id={'lengthValidationSpan'}>Must be 8-16 in length</ValidationSpan>
              {password.length >= 8 ? (
                <img id={'passwordChecked'} src={checked} alt="Valid" />
              ) : (
                <img id={'passwordUnChecked'} src={unchecked} alt="Invalid" />
              )}
            </ValidationItem>

            <ValidationItem id={'character'}>
              <ValidationSpan id={'specialCharacter'}>
                At least 1 special character e.g -).#@
              </ValidationSpan>
              {/[!@#$%^&*]/.test(password) ? (
                <img id={'lastChecked'} src={checked} alt="Valid" />
              ) : (
                <img id={'lastUnchecked'} src={unchecked} alt="Invalid" />
              )}
            </ValidationItem>
          </ValidationContainer>
        }
      >
        <div id={'finalDiv'}></div>
      </Popover>
    </div>
  );
};

export default PasswordValidator;
