import React, {useState} from 'react';
import {FaMinus, FaPlus} from 'react-icons/fa6';

const FAQ = () => {
    const [faqItems, setFaqItems] = useState([
        {
            question: 'What does Learnspace really do?',
            answer:
                `We are an education financing company that provides loans to students attending pre-approved institutions. 
                We do this by matching students’ loan needs with investors’ funds and offering them the best rates for acquiring high-in-demand skills.`,
            isOpen: false,
        },
        {
            question: 'How long does the loan application processing take?',
            answer:
                'The processing time for a loan application can vary, but we strive to make it as efficient as possible. Typically, it takes a few business days to process and finalize your application.',
            isOpen: false,
        },
        {
            question: 'How many times can I apply for a loan on Learnspace?',
            answer:
                'You can apply for a loan on Learnspace as many times as needed. However, each loan application is considered independently, and approval is based on various factors, including your financial profile and creditworthiness.',
            isOpen: false,
        },
        {
            question: 'What are the eligibility criteria for a Learnspace loan?',
            answer:
                "To be eligible for a Learnspace loan, you typically need to be a student attending a pre-approved institution. The specific eligibility criteria may vary, so it's important to check the requirements outlined during the application process.",
            isOpen: false,
        },
        {
            question: 'How are interest rates determined for Learnspace loans?',
            answer:
                'Interest rates for Learnspace loans are determined based on various factors, including your creditworthiness and the terms of the loan. Our goal is to offer competitive rates to help students pursue their education and acquire valuable skills.',
            isOpen: false,
        },
        {
            question: 'What happens if I miss a loan payment?',
            answer:
                "If you miss a loan payment, it's important to contact Learnspace as soon as possible. Late or missed payments may incur fees, and it can also impact your credit score. We are here to work with you and find solutions to help you manage your repayment.",
            isOpen: false,
        },
        {
            question: 'Can I apply for a loan if I have bad credit?',
            answer:
                'Learnspace considers various factors when reviewing loan applications, and having bad credit may affect your eligibility. However, we encourage you to apply, as each application is assessed individually. We aim to provide opportunities for all students to access education financing.',
            isOpen: false,
        },
        {
            question: 'How does the repayment process work?',
            answer:
                "The repayment process involves making regular payments according to the terms of your loan agreement. Learnspace will provide details about the repayment schedule, including the amount and frequency of payments. It's important to review and understand the terms before accepting a loan.",
            isOpen: false,
        },
    ]);

    const toggleAccordion = (index: number) => {
        setFaqItems((prevItems) =>
            prevItems.map((item, i) => ({
                ...item,
                isOpen: i === index ? !item.isOpen : false,
            }))
        );
    };

    return (
        <div id={`vdoiekjsdm`} className="">
            {faqItems.map((item, index) => (
                <div id={`cnljdkjfdoeo`} key={index} className={`px-4 py-6 ${index !== faqItems.length - 1 ? 'border-b' : ''}`}>
                    <div
                        id={`cojcnejniredds`}
                        className={`flex justify-between items-center text-[15px]`}
                        onClick={() => toggleAccordion(index)}
                    >
                        <p id={`cdsjknlowaeo`}>{item.question}</p>
                        {item.isOpen ? <FaMinus className="cursor-pointer"/> : <FaPlus className="cursor-pointer"/>}
                    </div>
                    {item.isOpen && (
                        <p id={`cjhofwejksd`} className={`w-full mt-2 text-[14px] text-[#404653]`}>{item.answer}</p>
                    )}
                </div>
            ))}
        </div>
    );
};

export default FAQ;
