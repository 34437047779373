import React, {Dispatch, SetStateAction, useState} from 'react';
import styles from "./styles/TopBar.module.css";
import dummyProfileImage from "../../../features/dashboard/assets/images/dummyProfileImage.svg";
import {GiHamburgerMenu} from "react-icons/gi";
import {useAppSelector} from "../../../redux/hooks/hooks";
import {IoIosArrowDown} from "react-icons/io";
import {FiBell} from "react-icons/fi";
import TopNavButton from "./TopNavButton";
import learnSpaceLogo from "../../../features/dashboard/assets/images/learnspaceLogo.svg";
import learnSpaceLetters from "../../../features/dashboard/assets/images/learnspaceLetters.svg";
import {Link} from "react-router-dom";
import utils from "../../../utils/utils";
import ProfileDropdown from "./ProfileDropdown";
import Notifications from "./Notifications";
import SideNavButton from "./SideNavButton";
import Logout from "../../../features/userManagement/auth/logout/Logout";

interface IProps {
    setMobileOpen?: any;
    setSelectedName?: Dispatch<SetStateAction<string | null>>;
}

function TopBar({ setMobileOpen, setSelectedName }: IProps) {
    const { user } = useAppSelector((state) => state.auth);


    const fullName = user?.name;
    const patten = /^[A-Z_]+$/
    //@ts-ignore
    const filteredRoles = user?.realm_access?.roles?.filter(v => patten.test(v))

    const userRole = filteredRoles?.length ? filteredRoles[0] : undefined;


    const [arrowToggled, setArrowToggled] = useState(false);
    const [bellClicked, setBellClicked] = useState(false);
    const [hamburger, setHamburger] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const toggleArrow = () => {
        setArrowToggled(!arrowToggled);
    };

    // famaxo7454@aersm.com

    const clickBell = () => {
        setBellClicked(!bellClicked);
    };

    const clickHamburger = () => {
        setHamburger(!hamburger);
    };

    const handleLogout = () => {
        setOpenModal(true);
        setArrowToggled(false);
    };

    return (
        <>
            <div id={"topNavigation"} className={styles.topNavigation}>
                <Link id={'fund'} to="">
                    <div id={'imagesDiv'} className="flex items-center">
                        <img id={'learnSpaceLogo'} src={learnSpaceLogo} className="mr-2" alt="learn-space-logo" />
                        <img id={'learnSpaceLetters'} src={learnSpaceLetters} className={styles.learnSpaceLetters} alt="learn-space-logo" />
                    </div>
                </Link>
                <div id={'topNavButton'} className={`absolute left-1/2 -translate-x-1/2 ${styles.topNavButton}`}>
                    <TopNavButton  role={userRole} setSelectedName={setSelectedName} />
                </div>
                <div id={'rightTopNav'} className={styles.rightTopNav}>
                    <div id={'bellDiv'}>
                        <button className={styles.bellDiv}
                                // onClick={clickBell}
                        >
                            <FiBell id={'FiBell'} className="text-[30px] " />
                        </button>
                        {bellClicked && <Notifications  close={clickBell} />}
                    </div>

                    <img id={'dummyProfileImage'} src={dummyProfileImage} className={styles.profileImage} alt="profileImage" />
                    <div id={'buttonsDiv'} className={`relative`}>
                        <button id={'clickHamburger'} onClick={clickHamburger} className={styles.hamburger}>
                            <GiHamburgerMenu id={'clickHamburgerGiHamburgerMenu'} />
                        </button>
                        {hamburger && <div id={'mobileTopNav'} className={styles.mobileTopNav}><SideNavButton /></div>}
                    </div>

                    <div id={'rightTopNavText'} className={styles.rightTopNavText}>
                        <div id={'fullNameDiv'} className="flex justify-between items-center w-[150px]">
                            <p id={'fullNamePTag'} className={styles.fullName}>{fullName}</p>
                            <div id={'profileArrow'} className={styles.profileArrow}>
                                <div id={'toggleArrowDiv'}>
                                    <button id={'toggleArrowButton'} onClick={toggleArrow}>
                                        <IoIosArrowDown id={'IoIosArrowDown'} />
                                    </button>
                                    {arrowToggled && <ProfileDropdown onLogoutClick={handleLogout} close={toggleArrow} />}
                                </div>
                            </div>
                        </div>
                        <p id={'formatRoleUserRole'}>{utils.formatRole(userRole)}</p>
                    </div>
                </div>
            </div>
            <div className={`h-[100px] relative ${styles.space}`} />
            <Logout showOpenModal={openModal} setShowOpenModal={setOpenModal} />
        </>
    );
}

export default TopBar;
