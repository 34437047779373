import React, {lazy, Suspense} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import InstituteSideBar from "../features/dashboard/institute/instituteSideBar/index";
import ViewProgram from "../features/dashboard/programs/ViewProgram";
import Investor from "../features/dashboard/investor/investorDashboard";
import InvestorBiodata from "../features/dashboard/investor/investorBiodata/BioLayout";
import InvestorInvestment from "../features/dashboard/investor/investorInvestment/Index";
import InvestorBankDetails from "../features/dashboard/investor/investorBankDetails";
import ViewTrainingInstitute from "../features/dashboard/institute/ViewTrainingInstitute";
import CohortsInProgram from "../features/dashboard/cohort/cohortsInProgram";
const SelectedLoan = lazy(()=> import("../features/dashboard/loan/selectLoan/index"))
const CohortTrainees = lazy(()=> import("../features/dashboard/trainee/cohortTrainees"))
const ViewAllLoanLayout = lazy(()=> import("../features/dashboard/loan/loanRequest/viewAllLoanRequest"))
const MidSection = lazy(()=> import("../features/dashboard/institute/ViewAllTrainingInstitute/midSection"))
const FundProductDashboard = lazy(() => import("../features/dashboard/fundProduct/fundProductDashboard"))
const LoanRequest = lazy(() => import("../features/dashboard/trainee/loanRequest"))
const TraineeDetails = lazy(() => import("../features/dashboard/trainee/traineeDetails/TraineeDetails"))
const ChangePassword = lazy(() => import("../features/dashboard/trainee/loanRequest"))
const InviteColleague = lazy(() => import("../features/dashboard/portfolioManager/inviteColleague/index"))
const CohortDetail = lazy(() => import("../features/dashboard/cohort/cohort/CohortDetail"));

function ManagerRoutes() {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route index element={<Navigate to="/fund" replace/>}/>
                    <Route path="/fund" element={<FundProductDashboard/>}/>
                    <Route path="/trainees" element={<LoanRequest/>}/>
                    <Route path="/trainee-details" element={<TraineeDetails/>}/>
                    <Route path="/change-password" element={<ChangePassword/>}/>
                    <Route path="/invite-colleague" element={<InviteColleague/>}/>
                    <Route path="/view-all-loan" element={<ViewAllLoanLayout />} />
                    <Route path="/institute" element={<MidSection />} />
                    <Route path="/loan" element={<SelectedLoan />} />
                    <Route path="institute/view/cohort" element={<CohortDetail />} />
                    <Route path={`/cohort/cohort-trainees`} element={<CohortTrainees/>}/>
                    <Route path="institute/view" element={<InstituteSideBar />}/>
                    <Route path="/view" element={<ViewTrainingInstitute/>}/>
                    <Route path="/program/view" element={<ViewProgram />} />
                    <Route path="/investor" element={<Investor />} />
                    <Route path={`/investor/biodata`} element={<InvestorBiodata/>}/>
                    <Route path={`/investor/investments`} element={<InvestorInvestment/>}/>
                    <Route path={`/investor/bank-details`} element={<InvestorBankDetails/>}/>
                    <Route path={`/program/view/cohorts`} element={<CohortsInProgram/>}/>
                </Routes>
            </Suspense>
        </>
    );
}

export default ManagerRoutes;
