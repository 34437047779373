import React, {Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import TraineeFlowLayout from "../features/dashboard/traineeFlow/main/TraineeFlowLayout";
import LoanApplicationDetails from "../features/dashboard/traineeFlow/loanApplicationDetails";
import AdditionalInformation from "../features/dashboard/traineeFlow/additionalInformation/AdditionalInformation";
import IdentityVerification from "../features/dashboard/traineeFlow/identityVerification/IdentityVerification";
import ConfirmApplicationDetails
    from "../features/dashboard/traineeFlow/confirmApplicationDetails/ConfirmApplicationDetails";
import TraineeDashboard from "../features/dashboard/traineeFlow/dashboard";
import {TraineeStatus} from "../types/trainee/traineeFlow.type"
import {getUserAssociatedData} from "../utils";
import ConfirmIdentity from "../features/dashboard/traineeFlow/confirmIdentity/ConfirmIdentity";
import LoanOfferDetails from "../features/dashboard/trainee/loanOfferDetails/LoanOfferDetails";


function TraineeRoutes() {
    const traineeStatus = getUserAssociatedData()?.trainee?.traineeStatus;
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    {getRoute(traineeStatus)}
                </Routes>
            </Suspense>
        </>
    )
}

function getRoute(traineeStatus: any) {
    switch (traineeStatus) {
        case TraineeStatus.IN_REVIEW:
        case TraineeStatus.APPLIED:
            return (
                <>
                    <Route id={'euwiud'} index element={<Navigate to="/trainee-dashboard" replace/>}/>
                    <Route id={'woierd'} path="trainee-dashboard" element={<TraineeDashboard/>}/>
                    <Route id={'ouefdhj'} path={`/loan-offer-details`} element={<LoanOfferDetails/>}/>
                </>
            );
        case TraineeStatus.REFERRED:
        case TraineeStatus.ACCEPTED:
            return (
                <>
                    <Route index element={<Navigate to="/loan-application" replace/>}/>
                    <Route path="loan-application" element={<TraineeFlowLayout/>}>
                        <Route path="" element={<LoanApplicationDetails/>}/>
                        <Route path="id-verification" element={<IdentityVerification/>}/>
                        <Route path="confirm-id" element={<ConfirmIdentity/>}/>
                        <Route path="additional-info" element={<AdditionalInformation/>}/>
                        <Route path="confirm-details" element={<ConfirmApplicationDetails/>}/>
                    </Route>
                    <Route path="trainee-dashboard" element={<TraineeDashboard/>}/>
                </>
            );
        default:
            return (
                <>
                    <Route index element={<Navigate to="/wrong-route" replace/>}/>
                    <Route path="wrong-route" element={<DefaultRoute/>}/>
                </>
            );
    }
}

function DefaultRoute() {
    return (
        <div className={`flex flex-col justify-center items-center h-screen`}>
            <p className={`text-[5rem]`}>☹</p>
            <i className={`text-center`}>
                <p>...oops! You're not supposed to be here.</p>
                <p>Please contact your institution admin about this.</p>
            </i>
        </div>
    );
}

export default TraineeRoutes;