import React from "react";
import Layout from "../../../../components/reusables/layout/Layout";
import ViewProgramIndex from "./ViewProgramIndex";
import styles from "../../cohort/styles/Dashboard.module.css";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../../../redux/hooks/hooks";
import BreadCrumbs from "../../../../components/breadCrumb/BreadCrumbs";

const ViewProgram: React.FC = () => {
    const programId = useAppSelector(state => state.program.programId);
    useNavigate();
    return (
        <div id={'MainView'}>
            <Layout page="View Program">
                <div id={'breadCrumbsDivQ'} className={`py-5 px-[40px]`}>
                    <BreadCrumbs/>
                </div>
                <div id={'programIdViewProgramIndex'} className={`flex flex-col  ${styles.wrapper}`}>
                    <ViewProgramIndex programId={programId}/>
                </div>
            </Layout>
        </div>
    )


}
export default ViewProgram;