import React, {ReactNode} from "react";
import {FormDiv, MobileLearnSpaceLogo} from "./Form.style";
import logo from "../background/assets/learnspace-logo.svg";

interface IProps {
  children: ReactNode;
}

function PageForm({ children }: IProps) {
  return (
    <FormDiv id={'formDIv'}>
      <MobileLearnSpaceLogo id={'MobileLearnSpaceLogo'}>
        <img id={'learnSpace'} src={logo} alt="learnspace logo" />
      </MobileLearnSpaceLogo>
      {children}
    </FormDiv>
  );
}

export default PageForm;
