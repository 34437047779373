import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    AdditionalInfo,
    BvnVerificationResponse,
    ILoanApplicationDetails,
    NINVerificationResponse
} from "../../types/trainee/traineeFlow.type";
import {notes} from "../../utils/roles";
import {PURGE} from "redux-persist";

interface ITrainee{
    trainee: any;
    traineeId: string;
    loanApplicationDetails: ILoanApplicationDetails | undefined;
    personalInformation: any;
    additionalInformation: AdditionalInfo | undefined;
    ninResponse: NINVerificationResponse | undefined;
    bvnResponse: BvnVerificationResponse | undefined;
    isTraineeAdded?: boolean;
    note: string;
    traineeDetails?:any;
}

const initialState: ITrainee = {
    trainee: "",
    traineeId: "",
    loanApplicationDetails: {},
    personalInformation: undefined,
    additionalInformation: {
        alternateEmailAddress: "",
        alternatePhoneNumber: "",
        alternateContactAddress: "",
        nextOfKinFirstName: "",
        nextOfKinLastName: "",
        nextOfKinEmailAddress: "",
        nextOfKinPhoneNumber: "",
        nextOfKinAddress: "",
        nextOfKinRelationship: "",
    },
    ninResponse: undefined,
    bvnResponse: undefined,
    isTraineeAdded: false,
    note: notes.TWO_TRIALS,
};


const traineeSlice = createSlice({
    name: "trainee",
    initialState,
    reducers: {
        setTrainee:(state, action:PayloadAction<any>) =>{
          state.trainee = action.payload;
        },
        setTraineeId:(state, action:PayloadAction<string>) => {
            state.traineeId = action.payload
        },
        setPersonalInfo:(state, action:PayloadAction<any>) => {
            state.personalInformation = action.payload;
        },
        setAdditionalInfo:(state, action:PayloadAction<any>)=>{
            state.additionalInformation = action.payload;
        },
        setNinResponse:(state, action:PayloadAction<NINVerificationResponse | undefined>)=>{
            state.ninResponse = action.payload;
        },
        setBvnResponse:(state, action:PayloadAction<BvnVerificationResponse | undefined>)=>{
            state.bvnResponse = action.payload;
        },
        setTraineeAdded:(state, action:PayloadAction<boolean>) =>{
            state.isTraineeAdded = action.payload;
        },
        setNote:(state, action:PayloadAction<string>)=>{
            state.note = action.payload;
        },
        setTraineeDetails:(state, action:PayloadAction<any>) =>{
            state.traineeDetails = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.trainee = "";
            state.traineeId = "";
            state.personalInformation = undefined;
            state.additionalInformation = undefined;
            state.ninResponse = undefined;
            state.bvnResponse = undefined;
            state.isTraineeAdded = false;
            state.note = "";
            state.traineeDetails = undefined;
        });
    },
})

export const traineeActions = traineeSlice.actions;
export default traineeSlice.reducer;