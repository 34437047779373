import React, {useState} from "react";
import styles from "../styles/EditCohortModal.module.css";
import {GrClose} from "react-icons/gr";
import {ErrorMessage, Field, FieldProps, Form, Formik, FormikValues} from "formik";
import {Icon} from "@iconify/react";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import {format} from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import cohortService from "../../../../services/cohort.service";
import {getInstituteId} from "../../../../utils";
import Modal from "../../../../components/Modal";
import {useAppSelector} from "../../../../redux/hooks/hooks";
import utils from "../../../../utils/utils";
import {Toast, ToastTypes} from "../../../../components/Toast";


interface IProps {
    open: boolean;
    close?: () => void;
    cohortId?: string | undefined;
}

// interface DropdownState {
//     program: boolean;
// }

const validationSchema = Yup.object().shape({
    cohortName: Yup.string().nullable(),
    startDate: Yup.date().nullable().transform((value, originalValue) => {
        return originalValue === "N/A" ? null : value;
    }),
    endDate: Yup.date()
        .nullable()
        .test({
            name: "date-validation",
            message: "End Date must be after Start Date",
            test: function (endDate) {
                const startDate = this.resolve(Yup.ref("startDate"));
                return !startDate || !endDate || startDate <= endDate;
            },
        }).transform((value, originalValue) => {
            return originalValue === "N/A" ? null : value;
        }),
    tuition: Yup.string()
        .matches(/^\d{1,3}(,\d{3})*(\.\d+)?$/, "Must be a positive number")
        .test("non-zero", "Must be a positive number", value => {
            if (value && value.trim() !== '') {
                return parseFloat(value.replace(/,/g, '')) > 0;
            }
            return true;
        })
        .nullable(),
    program: Yup.string().nullable(),
});

function EditCohortModal({open, close}: IProps) {
    const {
        cohortId,
        programId,
        cohortName,
        programName,
        cohortStartDate,
        cohortEndDate,
        tuitionAmount
    } = useAppSelector(state => state.cohort.cohortDetails);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);


    const instituteId = getInstituteId();

    const payloadData = {instituteId, programId, cohortId}

    const [setCohortName, setSetCohortName] = useState<string>(cohortName);
    const [setProgramName, setSetProgramName] = useState<string>(programName);
    const [tuitionAmountState, setTuitionAmountState] = useState<string>(tuitionAmount);

    const handleSubmit = async (formValues: FormikValues): Promise<void> => {
        formValues.tuitionAmount = tuitionAmountState + ""
        const tuitValue = formValues.tuitionAmount;
        formValues.tuitionAmount = formValues.tuitionAmount.replace(/[^0-9.]/g, '').replace(/\.(?=.*\.)/g, '');

        const values = {...payloadData, ...formValues, cohortName: setCohortName};
        setIsLoading(true);
        try {
            const response = await cohortService.editCohort(values);
            formValues.tuitionAmount = tuitValue;
            if (response) {
                Toast({content: response, type: ToastTypes.SUCCESS});
                close?.();
            }
        } catch (err) {
            utils.handleRequestError(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal isOpen={open}>
            <div id={"editCohortContainer"} className={styles.editCohortContainer}>
                <div id={'closeIconDiv'} className={styles.closeIcon} onClick={close}>
                    <GrClose id={'GrClose'}/>
                </div>
                <div id={'headings'} className={styles.headings}>
                    <h2 id={'Details'}>Edit Details</h2>
                </div>
                <Formik
                    initialValues={{
                        cohortName: '',
                        startDate: '',
                        endDate: '',
                        tuitionAmount: 0.00,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({errors, touched, setFieldValue}) => (
                        <div id={'formOuterDiv'} className="flex justify-center">
                            <Form>
                                <div id={'formDivMainContainer'} className={styles.formSection}>
                                    <div id={'formOne'} className={styles.one}>
                                        <div id={'formField'} className={styles.field}>
                                            <label id={'cohortLabel'}>Cohort Name</label>
                                            <Field
                                                type="text"
                                                name="cohortName"
                                                setFieldValue={setFieldValue}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    const cohortName = event.target.value
                                                    setSetCohortName(cohortName)
                                                    setFieldValue("cohortName", cohortName);
                                                }}
                                                value={setCohortName}
                                                style={{
                                                    borderColor:
                                                        errors.cohortName && touched.cohortName ? "red" : "inherit",
                                                }}
                                            />
                                            {errors.cohortName && touched.cohortName && (
                                                <div id={"errorCohortName"}
                                                     className={styles.error}>{errors.cohortName}</div>
                                            )}
                                        </div>

                                        <div id={'StartDiv'} className={styles.field}>
                                            <label id={'StartLabel'}>Start Date</label>
                                            <Field name="startDate">
                                                {({field}: FieldProps) => (
                                                    <DatePicker id={'datePicker'}
                                                                selected={startDate}
                                                                onChange={(date) => {
                                                                    setStartDate(date);
                                                                    const formattedDate = date
                                                                        ? format(date, "yyyy-MM-dd")
                                                                        : cohortStartDate;
                                                                    setFieldValue("startDate", formattedDate);
                                                                }}
                                                                dateFormat="yyyy-MM-dd"
                                                                className={errors.startDate && touched.startDate ? "red-border" : ""}
                                                    />
                                                )}
                                            </Field>
                                            {errors.startDate && touched.startDate && (
                                                <div id={'startDateError'}
                                                     className={styles.error}>{errors.startDate}</div>
                                            )}
                                        </div>

                                        <div id={'TuitionDiv'} className={styles.field}>
                                            <label id={'TuitionLabel'}>Tuition Amount</label>
                                            <div id={'TuitionFieldDiv'} style={{position: 'relative'}}>
                                                {/* <InputAdornment position="start" style={{ position: 'absolute', left: 8, top: '50%', transform: 'translateY(-50%)' }}>₦</InputAdornment> */}
                                                <Field
                                                    type="text"
                                                    name="tuition"
                                                    // placeholder="Enter Amount"
                                                    // onChange={(e: any) => {
                                                    //   const formattedValue = utils.formatValueWithComma(e)
                                                    // const displayValue = formattedValue? formattedValue:tuition;
                                                    //     setFieldValue("tuition", displayValue).then(r => {return displayValue});
                                                    // }}
                                                    setFieldValue={setFieldValue}
                                                    onChange={(e: any) => {
                                                        const formattedValue = utils.formatValueWithComma(e)
                                                        setFieldValue("tuition", formattedValue);
                                                        setTuitionAmountState(formattedValue)
                                                    }}
                                                    value={tuitionAmountState}
                                                    editable="false"
                                                    style={{ borderColor: errors.tuitionAmount && touched.tuitionAmount ? "red" : "inherit" }}
                                                />
                                                <ErrorMessage name="tuition" component="div" className={``}/>
                                            </div>
                                            {errors.tuitionAmount && touched.tuitionAmount && (
                                                <div id={'tuitionErrorDiv'} className={styles.error}>
                                                    {errors.tuitionAmount}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div id={'ProgramOneDiv'} className={styles.one}>
                                        <div id={'ProgramFieldDiv'} className={styles.field}>
                                            <label id={'programLabelTag'}>Program Name</label>
                                            <div id={'programNameFieldDiv'}>
                                                <Field
                                                    name="programName"
                                                    id=""
                                                    className=" p-2 border rounded-md w-[300px]"
                                                    value={setProgramName}
                                                    editable="false"
                                                    readOnly
                                                    onChange={(e: any) => {
                                                        const programName = e.target.value
                                                        setSetProgramName(programName)
                                                        setFieldValue("programName", programName);
                                                    }}
                                                />
                                            </div>

                                        </div>

                                        <div id={'endDateDiv'} className={styles.field}>
                                            <label id={'endDateLabel'}>End Date</label>
                                            <Field name="endDate">
                                                {({field}: FieldProps) => (
                                                    <DatePicker id={'endDateDatePicker'}
                                                                selected={endDate}
                                                                minDate={new Date()}
                                                                onChange={(date) => {
                                                                    setEndDate(date);
                                                                    const formattedDate = date
                                                                        ? format(date, "yyyy-MM-dd")
                                                                        : cohortEndDate;
                                                                    setFieldValue("endDate", formattedDate);
                                                                }}
                                                                dateFormat="yyyy-MM-dd"
                                                        //placeholderText={`Select a date`}
                                                                className={
                                                                    errors.endDate && touched.endDate
                                                                        ? "red-border"
                                                                        : ""
                                                                }
                                                    />
                                                )}
                                            </Field>
                                            {errors.endDate && touched.endDate && (
                                                <div id={'errorEndDate'} className={styles.error}>{errors.endDate}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div id={'buttonsDiv'} className={styles.buttons}>
                                    <button id={'buttonCancelCloseButton'} className={styles.cancelBtn} onClick={close}>
                                        Cancel
                                    </button>
                                    <button id={'saveBtnSubmit'} className={styles.saveBtn} type="submit">
                                        {isLoading ? (
                                            <div id={'IconDiv'} className="flex items-center justify-center">
                                                <Icon id={'Icon'} icon={loadingLoop} width={24} height={24}/>
                                            </div>
                                        ) : (
                                            "Save"
                                        )}
                                    </button>
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </div>
        </Modal>
    );
}


export default EditCohortModal;