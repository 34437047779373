import React from 'react';
import Modal from "../../../../components/Modal";
import Lottie from "lottie-react";
import check from "../../../../assets/image/lottie-check.json";
import {useNavigate} from "react-router-dom";

interface IProps {
    open: boolean;
    close?: () => void;
}

function Success({open, close}: IProps) {
    const navigate = useNavigate();

    return (
        <Modal isOpen={open}>
            <div id={`jhslkdhlkjsd`} className="p-6 max-w-[390px] mx-auto">
                <div id={`sdfjjviod`} className="flex justify-between items-center">
                    <div id={`svkjjdokvd`} className="w-[60px] h-[60px]">
                        <Lottie
                            width={60}
                            height={60}
                            animationData={check}
                            loop
                        />
                    </div>
                    <div/>
                </div>
                <div id={`cnsijnijnvd`} className="flex flex-col gap-[12px] my-5">
                    <p id={`shinsdikj`} className="font-bold text-[18px]">
                        Congratulations! Your loan has been successfully submitted
                    </p>
                    <p id={`cjdsklakjndnal`} className="font-[400] text-[14px] text-[#72757A]">
                        Head to your dashboard for real-time updates, personalized insights, and next steps on your journey to financial empowerment.
                    </p>
                </div>
                <button id={`cmnsdinjdk`}
                    className="text-[14px] text-white bg-[#0D9B48] py-3 w-full border rounded-[4px]"
                    onClick={() => navigate("/trainee-dashboard")}
                >
                    Go to Dashboard
                </button>
            </div>
        </Modal>
    );
}

export default Success;
