import React, { useEffect, useState } from "react";
import styles from "./ProgramTable.module.css";
import { IColumnProps, TableData } from "../../../../types/commons.type";
import LearnSpaceTable from "../../../../components/reusables/table/Table";
import noProgram from "../../assets/images/empty_state_institute.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks/hooks";
import EditProgramModal from "../editProgram/index";
import { programAction } from "../../../../redux/slices/programSlice";
import { IProgramData } from "../../../../types/program.type";
import { IAllInstitute } from "../../../../types/institute.type";
import { useNavigate } from "react-router-dom";
import { cohortActions } from "../../../../redux/slices/cohortSlice";

interface IProps {
    programData?: any;
}

const ProgramDataTable = (props: IProps) => {
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const navigate = useNavigate();
    const getSearchString = useAppSelector((state) => state.search.searchString);
    const instituteId = useAppSelector((state) => state.institute.instituteId);
    const institute: IAllInstitute = { instituteId };
    const gottenProgramId = useAppSelector((state) => state.program.programId);
    const dispatch = useAppDispatch();

    useEffect(() => {
        document.addEventListener("click", (event) => {
            setExpandedRow(null);
        });
    }, []);

    function handleDelete() {}

    function handleViewProgramDetails(row: { programId: string; programName: string }) {
        dispatch(programAction.setProgramId(row.programId));
        dispatch(cohortActions.setProgramId(row.programId));
        dispatch(programAction.setProgramName(row.programName));
        const path = `/program/view`;
        navigate(path);
    }

    const toggleRowExpansion = (rowId: string) => {
        if (expandedRow === rowId) {
            setExpandedRow(null);
        } else {
            setExpandedRow(rowId);
        }
    };



    const columns: IColumnProps[] = [
        {
            title: "Program Name",
            selector: "programName",
            sortable: true,
        },
        {
            title:"Program Duration",
            selector: "programDuration",
            sortable: true,
        },
        {
            title:"Number of Cohorts",
            selector: "numberOfCohort",
            sortable: true,
        },
        {
            title:"Number of Trainee",
            selector: "numberOfTrainees",
            sortable: true,
        },
        {
            title:"Total Amount Disbursed",
            selector: "amountDisbursed",
            sortable: true,
        },
        {
            title: "Total Amount Repaid",
            selector: "amountRepaid",
            sortable: true,
        },
        {
            title: "Total Amount Outstanding",
            selector: "outstandingAmount",
            sortable: true,
        },
        {
            title: "Program Status",
            selector: "programStatus",
            filterable: true,
            sortable: true,
        },
        {
            title: "More",
            selector: "more",
            sortable: false,
            cell: (row: IProgramData) => (
                <div id={"rowsOuter"} className={styles.rows}>
                    <button
                        id={"stopPropagation"}
                        className={styles.rows}
                        onClick={(event) => {
                            event.stopPropagation();
                            toggleRowExpansion(row.programId!);
                        }}
                    >
                        ⋮
                    </button>

                    {expandedRow === row.programId && (
                        <div
                            id="expandedAbsolute"
                            className={"absolute bg-white rounded-md shadow-lg mt-1 w-[100px] transform -translate-x-1/2 left-1/2 z-10"}
                        >
                            <button
                                id={"btnHandleDelete"}
                                className="block px-4 py-2 text-sm text-gray-800 hover:text-red-600 hover:font-bold"
                                onClick={handleDelete}
                            >
                                Delete Program
                            </button>
                        </div>
                    )}
                </div>
            ),
        },
    ];


    return (
        <>
            <>
                <LearnSpaceTable
                    columns={columns}
                    searchString={getSearchString}
                    searchColumn="programName"
                    tableData={props.programData}
                    emptyTable={{
                        image: noProgram,
                        title: "No Record",
                        subText: "Create Program to Appear here",
                        text: "",
                    }}
                    path=""
                    handleRowClick={(row: any) => {
                        handleViewProgramDetails(row);
                    }}
                />

                <EditProgramModal
                    openModal={isOpenEdit}
                    onClose={() => {
                        setIsOpenEdit(false);
                    }}
                    programId={gottenProgramId}
                    instituteId={institute}
                    buttonText="Save"
                />
            </>
        </>
    );
};

export default ProgramDataTable;
