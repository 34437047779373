import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10%;
  justify-content: center;
  background-color: #EAEAEA;
  height: 100vh;
  width: 100%;

  @media (max-width: 769px) {
    display: initial;
    background-color: purple;
  }
`;

export const AuthBgLogoLeft = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  img {
    width: 10rem;
  }

  @media (max-width: 769px) {
    display: none;
  }
`;

export const AuthBgLogoRight = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  img {
    width: 14rem;
  }

  @media (max-width: 769px) {
    display: none;
  }
`;

export const AuthBgFooter = styled.footer`
  position: absolute;
  bottom: 0;
  margin-top: 10%;
  font-size: 0.75rem;
  left: 0;
  right: 0;

  p {
    font-family: "IBM Plex Sans", serif;
    color: #333333;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  @media (max-width: 320px) {
    display: none;
  }
`;

export const AuthBgFormContainer = styled.div`
  justify-content: center;
  align-items: center;
  width: 30vw;
  margin: auto;

    @media (max-width: 789px) {
      width: 90vw;
    }
  
`;

export const AuthBgLearnSpaceLogo = styled.div`
  padding: 1rem;
  height: 5rem;
  width: 100%;
  margin: 2rem auto;
  max-width: 80%;
  align-items: center;

  @media (max-width: 769px) {
    display: none;
  }
`;
