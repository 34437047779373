import React, {useState} from "react";
import * as Yup from "yup";
import Modal from "../../../../components/Modal";
import styles from "./styles/CreateLoanProduct.module.css";
import {GrClose} from "react-icons/gr";
import {Field, Form, Formik} from "formik";
import {ILoanProductDetails, ILoanProductRequest} from "../../../../types/loan/loanProduct.type";
import {Icon} from "@iconify/react";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import loanProductService from "../../../../services/loanProduct.service";
import {toast} from "react-toastify";
import utils from "../../../../utils/utils";
import {nameRegExp, nameValidationMessage} from "../../../../utils";

function convertToDays(value: number, unit: any) {
    switch (unit) {
        case 'Years':
            return value * 365; // let's check this guy, if this is how we'll leave it.
        case 'Months':
            return value * 30; // And this guy as well.
        case 'Days':
            return value;
        default:
            return 0;
    }
}

function CreateLoanProduct(props: ILoanProductDetails) {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState('NGN');
    const currencyOptions = ['NGN', 'USD', 'GBP'];
    const [selectedTenor, setSelectedTenor] = useState('');
    const tenorOptions = ['Years', 'Months', 'Days'];
    const [selectedMoratorium, setMoratorium] = useState('');


    const validationSchema = Yup.object().shape({
            loanProductName: Yup.string().required("Loan Product Name is required").matches(nameRegExp, nameValidationMessage),
            loanProductSize1: Yup.string()
                .transform((originalValue) =>
                    originalValue?.replace(/,/g, '')?.trim())
                .test(
                    'is-positive-number',
                    'Loan product size must be a positive number',
                    (value) =>
                        typeof value === 'string' &&
                        !isNaN(parseFloat(value)) &&
                        parseFloat(value) > 0 &&
                        !value.startsWith('0'))
                .required("Loan Product Size is required"),
            obligorLoanLimit1: Yup.string()
                .transform((originalValue) =>
                    originalValue?.replace(/,/g, '')?.trim())
                .test(
                    'is-positive-number',
                    'Loan product size must be a positive number',
                    (value) =>
                        typeof value === 'string' &&
                        !isNaN(parseFloat(value)) &&
                        parseFloat(value) > 0 &&
                        !value.startsWith('0'))
                .required("Obligor loan limit is required")
                .test(
                    "obligorLoanLimit-vs-loanProductSize",
                    "Obligor Loan Limit should be less than Loan Product Size",
                    function (value) {
                        const loanProductSizeValue = parseFloat(this.parent.loanProductSize1);
                        const obligorLoanLimitValue = parseFloat(value);
                        return (
                            !isNaN(loanProductSizeValue) &&
                            !isNaN(obligorLoanLimitValue) &&
                            loanProductSizeValue >= obligorLoanLimitValue
                        );
                    }
                )
                .min(0, "Obligor Loan Limit must not be less than 0"),
            interestRate: Yup.number()
                .min(0, "Interest rate must not be less than 0")
                .positive("Interest rate must be greater than 0")
                .required("Interest Rate is required")
                .test(
                    "maxDigitsAfterDecimal",
                    "Interest can take just 2 digits after decimal or less",
                    (value) => {
                        if (value === null || value === undefined) return true;
                        const decimalPattern = /^\d*(\.\d{0,2})?$/;
                        return decimalPattern.test(value.toString());
                    }
                ),
            tenor: Yup.number()
                .min(0, "Tenor must not be less than 0")
                .positive("Tenor must be greater than 0")
                .transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value)
                .required("Tenor is required"),
            moratorium: Yup.number()
                .min(0, "moratorium  must not be less than 0")
                .transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value)
                // .positive("Moratorium must be a positive number")
                .positive("Moratorium must be greater than 0")
                .required("Moratorium is required")
                .test(
                    "tenor-vs-moratorium",
                    "Moratorium should be less than Tenor",
                    function (value) {
                        const tenorValue = convertToDays(this.parent.tenor, selectedTenor!);
                        const moratoriumValue = convertToDays(value, selectedMoratorium!);
                        // console.log("tenor", this.parent.tenor);
                        // console.log("moratorium", value);
                        // console.log("tenor status", selectedTenor!);
                        // console.log("moratorium status", selectedMoratorium!);
                        // console.log("Tenor in days:", tenorValue);
                        // console.log("Moratorium in days:", moratoriumValue);
                        return (
                            !isNaN(tenorValue) &&
                            !isNaN(moratoriumValue) &&
                            tenorValue >= moratoriumValue
                        );
                    }
                ),
            termsAndCondition: Yup.string()
                .required("Terms and condition must be set")
                .max(2500, "Maximum amount of characters cannot exceed 2,500")
        }
    )

    const handleModalClose = () => {
        props.closeModal();
        resetDropdownStates();
    };
    const handleCurrencyChange = (currency: string) => {
        setSelectedCurrency(currency);
    };

    const handleTenorChange = (tenor: string) => {
        setSelectedTenor(tenor);
    };
    const resetDropdownStates = () => {
        setSelectedCurrency('NGN');
        setSelectedTenor('');
        setMoratorium('');
    };
    const handleMoratoriumChange = (moratorium: string) => {
        setMoratorium(moratorium);
    };

    const handleSubmit = async (values: any) => {
        values.loanProductSize1 = values.loanProductSize1.replace(/,/g, '');
        values.loanProductSize = values.loanProductSize1;
        values.tenorStatus = selectedTenor;
        values.moratoriumStatus = selectedMoratorium;
        values.obligorLoanLimit1 = values.obligorLoanLimit1.replace(/,/g, '');
        values.obligorLoanLimit = values.obligorLoanLimit1;

        delete values.loanProductSize1;
        delete values.obligorLoanLimit1;
        const loanProductRequest: ILoanProductRequest = values;

        setIsLoading(true);
        try {
            const response = await loanProductService.createLoanProduct(loanProductRequest);
            if (response) {
                handleModalClose();
                const data = {};
                toast.success("Loan Product Created Successfully!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                try {
                    await loanProductService.viewLoanProduct(data)
                } catch (error) {
                    utils.handleRequestError(error)
                }
            }
        } catch (error) {
            utils.handleRequestError(error)
            toast.error("Error Creating Loan Product!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setIsLoading(false);
            handleModalClose();
        }

    };


    return (
        <Modal
            isOpen={props.openModal}>

            <div id={'wrapper'} className={styles.wrapper}>
                <div id={'stylesTop'} className={styles.top}>
                    <h1 id={'headingWriteUp'} className={styles.headingWriteUp}>Create Loan Product</h1>
                    <div id={'CreateProduct'} onClick={props.closeModal} className={`cursor-pointer`}>
                        <GrClose id={'GrClose'} onClick={handleModalClose}/>
                    </div>
                </div>

                <div id={'sdn'} className="">
                    <Formik
                        initialValues={{
                            loanProductName: "",
                            loanProductSize1: "",
                            obligorLoanLimit1: "",
                            interestRate: "",
                            tenor: "",
                            moratorium: "",
                            termsAndCondition: "",
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({values, errors, isValid, touched, setFieldValue}) => (
                            <Form>
                                <div id={'outerDiv'} className={styles.wrapper2}>
                                    <div id={'innerDiv'} className={styles.inner1}>
                                        <div id={'leftSide'} className={styles.leftSide}>
                                            <label id={'loanProduct'}>Loan Product Name</label>
                                            <Field
                                                type="text"
                                                name="loanProductName"
                                                placeholder="Enter Product Name"
                                                style={{

                                                    borderColor:
                                                        errors.loanProductName && touched.loanProductName
                                                            ? "red"
                                                            : "inherit",
                                                }}/>
                                            {errors.loanProductName && touched.loanProductName && (
                                                <div id={'loanProductNameError'}
                                                     className={styles.error}>{errors.loanProductName}</div>
                                            )}

                                        </div>
                                        <div id={'rightSideOuterDiv'} className={styles.rightSide}>
                                            <div id={'fieldDiv'} className={`${styles.field}`}>
                                                <label id={'productSize'}>Loan Product Size</label>
                                                <div
                                                    id={'currencyInputDiv'}
                                                    className={styles.currencyInput}
                                                    style={{
                                                        borderColor: errors.loanProductSize1 && touched.loanProductSize1 ? "red" : "inherit",
                                                    }}>
                                                    <select
                                                        id={'currencyDropdownSelect '}
                                                        className={""}
                                                        value={selectedCurrency}
                                                        onChange={(e) => handleCurrencyChange(e.target.value)}>
                                                        {currencyOptions.map((currency, index) => (
                                                            <option id={`${currency}option`} key={index}
                                                                    value={currency}>
                                                                {currency}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <Field
                                                        type={`text`}
                                                        name={`loanProductSize1`}
                                                        placeholder={`₦0.00`}
                                                        onChange={(e: any) => {
                                                            setFieldValue("loanProductSize1",
                                                                utils.formatValueWithComma(e));
                                                        }}
                                                    />
                                                </div>
                                                {errors.loanProductSize1 && touched.loanProductSize1 && (
                                                    <div id={"ckaskjjkll"} className={styles.error}>{errors.loanProductSize1}</div>
                                                )}
                                            </div>

                                        </div>
                                    </div>

                                    <div id={'tenorInput2DivMain'} className={styles.inner1}>
                                        <div id={'tenorDivInner'} className={styles.inner2}>
                                            <div id={'firstTenure'} className={styles.first}>
                                                <label id={'tenureLabel'}>Tenor</label>
                                                <div id={'tenorInput1DivTenure'} className={styles.tenorInput1}>
                                                    <div id={'tenureField'} className={styles.tenorInput2}>
                                                        <Field
                                                            type="number"
                                                            name="tenor"
                                                            placeholder="0"
                                                            min={0}
                                                            max={25}
                                                            disabled={!selectedTenor}
                                                        />
                                                    </div>

                                                    <div id={'divSelectTenure'} className={styles.tenorInput2}>
                                                        <select
                                                            id={'selectedTenureSelect'}
                                                            name={'tenor'}
                                                            className={""}
                                                            value={selectedTenor}
                                                            onChange={(e) => {
                                                                handleTenorChange(e.target.value)
                                                                setFieldValue('tenor', e.target.value)
                                                            }
                                                            }>
                                                            <option id={'cjdbsk.'} value={''} disabled selected>
                                                                Select
                                                            </option>
                                                            {tenorOptions.map((tenor) => (
                                                                <option id={`${tenor}option`} key={`${tenor}option`}
                                                                        value={tenor}>
                                                                    {tenor}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                {errors.tenor && touched.tenor && (
                                                    <div id={'tenorError'} className={styles.error}>{errors.tenor}</div>
                                                )}
                                            </div>


                                            <div id={'secondFirst'} className={styles.first2}>
                                                <label id={'MoratoriumLabel'}>Moratorium</label>
                                                <div id={'labelBottomDiv'} className={styles.tenorInput1}>
                                                    <div id={'ternorFieldDiv'} className={styles.tenorInput2}>
                                                        <Field
                                                            type="number"
                                                            name="moratorium"
                                                            placeholder="0"
                                                            min={0}
                                                            max={25}
                                                            disabled={!selectedMoratorium}
                                                        />
                                                    </div>


                                                    <div id={'currencyDropDownSelectDiv'}
                                                         className={styles.tenorInput2}>
                                                        <select
                                                            id={'selectedMoratoriumSelect'}
                                                            className={styles.currencyDropdown}
                                                            value={selectedMoratorium}
                                                            onChange={(e) => {
                                                                handleMoratoriumChange(e.target.value)
                                                                setFieldValue('moratorium', e.target.value)
                                                            }
                                                            }>
                                                            <option value={''} disabled selected>
                                                                Select
                                                            </option>
                                                            {tenorOptions.map((moratorium, index) => (
                                                                <option id={`${moratorium}option`}
                                                                        key={`${moratorium}option`} value={moratorium}>
                                                                    {moratorium}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                {errors.moratorium && touched.moratorium && (
                                                    <div id={'moratoriumError'}
                                                         className={styles.error}>{errors.moratorium}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>


                                    <div className={`flex justify-between`}>
                                        <div id={'all'} className={`${styles.oll} flex flex-col`}>
                                            <label id={'LimitLoan'}>Obligor Loan Limit</label>
                                            <Field
                                                type="text"
                                                name="obligorLoanLimit1"
                                                placeholder="00.0"
                                                min={0}
                                                max={25}
                                                style={{
                                                    borderColor:
                                                        errors.obligorLoanLimit1 && touched.obligorLoanLimit1 ? "red" : "inherit",
                                                }}
                                                onChange={(e: any) => {
                                                    setFieldValue("obligorLoanLimit1",
                                                        utils.formatValueWithComma(e));
                                                }}
                                            />
                                            {errors.obligorLoanLimit1 && touched.obligorLoanLimit1 && (
                                                <div id={'obligoErrorrLoanLimit'}
                                                     className={styles.error}>{errors.obligorLoanLimit1}</div>
                                            )}
                                        </div>

                                        <div id={'interestRateDiv'} className={`${styles.ir} flex flex-col`}>
                                            <label id={'InterestLabel'}>Interest Rate %</label>
                                            <Field
                                                type="number"
                                                name="interestRate"
                                                placeholder="0.00"
                                                // min={0}
                                                // max={50}
                                                style={{
                                                    borderColor: errors.interestRate && touched.interestRate ? "red" : "inherit",

                                                }}/>
                                            {errors.interestRate && touched.interestRate && (
                                                <div id={'InterestErrorDiv'}
                                                     className={styles.error}>{errors.interestRate}</div>
                                            )}
                                        </div>

                                    </div>
                                    <div id={'termsDiv'} className={styles.terms}>
                                        <label id={'conditionsTermsLabel'}>Terms and Conditions</label>
                                        <Field
                                            as="textarea"
                                            type="text"
                                            name="termsAndCondition"
                                            placeholder="Enter Terms and Conditions"
                                            style={{
                                                borderColor: errors.termsAndCondition && touched.termsAndCondition ? "red" : "inherit",
                                            }}
                                            className="w-[70vw] md:w-full sm:w-[59vw]  p-2 border rounded-md resize-none"
                                        />
                                        {errors.termsAndCondition && touched.termsAndCondition && (
                                            <div id={'termsAndConditionError'}
                                                 className={styles.error}>{errors.termsAndCondition}</div>
                                        )}
                                    </div>


                                    <div id={'isLoadingDiv'} className="flex justify-end mt-4">
                                        <button id={'isLoadingButton'}
                                                className=" bg-green-600 text-[white] w-[200px] h-[50px] rounded-md mt-4 "
                                                type="submit">
                                            {isLoading ? (
                                                <div id={'iconDiv'} className="flex items-center justify-center">
                                                    <Icon id={"Icon"} icon={loadingLoop} width={24} height={24}/>
                                                </div>
                                            ) : (
                                                "Create"
                                            )}
                                        </button>
                                    </div>
                                </div>

                            </Form>
                        )}

                    </Formik>
                </div>
            </div>

        </Modal>
    );
}

export default CreateLoanProduct;
