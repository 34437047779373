import React, { useEffect, useRef, useState } from 'react';
import styles from "./styles/Notifications.module.css";
import { GrClose } from "react-icons/gr";
import learn from "../../../features/dashboard/assets/images/learn.svg";
import active from "../../../features/dashboard/assets/images/active.svg";
import { NavLink } from 'react-router-dom';
import ViewLoanOffer from '../../../features/dashboard/trainee/viewLoanOffer/ViewLoanOffer';

interface IProps {
    close: () => void;
}

function Notifications(props: IProps) {
    const [viewLoanOfferModalOpen, setViewLoanOfferModalOpen] = useState(false);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const notificationsRef = useRef<HTMLDivElement>(null);

    const handleCloseInviteModal = () => {
        setShowInviteModal(false);
    };

    const openViewLoanOfferModal = () => {
        setViewLoanOfferModalOpen(true);
    };

    const closeViewLoanOfferModal = () => {
        setViewLoanOfferModalOpen(false);
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (notificationsRef.current && !notificationsRef.current.contains(event.target as Node)) {
                props.close();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [props]);

    return (
        <div id={`notificationMainDiv`} className={styles.main} ref={notificationsRef}>
            <div id={'notificationTop'} className={styles.top}>
                <h6 id={`h6Div`}>Notifications</h6>
                <button id={`closeButton`} onClick={props.close}>
                    <GrClose id={`GrClose`} className={styles.closeIcon} />
                </button>
            </div>
            <div id={`notify`} className={styles.notify}>
                <div id={`learnDIv`} className={styles.img}><img id={`learn`} src={learn} alt="logo" /></div>
                <div id={`activeMainDiv`} className=''>
                    <div id={`activeInnerDiv`} className={styles.name}>
                        <h4 id={`LearnSpacePortfolioManager`} className='w-[220px]'>Learnspace Portfolio Manager</h4>
                        <img id={`active`} src={active} alt="" className='w-[8px]' />
                    </div>
                    <p id={`sent`}>
                        LearnSpace just sent you a loan offer.{' '}
                        <NavLink id={'navLink'} to="/view-loan-offer-as-a-trainee" onClick={openViewLoanOfferModal}>
                            Make Decision
                        </NavLink>
                    </p>
                </div>

                {/* Render the ViewLoanOffer modal */}
                {viewLoanOfferModalOpen && <ViewLoanOffer />}
            </div>
        </div>
    );
}

export default Notifications;
