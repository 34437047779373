import React, {useLayoutEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import AcceptLoanOffer from './AcceptLoanOffer';
import Modal from '../../../../components/Modal';
import {HiOutlineX} from "react-icons/hi";
import {ViewALoanOfferAsATraineeResponse} from '../../../../types/loan/loan.type';
import loanService from '../../../../services/loan.service';
import utils from "../../../../utils/utils";


interface Accept {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}


const ViewLoanOffer = () => {
    const [showInputFields, setShowInputFields] = useState(false);
    const [showDeclineReason, setShowDeclineReason] = useState(false);
    const [declineReason, setDeclineReason] = useState('');
    const [clickCount, setClickCount] = useState(0);
    const navigate = useNavigate();
   
    const submitDeclineReason = () => {
        console.log('Decline Reason:', declineReason);
        setShowDeclineReason(false);
        setDeclineReason('');
    };

    const handleCreateLoanOffer = () => {
        setClickCount((prevCount) => prevCount + 1);

        if (clickCount === 0) {
            setShowInputFields(true);
        } else if (clickCount === 1) {
            navigate('/view-loan-offer');
            toast.success('Loan offer was created successfully', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                    background: '#101828',
                    color: '#FFFFFFB2',
                },
                icon: false,
            });
        }
    };


    const [data, setData] = useState<ViewALoanOfferAsATraineeResponse>({} as ViewALoanOfferAsATraineeResponse)

    const fetchData = async (values?: any) => {
        const requestBody: ViewALoanOfferAsATraineeResponse = values;
        try {
            const loanOffer = await loanService.viewLoanOfferAsATrainee(requestBody);

            if (!loanOffer) {
                throw new Error('Network response was not ok');
            }
            const data = loanOffer;
            setData(data);
        } catch (error) {
            utils.handleRequestError(error)
        }
    };
    useLayoutEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <Modal
               isOpen
            >
                <div id={`ncaijn`} >
                    <div id={`hcbjsd`} className="relative p-4 w-full max-w-2xl max-h-full">
                        <div id={`cods`} className="flex items-center justify-between p-4 md:p-5">
                            <h3 id={`bachbjs`} className=" text-[#000000] font-ibm text-lg font-bold">View Loan Offer</h3>
                            <div
                                id={`nacjds`}
                                className="text-[#000000] font-bold bg-transparent cursor-pointer"
                            >
                                <HiOutlineX id={`skjnkjanjnak`} className="" />
                            </div>

                        </div>
                        <div id={`cnksijnds`} className="flex items-center justify-between p-4 md:p-5">
                            <p id={`dlksknvfd`} className='font-ibm text-[#72757A]'>Crosscheck the details below</p>
                            <h3 id={`dnsknkvs`} className='font-ibm text-[#101828] font-bold'>Institute Name: <span className='font-ibm text-gray-600'>{data.instituteName}</span> </h3>
                        </div>

                        <div id={`nskjnkerlp`} className="p-4 md:p-5 space-y-4">
                            <h2 id={`cpvnijk`} className="font-normal font-ibm">Loan offer Information</h2>
                            <div id={`nsjnvkdsl`} className="grid grid-cols-2 grid-rows-2 gap-5 ">
                                <div id={`nsojvnsrsro`} className=''>
                                    <h3 id={`pdfncsjni`} className='font-bold font-ibm text-[#101828]'>Loan Amount Requested</h3>
                                    <p id={`cnsubujhbvjse`} className='font-medium font-ibm mt-3'>₦{data.loanAmountRequest}</p>
                                </div>

                                <div id={'ajnicjdmnfie'}>
                                    <h3 id={`kjcnson`} className='font-bold font-ibm text-[#101828]'>Loan Amount Approved</h3>
                                    <p id={`nsjnkdk`} className='font-medium font-ibm mt-3'>₦{data.loanAmountApproval}</p>
                                </div>
                                <div id={`jbsjvbjbhd`}>
                                    <h3 id={`ksnjbjjnoe`} className='font-bold font-ibm text-[#101828]'>Interest Rate</h3>
                                    <p id={`sjbuhfjdkofdjbui`} className='font-medium font-ibm mt-3'>{data.interestRate}%</p>
                                </div>
                                <div id={`snkjnoineinc`}>
                                    <h3 id={`ncsijnvinfujoidf`} className='font-bold font-ibm text-[#101828]'>Tenure</h3>
                                    <p id={`scidvbio`} className='font-medium font-ibm mt-3'>{data.tenure} years</p>
                                </div>
                                <div>
                                    <h3 id={`ncsbj93ewikd`} className='font-bold font-ibm text-[#101828]'>Moratorium</h3>
                                    <p id={`83uiewjnf8`} className='font-medium font-ibm mt-3'>{data.moratorium} months</p>
                                </div>
                                <div id={`chjdiwe`}>
                                    <h3 id={`bhjdboe`} className='font-bold font-ibm text-[#101828]'>Loan Product Name</h3>
                                    <p id={`ncosjer`} className='font-medium font-ibm mt-3'>{data.loanProductName}</p>
                                </div>
                            </div>
                        </div>

                        <div id={`svnibjvsre3`} className="p-4 md:p-5 space-y-4">
                            <h2 id={`bjslkjs34o`} className="font-normal font-ibm">Personal Information</h2>
                            <div id={`njidsoknvfd`} className="text-base leading-relaxed text-gray-500 dark:text-gray-400 grid grid-cols-2 grid-rows-2 gap-2 ">
                                <div id={`snijdnvenfd`}>
                                    <h3 id={`bchsjbhjer`} className='font-bold font-ibm text-[#101828]'>First Name</h3>
                                    <p id={`csdbhce `} className='font-medium font-ibm mt-3- text-[#101828]'>{data.firstName}</p>
                                </div>

                                <div id={`ncjbsuhbd`}>
                                    <h3 id={`bhsbidls`} className='font-bold font-ibm text-[#101828]'>Last Name</h3>
                                    <p id={`shciej`} className='font-medium font-ibm mt-3 text-[#101828]'>{data.lastName}</p>
                                </div>
                                <div id={`cnincjkme`}>
                                    <h3 id={`mdfvihnkvr`} className='font-bold font-ibm text-[#101828]'>Email Address</h3>
                                    <p id={`nslknoree3`} className='font-medium font-ibm mt-3 text-[#101828]'>{data.email}</p>
                                </div>
                                <div id={`mvlnonr044`}>
                                    <h3 id={`kfshuhbvd`} className='font-bold font-ibm text-[#101828]'>Phone Number</h3>
                                    <p id={`vmoskfmvokmem043e`} className='font-medium font-ibm mt-3 text-[#101828]'>{data.phoneNumber}</p>
                                </div>
                            </div>
                        </div>

                        {showInputFields && <div id={`knsnconvosnvdnfjj`}><AcceptLoanOffer /></div>}

                        <div id={`bjchbsbvudbhore`} className="p-4 md:p-5 mr-10">
                            {showDeclineReason ? (
                                <div id={`nkdjsbvhbjfvre`} className="mt-4">
                                    <div id={`sdusirekds`} className="mt-4">
                                        <p id={`cgushdvjhbie`}>Reason for Declining Loan Offer</p>
                                        <textarea
                                            id="declineReason"
                                            name="declineReason"
                                            className="mt-1 p-2 border rounded-md w-[600px]"
                                            placeholder="message"
                                            value={declineReason}
                                            onChange={(e) => setDeclineReason(e.target.value)}
                                        />
                                    </div>
                                    <button
                                        id={`snsdbvjhsoed`}
                                        type="button"
                                        className="bg-green-600 text-white w-40 h-10 mt-2 ml-[80%] rounded-md"
                                        onClick={submitDeclineReason}
                                    >
                                        Submit
                                    </button>
                                </div>
                            ) : (
                                <div id={`cbshdiuhed`} className="flex items-center justify-center p-4 md:p-5 mr-10">
                                    {!showInputFields && (
                                        <button
                                            id={`cnioefnirenn`}
                                            onClick={() => setShowDeclineReason(true)}
                                            type="button"
                                            className="text-[#0D9B48] border-green-400 w-52 font-medium  rounded-lg border text-sm px-5 py-2.5 text-center"
                                        >
                                            Decline
                                        </button>
                                    )}
                                    <button
                                        id={`jnisnvjoejndsv`}
                                        onClick={handleCreateLoanOffer}
                                        type="button"
                                        className="ms-3 text-gray-500 bg-white rounded-lg w-52 border border-gray-200 text-sm font-medium px-5 py-2.5 focus:z-10 dark:bg-green-700 dark:text-white   "
                                    >
                                        Accept Offer
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ViewLoanOffer;
