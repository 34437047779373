import React from 'react';

interface IProps{
    color?: string;
}
function AccountIcon({color}: IProps) {
    const colorPalette = !!color ? color : "#72757A";
    return (
        <svg id={`dksjfkj`} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id={`gfhkghfbxc`} d="M8.8444 0.935303C4.041 0.935303 0.142578 4.83372 0.142578 9.63713C0.142578 14.4405 4.041 18.339 8.8444 18.339C13.6478 18.339 17.5462 14.4405 17.5462 9.63713C17.5462 4.83372 13.6478 0.935303 8.8444 0.935303ZM4.5544 15.1019C4.92858 14.3187 7.20846 13.553 8.8444 13.553C10.4803 13.553 12.7689 14.3187 13.1344 15.1019C11.951 16.0417 10.4629 16.5986 8.8444 16.5986C7.22586 16.5986 5.73785 16.0417 4.5544 15.1019ZM14.3788 13.8401C13.1344 12.326 10.1149 11.8126 8.8444 11.8126C7.57394 11.8126 4.5544 12.326 3.31004 13.8401C2.38561 12.6339 1.88408 11.1568 1.88294 9.63713C1.88294 5.79962 5.0069 2.67567 8.8444 2.67567C12.6819 2.67567 15.8059 5.79962 15.8059 9.63713C15.8059 11.2209 15.2664 12.6741 14.3788 13.8401ZM8.8444 4.41603C7.15625 4.41603 5.79877 5.77352 5.79877 7.46167C5.79877 9.14983 7.15625 10.5073 8.8444 10.5073C10.5326 10.5073 11.89 9.14983 11.89 7.46167C11.89 5.77352 10.5326 4.41603 8.8444 4.41603ZM8.8444 8.76695C8.12215 8.76695 7.53913 8.18392 7.53913 7.46167C7.53913 6.73942 8.12215 6.1564 8.8444 6.1564C9.56666 6.1564 10.1497 6.73942 10.1497 7.46167C10.1497 8.18392 9.56666 8.76695 8.8444 8.76695Z" fill={colorPalette}/>
        </svg>
    );
}

export default AccountIcon;