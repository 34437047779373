import React from 'react';
import ReactModal from 'react-modal';
import "./AuthModalStyle.css"
import {ModalButton, ModalParagraph, ModalTitle} from "./AuthModal.style";


interface IProps {
    closeModal: () => void;
    isModalVisible: boolean;
    title: string;
    body: string;
    message: string;
    action?: string;
}

const AuthModal: React.FC<IProps> = ({
                                         closeModal,
                                         isModalVisible,
                                         title,
                                         body,
                                         message,
                                         action
                                     }) => {
    ReactModal.setAppElement("#root");

    const customStyles = {
        overlay: {
            background: "#000000",
            opacity: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    };

    return (
        <ReactModal
            id={'reactMOdal'}
            className="modal-body"
            onRequestClose={closeModal}
            isOpen={isModalVisible}
            style={customStyles}
        >
            <div id={'title'}>
                <ModalTitle id={'ModalTitle'}>{title}</ModalTitle>
                <ModalParagraph id={'ModalParagraph'}>{body}</ModalParagraph>
                <ModalParagraph id={'ModalParagraph'}>{message}</ModalParagraph>
                <ModalButton id={'ModalButton'} onClick={closeModal}>
                    {action || "Okay"}
                </ModalButton>
            </div>
        </ReactModal>
    );
}

export default AuthModal;
