import {TableData} from "../../../../types/commons.type";
import {IInvestmentData} from "./InvestmentDataTable";

const investmentData: IInvestmentData[] = [
  {
    investmentId: "102",
    productName: "Learning Well Product",
    investmentDate: "10 Sept,2022",
    maturityDate: "10 Dec,2023",
    amountInvested: "₦10,000,000,000.75",
    interestEarned: "₦100,000,000,000.98",
    amountPaidOut: "₦100,000,000,000.98",
  },

  {
    investmentId: "102",
    productName: "Learning Well Product",
    investmentDate: "10 Sept,2022",
    maturityDate: "10 Dec,2023",
    amountInvested: "₦10,000,000,000.75",
    interestEarned: "₦100,000,000,000.98",
    amountPaidOut: "₦100,000,000,000.98",
  },

  {
    investmentId: "102",
    productName: "Learning Well Product",
    investmentDate: "10 Sept,2022",
    maturityDate: "10 Dec,2023",
    amountInvested: "₦10,000,000,000.75",
    interestEarned: "₦100,000,000,000.98",
    amountPaidOut: "₦100,000,000,000.98",
  },

  {
    investmentId: "102",
    productName: "Learning Well Product",
    investmentDate: "10 Sept,2022",
    maturityDate: "10 Dec,2023",
    amountInvested: "₦10,000,000,000.75",
    interestEarned: "₦100,000,000,000.98",
    amountPaidOut: "₦100,000,000,000.98",
  },
];

export const investmentMockData: TableData<IInvestmentData> = {
  pageNo: 1,
  pageSize: 5,
  totalElement: 5,
  total: 1,
  last: false,
  content: investmentData,
};

export const investments: TableData<IInvestmentData> = {
  pageNo: 1,
  pageSize: 5,
  totalElement: 5,
  total: 1,
  last: false,
  content: [],
};
