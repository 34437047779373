import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";

interface ITableData{
    // change to appropriates
    table: any;
    pageNumber?: number | undefined;
}

const initialState: ITableData = {
    table: ""
}

const tableSlice = createSlice({
    name: "table",
    initialState,
    reducers: {
        setTableData:(state, action:PayloadAction<any>) =>{
            state.table = action.payload;
        },
        setPageNumber:(state, action:PayloadAction<any>)=>{
            state.pageNumber = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.table = undefined;
            state.pageNumber = undefined;
        });
    },
})

export const tableActions = tableSlice.actions
export default tableSlice.reducer;