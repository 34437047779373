import React, {useState} from 'react';
import TraineeForm from "../form/TraineeForm";
import styles from "../styles/AdditionalInformation.module.css";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Icon} from "@iconify/react";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import {useNavigate} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks/hooks";
import { traineeActions } from "../../../../redux/slices/traineeSlice";

const phoneRegExp = /^(0[789][01]\d{8}|[789][01]\d{8})$/;

const validationSchema = Yup.object().shape({
    alternateEmailAddress: Yup.string().email("Invalid email address").required("Alternate email address is required"),
    alternatePhoneNumber: Yup.string().matches(phoneRegExp, "Invalid phone number.").required("Phone number is required"),
});

function AdditionalInformation() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const initialValues = {
        alternateEmailAddress: "",
        alternatePhoneNumber: "",
    };

    const handleBackButton = () => {
        navigate("/loan-application/confirm-id");
    }

    const handleSubmit = (values: any) => {
        console.log(values)
        dispatch(traineeActions.setAdditionalInfo(values));
        navigate("/loan-application/confirm-details");
    }

    const [selectedCode, setSelectedCode] = useState('+234');
    const countryCode = ['+234', '+44'];

    const handleCodeChange = (code: string) => {
        setSelectedCode(code);
    };

    const renderDetail = (label: string, value: any) => (
        <div className={``} key={label}>
            <div>
                <p className={styles.label}>{label}</p>
                <div className={styles.value}>{value || 'N/A'}</div>
            </div>
        </div>
    );


    return (
        <TraineeForm formName={`Additional Information`}>
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({errors, touched}) => (
                        <Form>
                            <div className={`grid grid-cols-1 gap-3 p-6`}>
                                <div className={`${styles.field}`}>
                                    <label>Alternate Email Address</label>
                                    <Field
                                        type="email"
                                        name="alternateEmailAddress"
                                        placeholder="Enter alternate email address"
                                        style={{
                                            borderColor: errors.alternateEmailAddress && touched.alternateEmailAddress ? "red" : "inherit",
                                            width: "100%", '@media (min-width: 768px)': {
                                                width: "470px",
                                            }
                                        }}
                                    />
                                    {errors.alternateEmailAddress && touched.alternateEmailAddress && (
                                        <div className={styles.error}>{errors.alternateEmailAddress}</div>)}
                                </div>

                                <div className={styles.field}>
                                    <label>Alternate Phone Number</label>
                                    <div className={`${styles.countryCode} flex`}
                                         style={{borderColor: errors.alternatePhoneNumber && touched.alternatePhoneNumber ? "red" : "inherit"}}
                                    >
                                        <select
                                            className={styles.countryCodeDropdown}
                                            value={selectedCode}
                                            onChange={(e) => handleCodeChange(e.target.value)}
                                        >
                                            {countryCode.map((code, index) => (
                                                <option key={index} value={code}>
                                                    {code}
                                                </option>
                                            ))}
                                        </select>
                                        <Field
                                            type={`number`}
                                            name={`alternatePhoneNumber`}
                                            placeholder={`Enter alternate phone number`}
                                            style={{width: "400px"}}
                                        />
                                    </div>
                                    {errors.alternatePhoneNumber && touched.alternatePhoneNumber && (
                                        <div className={styles.error}>{errors.alternatePhoneNumber}</div>
                                    )}
                                </div>

                            </div>
                            <div
                                className="mt-[20px] flex md:flex-row flex-col-reverse justify-between align-middle border border-b-3 p-4">
                                <button
                                    className={styles.back}
                                    onClick={handleBackButton}
                                >
                                    Back
                                </button>
                                <button className={styles.continue} type="submit">
                                    {isLoading ? (
                                        <div className="flex items-center justify-center">
                                            <Icon icon={loadingLoop} width={24} height={24}/>
                                        </div>
                                    ) : (
                                        "Continue"
                                    )}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </TraineeForm>
    );
}

export default AdditionalInformation;
