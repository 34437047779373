import React, {ChangeEvent, CSSProperties} from "react";
import {InputDiv} from "../password/password.style";

interface IProps {
  text?: any;
  onTextChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  imgSrc?: string;
  style?: CSSProperties;
  placeHolderText?: string;
  inputType?: "text" | "number" | "date" | "textarea";
  name?: string;
  className?: any;
  onFocus?: ()=> void;
  onBlur?: (e: any)=> void;
}
function TextInput({
  text,
  onTextChange,
  className,
  imgSrc,
  style,
  placeHolderText,
  inputType,
  name,
    onFocus,
    onBlur
}: IProps) {
  return (
    <InputDiv id={'onTextChange'}>
      <input
          id={'text'}
        type={inputType}
        value={text}
        required
        onChange={onTextChange}
        placeholder={placeHolderText}
        style={style}
        name={name}
        className={className}
        onFocus={onFocus}
        onBlur={onBlur}
        
      />
      {imgSrc && <img src={imgSrc} alt="learnspace logo" />}
    </InputDiv>
  );
}

export default TextInput;
