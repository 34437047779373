import React, {useState} from 'react';
import styles from "./TableTop.module.css"
import search from "../../../features/dashboard/assets/images/search.svg";
import {store} from "../../../redux/store";
import {searchActions} from "../../../redux/slices/searchTextSlice";

interface IProps {
    placeholder: string;
    title?: string;
    tableTitle?: string;
    buttonClick?: () => void | any
    inputChange?: () => {} | void;
    setButtonNeeded: boolean;
    isSelectable?: boolean;
    selectOptions?: any[];
    selectText?: () => void | any
}

function TableTop({placeholder, tableTitle, title, buttonClick, inputChange, setButtonNeeded, selectOptions,isSelectable, selectText}: IProps) {

   const [isButtonNeeded, setIsButtonNeeded] = useState(setButtonNeeded);
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        store.dispatch(searchActions.setSearchString(inputValue));
    };

    return (
        <div id={`tableTopMainDiv`} className={'grid grid-cols-2 items-center'}>
            <div id={`tableTitleDiv`}>
                <h1 id={'tableTitle'} className={'font-bold text-[22px] '}>{tableTitle}</h1>
            </div>


            <div id={"rightGridContainer"} className={` justify-end items-center gap my-3 ${styles.main}`}>
                <div id={'rightGridInnerContainer'} className={`flex justify-end items-center ga my-3 ${styles.main}`}>

                    <div id={`imageDiv`}
                         className="flex items-center border rounded-md border-solid border-[#D0D5DD] w-[300px] px-2 bg-white">
                        <img id={`search`} src={search} alt="search" className="w-6 h-4 ml-2"/>

                        <input
                            id={`text ${placeholder} input`}
                            type="text"
                            placeholder={placeholder}
                            className="bg-transparent outline-none border-none flex-1"
                            onChange={handleInputChange}
                        />
                    </div>

                    <button
                        id={`titleIsButtonNeededButton`}
                        className={`text-white text bg-[#0D9B48] w-[220px] rounded-[4px] cursor-pointer border-0 px-10 py-2 ml-3
                            ${!isButtonNeeded ? 'hidden' : ''}`}
                        onClick={buttonClick}
                    >
                        <p id={`titlePtag`} className='w-[150px]'>{title}</p>
                    </button>
                </div>

            </div>
        </div>
    );
}

export default TableTop;