import React from "react";
import Layout from "../../../../components/reusables/layout/Layout";
import BreadCrumbs from "../../../../components/breadCrumb/BreadCrumbs";

const BankDetails: React.FC = () => {
    const asteriskAccountNumber = (accountNumber: string): string => {
        const firstTwoDigits = accountNumber.slice(0, 2);
        const asterisks = "****";
        const remainingDigits = accountNumber.slice(6);
        return `${firstTwoDigits}${asterisks}${remainingDigits}`;
    };


    const sections: any[] = [
        // {
        //     bankName: "FCMB",
        //     accountNumber: "20001234581",
        //     accountName: "Alice Omodele Idowu",
        //     isPrimary: true
        // },
        // {
        //     bankName: "GTB",
        //     accountNumber: "67234153908",
        //     accountName: "Kunle Adelabu Taye",
        //     isPrimary: false
        // },
        // {
        //     bankName: "First Bank",
        //     accountNumber: "14523876513",
        //     accountName: "Bamidele Olaolu Tokunbo",
        //     isPrimary: false
        // }
    ];

    return (
        <Layout>
            <div id="InvestorBankMainDiv" className="p-12">
                <div id={'acsdjk'} className={`mb-5`}>
                    <BreadCrumbs/>
                </div>
                <div id="bankInnerDiv" className="bg-white p-8 flex flex-col rounded-[6px]">
                    {
                        sections.length === 0 && (
                            <div id={"acpod"} className={``}>
                                <p id={'caoieds'} className={`text-center m-20`}>No Bank Details Added Yet</p>
                            </div>
                        )
                    }
                    {sections.map((section, index) => (
                        <div id={"capdslas"} key={index} className="grid grid-cols-4 mb-5 gap-x-28 gap-y-20">
                            <div id={'caepod'}>
                                <h1 id={'jndcjks'} className="font-medium text-lg">Bank Name</h1>
                                <small id={'caidsk'} className="font-semicold text-base text-[#5F646F]">
                                    {section?.bankName}
                                </small>
                            </div>
                            <div id={'casdjk'}>
                                <h1 id={'cekdsl'} className="font-medium text-lg">Account Number</h1>
                                <small id={'chsiowl'} className="font-semicold text-base text-[#5F646F]">
                                    {asteriskAccountNumber(section?.accountNumber)}
                                </small>
                            </div>
                            <div id={'caeahbksde'}>
                                <h1 id={'caeoidkjsk'} className="font-medium text-lg">Account Name</h1>
                                <small id={'caejhdjs'} className="font-semicold text-base text-[#5F646F]">
                                    {section.accountName}
                                </small>
                            </div>
                            {section.isPrimary && (
                                <div id={'cehdsjh'} className="rounded-[6px] bg-[#E7F7ED] px-8 py-2 ">
                                    <button id={"uheridfljk"} className="text-[#0D9B48] text-xs font-normal ">
                                        Primary Account
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    );
};

export default BankDetails;
