import React from 'react';
function HourGlassIcon() {
    return (
        <svg width="30" height="50" viewBox="0 0 30 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 2.5L27.5 2.5L27.5 13L15 25L2.5 13L2.5 2.5Z" fill="#0D9B48"/>
            <path d="M30 50L30 35H29.975L30 34.975L20 25L30 15L29.975 14.975H30L30 0L0 0L0 14.975H0.0250015L0 15L10 25L0 34.975L0.0250015 35H0L0 50L30 50ZM5 13.75L5 5H25L25 13.75L15 23.75L5 13.75ZM15 26.25L25 36.25V45H5V36.25L15 26.25Z" fill="#0D9B48"/>
        </svg>
    );
}

export default HourGlassIcon;