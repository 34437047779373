import React from 'react';
import Layout from '../../../../components/reusables/layout/Layout';
import BioData from '.';


const BioLayout = () => {

    return (
        <Layout>
            <BioData/>
        </Layout>
    )

}

export default BioLayout;