import axios, {AxiosInstance} from "axios";

class ApiInstance {
  private readonly instance: AxiosInstance;

  constructor(authToken?: string | null, token?: string) {
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };

    if (authToken) {
      headers["Authorization"] = `Bearer ${authToken}`;
    }

    if (token) {
      headers["Authentication"] = token;
    }

    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_BASEURL,
      headers,
    });
  }

  getInstance() {
    return this.instance;
  }
}

export default ApiInstance;
