import React, {ReactNode} from 'react';
import styles from "../styles/Form.module.css";

interface IProps{
    formName: string;
    children: ReactNode;
}

function TraineeForm({formName, children}: IProps) {
    return (
        <div id={`snkljdfnjk`} className={styles.background}>
            <div id={`sdjnvoe`} className="border border-b-2 p-4 flex justify-between">
                <h2 id={`bhsdjljviierudjk`} className={styles.headings}>{formName}</h2>
            </div>
            <div id={`jdkjlsknvron`}>
                {children}
            </div>
        </div>
    );
}

export default TraineeForm;