import React, {useEffect, useState,} from 'react';
import {IColumnProps, TableData} from "../../../../types/commons.type";
import {useAppSelector} from "../../../../redux/hooks/hooks";
import LearnSpaceTable from "../../../../components/reusables/table/Table";
import {useNavigate} from "react-router-dom";
import {investorActions} from "../../../../redux/slices/investorSlice";
import {store} from "../../../../redux/store";


interface IProps {
    investorsData: any;

}

const InvestorDataTable: React.FC<IProps> = ({investorsData}) => {
    const navigate = useNavigate()

    function handleViewInvestorDetails(investor: any) {
        navigate("/investor/biodata")
        store.dispatch(investorActions.setInvestorDetail(investor));
    }

    const investorColumn: IColumnProps[] = [

         {
            title: 'Investor Name',
            selector: 'investorName',
            sortable: true,
         },

        {
            title: 'Email',
            selector: 'email',
            sortable: true,
        },

        {
            title: 'Phone Number',
            selector: 'phoneNumber',
            sortable: true,
        },
        {
            title: 'Investment Amount',
            selector: 'amountInvested',
            sortable: true,
        },
        {
            title: 'Investment Vehicle',
            selector: 'fundProductName',
            sortable: true,
        }
    ]

    const getSearchString = useAppSelector(state => state.search.searchString)

    return (
        <>
            <LearnSpaceTable
                tableData={investorsData}
                columns={investorColumn}
                searchString={getSearchString}
                searchColumn="investorName"
                emptyTable={{
                    image: "",
                    title: "No Record Found",
                    subText: "No Investor Available",
                    text: "",
                }}
                path={""}
                handleRowClick={(row: any) => {
                    handleViewInvestorDetails(row);
                }}
                // handleRowClick={handleViewInvestorDetails}
            />

        </>
    )
}


export default InvestorDataTable;

