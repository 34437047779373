import {TableData} from "../commons.type";
import {IAddInvestorRequest} from "../investor.type";

export interface IAddPayment {
    accountName: string,
    bankName: string,
    amountPaid: number,
    datePaid: Date,
    paymentMode: string
}

export interface IAddPaymentResponse {
    data: IAddPayment,
    successful: boolean
}

export interface loanRequestData {
    traineeName: string;
    identity: string;
    programDate: Date;
    amountRequested: number;
    referralDate: Date;
    creditWorthiness: string;
    loanApplicationStatus: string;
}
export interface adminData {
    adminName: string;
    adminEmail: string;
    status: string;
}


export interface AdjustedTraineeData {
    traineeId: any;
    traineeName: string;
    cohortName: any;
    referralDate: Date;
    verified: boolean;
    traineeLoanRequestStatus: string;
    traineeStatus: string;
    programStartDate: string | null;
    amountRequested: number | null;
}
export interface traineeData {
    firstName: string,
    lastName: string,
    verified: boolean;
    programDate: Date;
    amountRequested: number;
    referralDate: Date;
    creditWorthiness: string;
    traineeLoanRequestStatus: string;
}

export interface Trainee {
    traineeId: string;
    firstName: string;
    lastName: string;
    cohortName: string;
    referralDate: string;
    verified: boolean;
    traineeLoanRequestStatus: string;
    traineeStatus: string;
    programStartDate: string | null;
    amountRequested: number | null;
}
export interface IViewALoanOfferAsATraineeResponse {
    data: ViewALoanOfferAsATraineeResponse,
    successful: boolean
}

export interface ViewALoanOfferAsATraineeResponse {
firstName : string;
lastName: string;
email: string;
phoneNumber: string;
loanProductName: string;
instituteName: string;
interestRate: number;
tenure: number;
moratorium: number;
loanAmountRequested: number;
loanAmountApproved: number;


}
export interface TraineeId{
    traineeId: string | null;
}


const loanRequestMock: loanRequestData[] = [
    {
        "traineeName": "Racheal Adenuga",
        "identity": "verified",
        "programDate": "13th Dec. 2023" as unknown as Date,
        "amountRequested": 3000000,
        "referralDate": "12th Jan. 2024" as unknown as Date,
        "creditWorthiness": "Good",
        "loanApplicationStatus": "New"
    },
    {
        "traineeName": "Racheal Adenuga",
        "identity": "unverified",
        "programDate": "13th Dec. 2023" as unknown as Date,
        "amountRequested": 3000000,
        "referralDate": "12th Jan. 2024" as unknown as Date,
        "creditWorthiness": "Good",
        "loanApplicationStatus": "New"
    },
    {
        "traineeName": "Racheal Adenuga",
        "identity": "verified",
        "programDate": "13th Dec. 2023" as unknown as Date,
        "amountRequested": 3000000,
        "referralDate": "12th Jan. 2024" as unknown as Date,
        "creditWorthiness": "Good",
        "loanApplicationStatus": "New"
    },
    {
        "traineeName": "Racheal Adenuga",
        "identity": "verified",
        "programDate": "13th Dec. 2023" as unknown as Date,
        "amountRequested": 3000000,
        "referralDate": "12th Jan. 2024" as unknown as Date,
        "creditWorthiness": "Bad",
        "loanApplicationStatus": "New"
    },
    {
        "traineeName": "Racheal Adenuga",
        "identity": "unverified",
        "programDate": "13th Dec. 2023" as unknown as Date,
        "amountRequested": 3000000,
        "referralDate": "12th Jan. 2024" as unknown as Date,
        "creditWorthiness": "Good",
        "loanApplicationStatus": "New"
    },
    {
        "traineeName": "Racheal Adenuga",
        "identity": "unverified",
        "programDate": "13th Dec. 2023" as unknown as Date,
        "amountRequested": 3000000,
        "referralDate": "12th Jan. 2024" as unknown as Date,
        "creditWorthiness": "Bad",
        "loanApplicationStatus": "New"
    },
    {
        "traineeName": "Racheal Adenuga",
        "identity": "verified",
        "programDate": "13th Dec. 2023" as unknown as Date,
        "amountRequested": 3000000,
        "referralDate": "12th Jan. 2024" as unknown as Date,
        "creditWorthiness": "Good",
        "loanApplicationStatus": "New"
    },
    {
        "traineeName": "Racheal Adenuga",
        "identity": "unverified",
        "programDate": "13th Dec. 2023" as unknown as Date,
        "amountRequested": 3000000,
        "referralDate": "12th Jan. 2024" as unknown as Date,
        "creditWorthiness": "Bad",
        "loanApplicationStatus": "New"
    },
    {
        "traineeName": "Racheal Adenuga",
        "identity": "verified",
        "programDate": "13th Dec. 2023" as unknown as Date,
        "amountRequested": 3000000,
        "referralDate": "12th Jan. 2024" as unknown as Date,
        "creditWorthiness": "Good",
        "loanApplicationStatus": "New"
    },
    {
        "traineeName": "Racheal Adenuga",
        "identity": "verified",
        "programDate": "13th Dec. 2023" as unknown as Date,
        "amountRequested": 3000000,
        "referralDate": "12th Jan. 2024" as unknown as Date,
        "creditWorthiness": "Good",
        "loanApplicationStatus": "New"
    },
]
export const emptyLoanRequest: TableData<loanRequestData> = {
    pageNo: 1,
    pageSize: 10,
    totalElement: 0,
    total: 0,
    last: true,
    // content: loanRequestMock,
    content: [],
};

export const emptyTraineeTable: TableData<loanRequestData> = {
    pageNo: 1,
    pageSize: 10,
    totalElement: 0,
    total: 0,
    last: true,
    content: [],
};

export const emptyTraineeTableData: TableData<AdjustedTraineeData> = {
    pageNo: 1,
    pageSize: 10,
    totalElement: 0,
    total: 0,
    last: true,
    content: [],
};

export interface TraineeDetailsRequest{
    instituteId: string;
    programId: string;
    cohortId: string;
}

export const traineeMock: TableData<IAddInvestorRequest> = {
    pageNo: 10,
    pageSize: 10,
    totalElement: 100,
    total: 10,
    last: true,
    content: []
}