import {IAddFundProductRequest, IFundProductresponse, IViewFundProductRequest} from "../types/fund.type";
import ApiInstance from "./api";
import {store} from "../redux/store"
import {fundProductActions} from "../redux/slices/fundProductSlice"

class FundProductService {
 
  private apiInstance: ApiInstance;
   // @ts-ignore
  private token:string | undefined = store.getState().auth.token?.access_token;
   private accessToken: string | null = sessionStorage.getItem("token" || "");
  constructor() {
    // @ts-ignore
    this.apiInstance = new ApiInstance(store.getState().auth.token?.access_token)
  }

  public async addFundProduct(data:IAddFundProductRequest):Promise<any> {
    const test_Api = new ApiInstance(sessionStorage.getItem("token"));
    try{
      const response = await test_Api.getInstance().post('/fundProduct/add', data);
      store.dispatch(fundProductActions.setFundProduct(response));
      return response.data;
    }catch(err){
      throw err;
    }

  }

  public async viewAllFundProduct(data:any): Promise<any> {
    try {
      // @ts-ignore
      const test_api = new ApiInstance(store.getState().auth.token?.access_token);
      const response = await test_api.getInstance().post("/fundProduct/viewAll", data);
      store.dispatch(fundProductActions.setFundProduct(response.data));
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public async viewFundProduct(data:IViewFundProductRequest): Promise<IFundProductresponse> {
    // @ts-ignore
    const test_Api = new ApiInstance(store.getState().auth.token?.access_token)
    try {
      const response = await test_Api.getInstance().post(`/fundProduct/viewFundProduct`, data)
      return response.data;
    } catch (err) {
      throw err;
    }
  }

}

const fundProductService = new FundProductService()

export default fundProductService;
