import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import styles from "./styles/CreateLoanOffer.module.css"
import {GrClose} from "react-icons/gr";
import {Icon} from "@iconify/react";
import {Field, Form, Formik} from "formik";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import {toast} from "react-toastify";
import loanService from "../../../../../services/loan.service";
import loanProductService from "../../../../../services/loanProduct.service";
import Modal from "../../../../../components/Modal";
import utils from "../../../../../utils/utils";
import {useAppSelector} from "../../../../../redux/hooks/hooks";

interface IProps {
    onClose?: () => void;
    openModal: boolean;
    closeModal?: () => void;
    buttonText?: string;
}

interface Option {
    loanProductName: string;
    loanProductId: string;
}

const validationSchema = Yup.object().shape({
    loanProductName: Yup.string().required("Loan Product Required"),
    amountApproved1: Yup.string()
        .transform((originalValue) =>
            originalValue?.replace(/,/g, '')?.trim())
        .test(
            'is-positive-number',
            'Approved Amount must be a positive number',
            (value) =>
                typeof value === 'string' &&
                !isNaN(parseFloat(value)) &&
                parseFloat(value) > 0 &&
                !value.startsWith('0')
        )
        .required("Amount Approved is required"),

});


function CreateLoanOfferModal(props: IProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<Option[]>([]);
    const [selectedCurrency, setSelectedCurrency] = useState('NGN');
    const currencyOptions = ['NGN', 'USD', 'GBP'];
    const [selectedValue, setSelectedValue] = useState<string>('');
    const [isEmpty, setIsEmpty] = useState(false);
    const [isLoanOfferFailed, setIsLoanOfferFailed] = useState(false);
    const [isLoanOfferError, setIsLoanOfferError] = useState(false);
    const loanRequestDetails = useAppSelector((state) => state.loan.traineeLoanRequest);
    const loanRequestId = loanRequestDetails?.loanRequestId;
    const traineeId = loanRequestDetails?.trainee?.traineeId;



    const data = {};
    const handleModalClose = () => {
        setSelectedValue('');
        props.onClose && props?.onClose();
    };

    const handleCurrencyChange = (currency: string) => {
        setSelectedCurrency(currency);
    };
    const fetchLoanProduct = async () => {

        try {
            const response = await loanProductService.viewLoanProduct(data);
            setOptions(response.content);

        } catch (err) {
            utils.handleRequestError(err)
        }
        finally{
            setSelectedValue('');
        }
    };

    useEffect(() => {
        fetchLoanProduct().then();
    }, []);


    const handleSelectChange = (
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    ) => (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setSelectedValue(selectedValue);
        setFieldValue("loanProductName", selectedValue);
    };


    const handleSubmit = async (values: any) => {
        const loanAmountApproved = values.amountApproved1.replace(/,/g, "");
        const loanProductId = values.loanProductName;
        const data = {loanAmountApproved, loanRequestId, loanProductId, traineeId}

        delete values.amountApproved1;

        const loanOffer: { loanRequestId: any; traineeId: any; loanProductId: any; loanAmountApproved: any } = data;
        try {
            setIsLoading(true);
            const response = await loanService.createLoanOffer(loanOffer);
            if (response) {
                setIsLoanOfferFailed(false);
                setIsLoanOfferError(false);
                handleModalClose();
                setIsEmpty(true);
                toast.success("Loan Offer Created Successfully!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            setIsLoanOfferError(true);
            toast.error("Error Creating Loan Offer!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setIsLoading(false);
            props.onClose && props?.onClose();
        }

    };


    return (
        <Modal isOpen={props.openModal}>
            <div id={'eiuhdjs'} className={styles.wrapper}>
                <div id={'coiudsj'} className={styles.top}>
                    <h1 id={'casdnj'} className={styles.label2}>Create Loan Offer</h1>
                    <div id={'aowedc'} onClick={handleModalClose} className={`cursor-pointer`}>
                        <GrClose id={'wuhxjks'}/>
                    </div>
                </div>

                <Formik
                    initialValues={{
                        amountApproved1: "",
                        loanProductName: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({errors, isValid, touched, setFieldValue}) => (
                        <Form>
                            <div id={'cuhdsj'} className={styles.inner}>
                                <div id={'edjads'} className={styles.inner2}>
                                    <div id={'euwhued'} className={styles.label}>
                                        <label id={'epojwjid'}>Amount Approved</label>
                                        <div
                                            id={'currencyInputDiv'}
                                            className={styles.currencyInput}
                                            style={{
                                                borderColor: errors.amountApproved1 && touched.amountApproved1 ? "red" : "inherit",
                                            }}>
                                            <select
                                                id={'currencyDropdownSelect '}
                                                className={styles.currencyDropdown}
                                                value={selectedCurrency}
                                                onChange={(e) => handleCurrencyChange(e.target.value)}>
                                                {currencyOptions.map((currency, index) => (
                                                    <option id={`${currency}option`} key={index} value={currency}>
                                                        {currency}
                                                    </option>
                                                ))}
                                            </select>
                                            <Field
                                                type={`text`}
                                                name={`amountApproved1`}
                                                placeholder={`₦0.00`}
                                                style={{border: "none"}}
                                                onChange={(e: any) => {
                                                    setFieldValue("amountApproved1",
                                                        utils.formatValueWithComma(e));
                                                }}/>
                                        </div>
                                        {errors.amountApproved1 && touched.amountApproved1 && (
                                            <div id={'ewioadf'} className={styles.error}>{errors.amountApproved1}</div>
                                        )}
                                    </div>

                                </div>

                                <div id={'euqow'} className={styles.label}>
                                    <label id={'poriea'}> Loan Product Name </label>
                                    <div
                                        id={'currencyInputDiv'}
                                        className={styles.currencyInput1}
                                        style={{
                                            borderColor: errors.loanProductName && touched.loanProductName ? "red" : "inherit",
                                        }}>
                                        <select
                                            id={'pqieuidjks'}
                                            name="loanProductName"
                                            className=" p-2 border rounded-md w-[330px]"

                                            value={selectedValue}
                                            onChange={handleSelectChange(setFieldValue)}
                                        >
                                            <option id={'ewuoiuy'} value="" disabled selected>Select a fund</option>
                                            {options.map((option) => (
                                                <option
                                                    key={option.loanProductId}
                                                    value={option.loanProductId}
                                                    id={option.loanProductId}>
                                                    {option.loanProductName}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.loanProductName && touched.loanProductName && (
                                            <div id={'loanProductSize1Error'} className={styles.error}>
                                                {errors.loanProductName}
                                            </div>)}
                                    </div>
                                </div>

                                <div id={'yeuimd'} className=" w-[330px] flex justify-end mt-10">
                                    {/*<button*/}
                                    {/*    id={'peiquij'}*/}
                                    {/*    className="bg-gray-400 text-[white] w-[150px] h-[50px] rounded-md mt-4 border ml-1 "*/}
                                    {/*    type="submit"*/}
                                    {/*>*/}
                                    <button
                                        id={'isValidButton'}
                                        className={`${
                                            isValid
                                                ? `bg-[#0D9B48] text-white`
                                                : `bg-[#D0D5DD] text-white cursor-not-allowed`
                                        } rounded-[4px] py-[10px] w-[191px] ${styles.eachButton}`}
                                        type="submit"
                                        disabled={!isValid}
                                    >
                                        {isLoading ? (
                                            <div id={'peuwidh'} className="flex items-center justify-center">
                                                <Icon id={'eijds'} icon={loadingLoop} width={24} height={24}/>
                                            </div>
                                        ) : (
                                            "Create Loan Offer"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
}

export default CreateLoanOfferModal;
