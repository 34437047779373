import React from 'react';

interface IProps{
    color?: string;
}
function InviteColleagueIcon({color}:IProps) {
    const colorPalette = !!color ? color : "#72757A";
    return (
        <svg id={`gfghjnkvbguy`} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id={`feghjkik`} clip-path="url(#clip0_14884_82793)">
                <path id={`dfgjbjgjh`} d="M16.9358 15.2759V17.0163H6.49362V15.2759C6.49362 15.2759 6.49362 11.7952 11.7147 11.7952C16.9358 11.7952 16.9358 15.2759 16.9358 15.2759ZM14.3253 7.44429C14.3253 6.92797 14.1722 6.42325 13.8853 5.99395C13.5985 5.56464 13.1907 5.23004 12.7137 5.03246C12.2367 4.83487 11.7118 4.78317 11.2054 4.8839C10.699 4.98463 10.2339 5.23326 9.86878 5.59835C9.50369 5.96344 9.25506 6.4286 9.15433 6.935C9.0536 7.44139 9.1053 7.96629 9.30289 8.4433C9.50047 8.92032 9.83507 9.32803 10.2644 9.61488C10.6937 9.90173 11.1984 10.0548 11.7147 10.0548C12.4071 10.0548 13.0711 9.7798 13.5607 9.29023C14.0502 8.80065 14.3253 8.13665 14.3253 7.44429ZM17.1098 11.8474C17.5855 12.2862 17.969 12.8154 18.2379 13.4041C18.5069 13.9927 18.6558 14.6291 18.6762 15.2759V17.0163H21.2867V15.2759C21.2867 15.2759 21.2867 12.2738 17.1098 11.8474ZM16.0656 4.83374C15.8027 4.8339 15.5414 4.875 15.2912 4.95557C15.8003 5.68562 16.0732 6.55425 16.0732 7.44429C16.0732 8.33433 15.8003 9.20295 15.2912 9.93301C15.5414 10.0136 15.8027 10.0547 16.0656 10.0548C16.758 10.0548 17.422 9.7798 17.9116 9.29023C18.4011 8.80065 18.6762 8.13665 18.6762 7.44429C18.6762 6.75193 18.4011 6.08793 17.9116 5.59835C17.422 5.10878 16.758 4.83374 16.0656 4.83374ZM7.3638 9.18465H4.75326V6.57411H3.01289V9.18465H0.402344V10.925H3.01289V13.5356H4.75326V10.925H7.3638V9.18465Z" fill={colorPalette}/>
            </g>
            <defs id={`ksjdkgdjs`}>
                <clipPath id="clip0_14884_82793">
                    <rect id={`ertyiuythf`} width="20.8844" height="20.8844" fill="white" transform="translate(0.402344 0.48291)"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default InviteColleagueIcon;