import React, {ChangeEvent, MouseEvent, useState} from "react";
import AuthBackground from "../../reuseables/background/AuthBackground";
import PageForm from "../../reuseables/form/Form";
import TextInput from "../../reuseables/input/TextInput";
import Button from "../../reuseables/button/Button";
import AuthModal from "../../reuseables/modal/AuthModal";
import {Link, useNavigate} from "react-router-dom";
import {FooterNote, InnerPageFormDiv, MobileButtonDiv} from "./Index.style";
import "./Index.style.css";
import mailEnvelope from "../../../../assets/mailenvelope.png";
import {IForgetPasswordRequest} from "../../../../types/auth.type";
import authService from "../../../../services/auth.service";
import {authActions} from "../../../../redux/slices/authSlice";
import {useAppDispatch} from "../../../../redux/hooks/hooks";

interface IProps {
}

function ForgetPassword(props: IProps) {
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    // const [navNext, setNavNext] = useState("Okay");
    // const [action, setAction] = useState("Okay");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const openModal = (title: string, message: string, content: string) => {
        setModalTitle(title);
        setModalMessage(message);
        setModalContent(content);
        setIsModalVisible(true);
    };

    const closeModal = () => {
        // setIsModalVisible(false);
        navigate("/otp")
    };

    const handleButtonClick = async (
        event: MouseEvent<HTMLButtonElement>
    ): Promise<void> => {
        event.preventDefault();
        setIsLoading(true);
        const values: IForgetPasswordRequest = {
            email: email,
        };

        if (isEmailValid) {
            try {
                const response = await authService.forgetPassword(values);
                // console.log("email response" + response)
                if (response) {
                    //   openModal(
                    //     "Recovery Email Sent",
                    //     "A recovery email has been sent to you. Please check your Email.",
                    //     ""
                    //   );
                    openModal(
                        "OTP Sent",
                        "A One-Time Password has been sent to you. Please check your Email.",
                        ""
                    );
                } else {
                    openModal(
                        "Error",
                        "An error occurred while sending the recovery email.",
                        "Okay"
                    );
                }
            } catch (error) {
                openModal(
                    "Error",
                    "An error occurred while sending the recovery email.",
                    "Okay"
                );
            } finally {
                setIsLoading(false);
            }
        }
    };

    function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
        const enteredEmail = event.target.value;
        setEmail(enteredEmail);
        dispatch(authActions.setResetPasswordEmail(enteredEmail));
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        setIsEmailValid(emailRegex.test(enteredEmail));
    }

    return (
        <AuthBackground>
            {isModalVisible && (
                <AuthModal
                    body={modalContent}
                    title={modalTitle}
                    message={modalMessage}
                    closeModal={closeModal}
                    isModalVisible={isModalVisible}
                    action={"Proceed to OTP page"}
                />
            )}
            <PageForm>
                <InnerPageFormDiv>
                    <h5 id={'forgotpassword'}>Forgot Password?</h5>
                    <p id={'yourAccount'}>Let's find your account!</p>
                    <label id={'email'}>Email</label>
                    <TextInput
                        text={email}
                        onTextChange={handleEmailChange}
                        imgSrc={mailEnvelope}
                    />
                </InnerPageFormDiv>

                <MobileButtonDiv>
                    <Button
                        text={"Continue"}
                        style={{backgroundColor: "#45a049", marginTop: "46px"}}
                        onClick={handleButtonClick}
                        loading={isLoading}
                    />

                    <FooterNote>
                        {" "}
                        <div id={'botherDiv'} className={`flex justify-center items-center mt-5`}>
                            <span id={'dontBotherSpan'}>Don't bother!</span>
                            <Link id={'link'} className={`text-[#45a049] font-bold`} to="/">
                                &nbsp; Login instead
                            </Link>
                        </div>
                    </FooterNote>
                </MobileButtonDiv>
            </PageForm>
        </AuthBackground>
    );
}

export default ForgetPassword;
