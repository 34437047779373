import React from "react";
import {Checkbox} from "@mui/material";

const AcceptLoanOffer = () => {

const bankSelections:string[] =["Opay", "PalmPay","Gtb", "UBA","KeyStone","Access", "Union", "Jaiz", "Fidelity", "Wema", "FCMB"];
    return (
        <div id={"bankDetailsDivMain"} className="p-4 md:p-5">
            <h3 id={"successBankDetailH"} className="mb-5">Bank Details</h3>
            <div id={"accountNumberDivOuter"} className=" flex gap-3">
                <div id={"accountNumberDiv"}>
                    <label id={"accountNumberLabel"} htmlFor="input2" className="text-gray-600 font-semibold">
                        Account Number
                    </label>
                    <input
                        type="text"
                        id="input2"
                        name="input2"
                        className="p-2 border rounded-md w-[300px]"

                    />
                </div>
                <div id={"bankNameDiv"}>
                    <label id={"bankNameLabel"} htmlFor="input2" className="text-gray-600 font-semibold">
                       Bank Name
                    </label>
                    <select
                        name="loan_product"
                        id="loan_product"
                        className="p-2 border rounded-md w-[300px]"
                    >
                        <option id={"valuedDisabledOption"} value="" disabled>
                            E.g UBA
                        </option>
                        {
                            bankSelections.map((bankSelect => (
                                <option id={`${bankSelect}option`} value="option1">{bankSelect}</option>
                            )))
                        }

                    </select>
                </div>
            </div>
          <div id={"defaultCheckedDiv"} className="flex ">
              <Checkbox id={"defaultCheckedCheckbox"} defaultChecked color="success" />
              <p id={"successAccept"} className="mt-2">I Accept LearnSpace <span className="text-[#0EAA4F]">Loan Terms & Conditions</span> </p>
          </div>


        </div>
    );
};

export default AcceptLoanOffer;
