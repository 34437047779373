import React, {useState} from 'react';
import TraineeForm from "../form/TraineeForm";
import styles from "../styles/Id.module.css";
import {Field, Form, Formik} from "formik";
import {Icon} from "@iconify/react";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import * as Yup from "yup";
import {FaCheck, FaMinus, FaPlus} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {FaTimes} from "react-icons/fa";
import traineeService from "../../../../services/trainee.service";
import {getUserAssociatedData} from "../../../../utils";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks/hooks";
import {traineeActions} from "../../../../redux/slices/traineeSlice";
import {AiOutlineExclamationCircle} from "react-icons/ai";
import {BsExclamationTriangleFill} from "react-icons/bs";
import {IoIosCloseCircleOutline} from "react-icons/io";
import {notes} from '../../../../utils/roles';
import utils from "../../../../utils/utils";

function IdentityVerification() {
    const [isNinLoading, setNinLoading] = useState(false);
    const [isBvnLoading, setBvnLoading] = useState(false);

    const [submitLoading, setSubmitLoading] = useState(false);

    const [isBvnInfoVisible, setIsBvnInfoVisible] = useState(false);
    const [isNinInfoVisible, setIsNinInfoVisible] = useState(false);

    const [isBvnValid, setIsBvnValid] = useState<boolean | undefined>(undefined);
    const [isNinValid, setIsNinValid] = useState<boolean | undefined>(undefined);


    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const note = useAppSelector(state => state.trainee.note);

    const validationSchema = Yup.object().shape({
        bvn: Yup.string()
            .min(11, "BVN must be 11 digits")
            .max(11, "BVN must be 11 digits")
            .required("BVN is required"),
        nin: Yup.string()
            .min(11, "NIN must be 11 digits")
            .max(11, "NIN must be 11 digits")
            .required("NIN is required"),
    });

    const initialValues = {
        bvn: "",
        nin: "",
    };

    const handleBackButton = () => {
        navigate('/loan-application');
    };

    const verifyBvn = async (values: any, setFieldError: any) => {
        const data = {
            // @ts-ignore
            bvnNumber: values.bvn,
            traineeId: getUserAssociatedData()?.trainee?.traineeId
        };
        try {
            setBvnLoading(true);
            const response = await traineeService.verifyBvn(data);
            response && setIsBvnValid(true);
            dispatch(traineeActions.setBvnResponse(response));
        } catch (err: any) {
            setIsBvnValid(false);
            setFieldError("bvn", err.response?.data.error);
            utils.handleRequestError(err)
        } finally {
            setBvnLoading(false);
        }
    }

    const verifyNin = async (values: any, setFieldError: any) => {
        const data = {
            // @ts-ignore
            ninNumber: values.nin,
            traineeId: getUserAssociatedData()?.trainee?.traineeId
        };
        try {
            setNinLoading(true);
            const response = await traineeService.verifyNin(data);
            response && setIsNinValid(true);
            dispatch(traineeActions.setNinResponse(response));
        } catch (err: any) {
            setIsNinValid(false);
            setFieldError("nin", err.response.data.error);
        } finally {
            setNinLoading(false);
        }
    }

    const handleSubmit = async () => {
        if (note === notes.TWO_TRIALS) {
            dispatch(traineeActions.setNote(notes.ONE_TRIAL));
        }
        if (note === notes.ONE_TRIAL) {
            dispatch(traineeActions.setNote(notes.NO_TRIAL));
        }
        isBvnValid === true &&
        navigate('/loan-application/confirm-id');
    };

    const toggleBvnInfo = () => {
        setIsBvnInfoVisible(!isBvnInfoVisible);
    };

    const toggleNinInfo = () => {
        setIsNinInfoVisible(!isNinInfoVisible);
    };

    return (
        <div id={`bjdjnksl`}>
            { note === notes.NO_TRIAL &&
                <div
                    id={`kdnjkvknfk`}
                    className={`w-[80%] border border-[#E80000] mx-auto bg-[#FFE6E6] rounded-[6px] p-[12px] mb-[56px]`}>
                    <div id={`dvnveojdfk`} className={`flex justify-center items-center gap-6 `}>
                        <BsExclamationTriangleFill className={`text-[#E80000]`}/>
                        <p id={`cskjdkndms`} className={`text-[#E80000]`}>You have exceeded your number of tries</p>
                        <div id={`cbudbyufdhj`} className={`cursor-pointer`}>
                            <IoIosCloseCircleOutline className={`text-[#E80000]`}/>
                        </div>
                    </div>
                </div>
            }
            <TraineeForm formName={`Identity Verification`}>
                <div id={`ldnjvikfjd`}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({errors, touched, setFieldError, values}) => (
                            <Form>
                                <div id={`jdkjnksoeaw`} className={`p-[28px]`}>
                                    <div id={`dbbiwoaw`} className={`${styles.field} relative`}>
                                        <label id={`cbkhdjbkve`}>Bank Verification Number</label>
                                        <div id={`bdijfkvjfdo`} className={`relative flex`}>
                                            <Field
                                                id={`bvn`}
                                                type="text"
                                                name="bvn"
                                                readOnly={note === notes.NO_TRIAL}
                                                placeholder="Enter your BVN"
                                                style={{
                                                    borderColor: note !==notes.NO_TRIAL && errors.bvn && touched.bvn ? "red" : (isBvnValid ? "green" : isBvnValid === false ? "red" : "inherit"),
                                                    paddingRight: '2.5em',
                                                }}
                                            />
                                            {isBvnValid ? <FaCheck color="green" className={styles.check}/>
                                                : isBvnValid === false ?
                                                    <FaTimes color="red" className={styles.times}/> : ""}
                                            <button
                                                id={`bvn-button`}
                                                className={`border ml-2 px-4 rounded-[4px] ${!errors.bvn && touched.bvn && styles.verifySuccess}`}
                                                onClick={(e) => {
                                                    e.preventDefault(); // Prevent the default button behavior
                                                    verifyBvn(values, setFieldError).then();
                                                }}
                                            >
                                                {isBvnLoading ? (
                                                    <div id={`ncsiljidkj`} className="flex items-center justify-center">
                                                        <Icon icon={loadingLoop} width={24} height={24}/>
                                                    </div>
                                                ) : (
                                                    "Verify"
                                                )}
                                            </button>

                                        </div>
                                        {note === notes.NO_TRIAL ? "" : (errors.bvn && touched.bvn && (
                                            <div id={`nlskdvlierkjd`} className={styles.error}>{errors.bvn}</div>))}
                                        {isBvnValid === true && (
                                            <div id={`lsjfdijkfno`} className={styles.successVerification}>BVN Verification
                                                Successful</div>)}

                                        <div id={`nskjdknlsore`} className={`mt-3 bg-[#FAFBFC] border rounded-[4px] p-2`}>
                                            <div className={`flex justify-between items-center text-[15px]`}
                                                 onClick={toggleBvnInfo}
                                                 id={`bvnInfoToggle`}
                                            >
                                                <p id={`nnfdigu`}>Why do we need your Bank Verification Number?</p>
                                                {isBvnInfoVisible ? <FaMinus className="cursor-pointer"/> :
                                                    <FaPlus className="cursor-pointer"/>}
                                            </div>
                                            {isBvnInfoVisible && (
                                                <p id={`bsdhjksd`} className={`md:w-[381px] mt-2 text-[14px] text-[#404653] w-full`}>
                                                    We request for your BVN to verify your identity and confirm that the
                                                    account you registered with is yours.
                                                    It is also a KYC requirement for all financial institutions by the
                                                    Central Bank of Nigeria (CBN).
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    {/*NIN*/}
                                    <div id={`jdbkbrkeed`} className={`${styles.field} relative`}>
                                        <label id={`cjhlkeoajndle`}>National Identity Number</label>
                                        <div id={`cuindkoejkdenoaeajk`} className={`relative flex`}>
                                            <Field
                                                id={`nin`}
                                                type="text"
                                                name="nin"
                                                readOnly={note === notes.NO_TRIAL}
                                                placeholder="Enter your NIN"
                                                style={{
                                                    borderColor: note !== notes.NO_TRIAL && errors.nin && touched.nin ? "red" : (isNinValid ? "green" : isNinValid === false ? "red" : "inherit"),
                                                    paddingRight: '2.5em',
                                                }}
                                            />
                                            {isNinValid ? <FaCheck color="green" className={styles.check}/>
                                                : isNinValid === false ?
                                                    <FaTimes color="red" className={styles.times}/> : ""}
                                            <button
                                                id={`nin-button`}
                                                className={`border ml-2 px-4 rounded-[4px] ${!errors.nin && touched.nin && styles.verifySuccess}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    verifyNin(values, setFieldError).then();
                                                }}
                                            >
                                                {isNinLoading ? (
                                                    <div id={`cnsdlsnl`} className="flex items-center justify-center">
                                                        <Icon icon={loadingLoop} width={24} height={24}/>
                                                    </div>
                                                ) : (
                                                    "Verify"
                                                )}
                                            </button>

                                        </div>
                                        {note === notes.NO_TRIAL ? "" : (errors.nin && touched.nin && (
                                            <div id={`lkjsdole`} className={styles.error}>{errors.nin}</div>))}
                                        {isNinValid === true && (
                                            <div id={`njdabhow`} className={styles.successVerification}>NIN Verification
                                                Successful</div>)}

                                        <div id={`chdbsviujhd`} className={`mt-3 bg-[#FAFBFC] border rounded-[4px] p-2`}>
                                            <div itemID={`djsalsasj`} className={`flex justify-between items-center text-[15px]`}
                                                 onClick={toggleNinInfo}
                                                 id={`ninInfoToggle`}
                                            >
                                                <p id={`jabhjeabhjs`}>Why do we need your National Identification Number?</p>
                                                {isNinInfoVisible ? <FaMinus className="cursor-pointer"/> :
                                                    <FaPlus className="cursor-pointer"/>}
                                            </div>
                                            {isNinInfoVisible && (
                                                <p id={`kndkkjsnirejnk`} className={`md:w-[381px] mt-2 text-[14px] text-[#404653] w-full`}>
                                                    We request for your NIN to verify your identity and confirm that the
                                                    account
                                                    you registered with is yours.
                                                    It is also a KYC requirement for all financial institutions by the
                                                    Central
                                                    Bank of Nigeria (CBN).
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id={`ajbcdujh`}
                                    className="mt-[20px] flex md:flex-row flex-col-reverse justify-between align-middle border border-b-3 p-4">
                                    <button
                                        id={`cnsjdvnkjd`}
                                        className={styles.back}
                                        onClick={handleBackButton}
                                    >
                                        Back
                                    </button>
                                    <button id={`cjnlksdl`} className={styles.continue} type="submit">
                                        {submitLoading ? (
                                            <div id={`hisdijk`} className="flex items-center justify-center">
                                                <Icon id={`ninidirejil`} icon={loadingLoop} width={24} height={24}/>
                                            </div>
                                        ) : (
                                            "Continue"
                                        )}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </TraineeForm>
            <div
                id={`ncdlkjsdif`}
                className={`mt-5 bg-white border border-[#D0D5DD] flex justify-center items-start px-[28px] py-[16px] rounded-[6px] md:w-[520px]`}>
                <div
                    id={`lnksd;sk`}
                    className={`w-[36px] h-[36px] bg-[#E7F7ED] rounded-[50%] mr-[15px] flex justify-center items-center flex-shrink-0`}>
                    <AiOutlineExclamationCircle className={`w-[24px] text-[#0D9B48]`}/>
                </div>
                <p id={`sjndlnsjkro`}>{`Please ensure that you input a valid BVN and NIN. ${note}`}</p>
            </div>
        </div>
    );
}

export default IdentityVerification;
