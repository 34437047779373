import React, {useEffect, useState} from "react";
import TableTop from "../../../../components/reusables/tableTop/TableTop";
import investorService from "../../../../services/investor.service";
import Layout from "../../../../components/reusables/layout/Layout";
import {Skeleton} from "@mui/material";
import InvestorDataTable from "../investorTable/index";
import AddInvestor from "../addInvestor/addInvestor";
import utils from "../../../../utils/utils";
import {store} from "../../../../redux/store";
import {investorActions} from "../../../../redux/slices/investorSlice";
import {useAppSelector} from "../../../../redux/hooks/hooks";


interface IProps {
}

function InvestorDashBoard(props: IProps) {
    const [skeletonLoader, setSkeletonLoader] = useState(false)
    const [openModal, setOpenModal] = React.useState(false);

    const investorData = useAppSelector(state => state.investor?.investor);

    const adjustedResponse = (originalResponse: { content: any[] }) => {
        return {
            ...originalResponse,
            content: originalResponse.content?.map(investor => ({
                investorId: investor.investorId,
                investorName: `${investor.firstName} ${investor.lastName}`,
                email: investor.email,
                phoneNumber: investor.phoneNumber,
                fundProductName: investor.fundProductName,
                amountInvested: utils.formatAmount(investor.amountInvested),
            }))
        };
    };

    const pageNumber = useAppSelector(state => state.table?.pageNumber)
    const fetchInvestorData = async () => {
        const data = {
            pageNumber: pageNumber,
            pageSize: 10
        }

        try {
            const response = await investorService.viewAllInvestor(data);
            const adjustedResponse2 = adjustedResponse(response);
            store.dispatch(investorActions.setInvestor(adjustedResponse2));
        } catch (error) {
            utils.handleRequestError(error)
        }

    }

    useEffect(() => {
        fetchInvestorData().then();
    }, [pageNumber, openModal]);

    return (
        <div id={'mainDiv'}>
            <Layout>
                <div id={'tableTopDiv'} className={`flex flex-col h-full py-[20px] px-[40px] w-screen md:w-auto`}>
                    <TableTop
                        tableTitle={"Investors"}
                        placeholder={"Find Investors"}
                        title={"Add Investor"}
                        setButtonNeeded={true}
                        buttonClick={async () => setOpenModal(true)}
                    />
                    <div id={'skeletonLoaderOuterDiv'} className="flex-1">
                        {
                            skeletonLoader
                                ?
                                <div id={'skeletonLoaderInnerDiv'} className={`flex flex-col gap-3`}>
                                    <Skeleton variant="rectangular" height={40}/>
                                    <Skeleton variant="rectangular" height={40}/>
                                    <Skeleton variant="rectangular" height={40}/>
                                    <Skeleton variant="rectangular" height={40}/>
                                    <Skeleton variant="rectangular" height={40}/>
                                </div> :
                                <InvestorDataTable investorsData={investorData}/>
                        }

                        <AddInvestor
                            openModal={openModal}
                            buttonText={"Add Investor"}
                            onClose={() => setOpenModal(false)}
                        />
                    </div>
                </div>
            </Layout>
        </div>


    );
}

export default InvestorDashBoard;
