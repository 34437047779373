import React, {useEffect, useState} from 'react';
import TraineeForm from "../../traineeFlow/form/TraineeForm";
import styles from "./LoanOfferDetails.module.css";
import {useNavigate} from "react-router-dom";
import TopBar from "../../../../components/reusables/layout/TopBar";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks/hooks";
import loanService from "../../../../services/loan.service";
import utils from "../../../../utils/utils";
import {loanActions} from "../../../../redux/slices/loanSlice";

function LoanOfferDetails() {

    const [isChecked, setChecked] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const handleBackButton = () => {
        navigate("")
    }

    const handleAcceptButton = ()=> {
        navigate("")
    }
    const handleCheckboxChange = () => {
        setChecked(!isChecked);
    };

    const id = useAppSelector(state => state.auth?.param?.flag);
    const offerDetails: any = useAppSelector(state => state.loan?.loanOfferDetails);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await loanService.loanOfferDetails(id);
                dispatch(loanActions.setLoanOfferDetails(response));
            } catch (error) {
                utils.handleRequestError(error);
            }
        };

        fetchData().then();
    }, [id]);


    const renderDetail = (label: string, value: any) => (
        <div id={'eurhjd'} className={``} key={label}>
            <div id={'ieujkds'}>
                <p id={'uerdfd'} className={styles.label}>{label}</p>
                <div id={'keyudhs'} className={styles.value}>{value || 'N/A'}</div>
            </div>
        </div>
    );

    const yourDetails = [
        {label: 'First Name', value: offerDetails?.firstName},
        {label: 'Last Name', value: offerDetails?.lastName},
        {label: 'Middle Name', value: offerDetails?.middleName},
        {label: 'Date of Birth', value: offerDetails?.dateOfBirth},
    ]

    const trainingDetails = [
        {label: 'Institute Name', value: offerDetails?.instituteName},
        {label: 'Program Name', value: offerDetails?.programName},
        {label: 'Cohort Name', value: offerDetails?.cohortName},
        {label: 'Cohort Start Date', value: offerDetails?.cohortStartDate},
    ]

    const loanDetails = [
        {label: 'First Name', value: offerDetails?.amountRequested},
        {label: 'Last Name', value: offerDetails?.amountApproved},
        {label: 'Middle Name', value: offerDetails?.interestRate},
        {label: 'Date of Birth', value: offerDetails?.tenor},
        {label: 'Payment Moratorium Perios', value: offerDetails?.moratorium}
    ]

    return (
        <>
            <TopBar/>
            <div id={"formName"} className={`w-[45%] my-8 mx-auto`}>
                <TraineeForm formName={`Loan Offer Details`}>
                    <div id={"traineeDive"} className={`p-6`}>
                        <div id={"detailNameYour"}>
                            <p id={"yourDetails"} className={styles.detailName}>YOUR DETAILS</p>
                            <div id={"detailDiv"} className={`${styles.detail}`}>
                                {yourDetails.map(({label, value}) => renderDetail(label, value))}
                            </div>
                        </div>

                        <div id={"trainingDetails"}>
                            <p id={"trainingDetailsTwo"} className={styles.detailName}>TRAINING DETAILS</p>
                            <div id={"divTrainView"} className={`${styles.detail}`}>
                                {trainingDetails.map(({label, value}) => renderDetail(label, value))}
                            </div>
                        </div>

                        <div id={"loanDetailsDiv"}>
                            <p id={"LoanDetailsPTag"} className={styles.detailName}>LOAN DETAILS</p>
                            <div id={"innerLoanDetails"} className={`${styles.detail}`}>
                                {loanDetails.map(({label, value}) => renderDetail(label, value))}
                            </div>
                        </div>

                        <div>

                            <div id={"acceptOuter"} className={`${styles.accept}`}>
                                <div id={"acceptOuter"}>
                                    <div id={"acceptTermsInputDiv"} className={`${styles.terms}`}>
                                        <input id={"inputCheckBox"} type="checkbox" checked={isChecked} onChange={handleCheckboxChange}/>
                                        <p id={'understoodMessage'} className={`ml-[10px]`}>
                                            I have read, understand and I agree with the <span
                                            className={`text-[#0D9B48] underline cursor-pointer`}> Loan Terms & Conditions </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={"divButton"} className="mt-[20px] flex justify-between align-middle border border-b-3 p-4">
                        <button
                            id={"handleBackButton"}
                            className={styles.decline}
                            onClick={handleBackButton}
                        >
                            Decline
                        </button>
                        <button
                            id={"handleAccept"}
                            className={isChecked ? styles.continue : styles.disabled}
                            disabled={!isChecked} onClick={handleAcceptButton}>
                            Accept
                        </button>
                    </div>
                </TraineeForm>
            </div>
        </>
    );
}

export default LoanOfferDetails;