import styled from "styled-components";

export const FooterNote = styled.p`
  //font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: green;
  text-align: right;

  @media (max-width: 769px) {
    font-size: 17px;
    margin-top: 10%;
  }
`;

export const MobileButtonDiv = styled.div`
  @media (max-width: 769px) {
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin-top: 17rem;
  }
`;

export const InnerPageFormDiv = styled.div`
  flex-direction: column;
  display: flex;
  

  @media (max-width: 769px) {
    position: relative;
    top: 10rem;
  }
`;
