import React, {useMemo, useState} from "react";
import Modal from "../../../../components/Modal";
import styles from "../addProgram/AddProgram.module.css";
import {GrClose} from "react-icons/gr";
import {Field, Form, Formik} from "formik";
import {Icon} from "@iconify/react";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import * as Yup from "yup";
import programService from "../../../../services/program.service";
import {IEditProgramRequest} from "../../../../types/program.type";
import {useAppSelector} from "../../../../redux/hooks/hooks";
import CustomDropdown from "../../../../components/reusables/customDropDown";
import {toast} from "react-toastify";
import errorIcon from "../../../userManagement/assests/error.png";
import {IAllInstitute} from "../../../../types/institute.type";
import {useDispatch} from "react-redux";
import utils from "../../../../utils/utils";

interface IProps {
    openModal: boolean;
    onClose?: () => void;
    buttonText?: string;
    text?: string;
    programId?: string;
    instituteId?: IAllInstitute;

}

interface DropdownState {
    deliveryType: boolean;
    programType: boolean;
    programDuration: boolean;
    programMode: boolean;
}

const validationSchema = Yup.object().shape({
    programName: Yup.string().required("Program Name is required"),
    deliveryType: Yup.string().required("Delivery Type is required"),
    programType: Yup.string().required("Program Type is required"),
    programDuration: Yup.string().required("Duration is required"),
    programMode: Yup.string().required("Program Mode is required"),
    programDescription: Yup.string().required("Program Description is required"),
});


function Index(props: IProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDeliveryType, setSelectedDeliveryType] = useState<string>("");
    const [selectedProgramType, setSelectedProgramType] = useState<string>("");
    const [selectedDuration, setSelectedDuration] = useState<string>("");
    const [selectedProgramMode, setSelectedProgramMode] = useState<string>("");

    const [dropdowns, setDropdowns] = useState<DropdownState>({
        deliveryType: false,
        programType: false,
        programDuration: false,
        programMode: false,
    });
    const toggleDropdown = (dropdownName: keyof DropdownState) => {
        setDropdowns((prevState) => ({
            ...prevState,
            [dropdownName]: !prevState[dropdownName],
        }));
    };

    const [isCreateProgramFailed, setIsCreateProgramFailed] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false); // Initial value set to false

    const handleDropdownSelect = (
         dropdownName: keyof DropdownState,
        selectedOption: string
    ) => {
        const capitalizedOption = selectedOption.toLocaleUpperCase();

        switch (dropdownName) {
            case "deliveryType":
                setSelectedDeliveryType(capitalizedOption);
                break;
            case "programType":
                setSelectedProgramType(capitalizedOption);
                break;
            case "programMode":
                setSelectedProgramMode(capitalizedOption);
                break;
            default:
                break;
        }
    };
    const dispatch = useDispatch();

    const storedProgram = useAppSelector((state)=> state.program.program);
    const programDetails = useMemo(() => storedProgram?.content?.find((p:any) => p.programId === props.programId), [storedProgram, props.programId]);
    const handleSubmit = async (values: any) => {
        const program: IEditProgramRequest = {  ...values,programId:props.programId,instituteId: props.instituteId};

        try {
            setIsLoading(true)
            const response = await programService.editProgram(
                program
            );
            if (response) {
                setIsCreateProgramFailed(false);
                handleModalClose();
                setIsEmpty(true);

            }
                toast.success("Program Updated successfully!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } catch (error) {
            utils.handleRequestError(error)
            setIsCreateProgramFailed(true);

            toast.error("Error Creating Program!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setIsLoading(false);
            props.onClose && props?.onClose();
        }
    };

    const handleModalClose = () => {
        props.onClose && props?.onClose();
    };

    const program1 = programDetails;
    return (
        <Modal isOpen={props.openModal} closeModal={handleModalClose}>
            <div id={'addProgramContainer'} className={styles.addProgramContainer}>
                <div id={'onCloseDiv'} className="flex justify-between p-7 border border-b-2"  onClick={props.onClose}>
                    <h2 id={'ProgramEditH'} className="font-bold">Edit Program</h2>
                    <GrClose/>
                </div>
                <div id={'formContainerDiv'} className={styles.formContainer}>

                    <Formik
                        initialValues={{
                            programName: "",
                            programType:  "",
                            programDuration: "",
                            deliveryType:  "",
                            programMode: "",
                            programDescription: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({errors, touched, setFieldValue}) => (
                            <Form>
                                <div id={'formSection'} className={styles.formSection}>
                                    <div id={'validationSchema'} className={styles.one}>
                                        <div id={'setFieldValue'} className={styles.field}>
                                            <label id={'ProgramNameLabel'}>Program Name</label>
                                            <Field
                                                type="text"
                                                name="programName"
                                                placeholder="E.g Design Thinking"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    const newValue = event.target.value;
                                                    setFieldValue("programName", newValue);
                                                  }}
                                                style={{
                                                    borderColor:
                                                        errors.programName && touched.programName
                                                            ? "red"
                                                            : "inherit",
                                                }}
                                            />
                                            {errors.programName && touched.programName && (
                                                <div id={'programNameError'} className={styles.error}>{errors.programName}</div>
                                            )}
                                        </div>

                                        <div id={'Delivery'} className={styles.field}>
                                            <label id={'DeliveryType'}>Delivery Type</label>
                                            <CustomDropdown
                                                options={["ONLINE", "ONSITE", "HYBRID"]}
                                                selectedOption={selectedDeliveryType ? selectedDeliveryType : program1?.deliveryType}
                                                onSelect={(option: string) =>
                                                    handleDropdownSelect("deliveryType", option)
                                                }
                                                isOpen={dropdowns.deliveryType}
                                                placeholder={"Select type"}
                                                toggleDropdown={() => toggleDropdown("deliveryType")}
                                                fieldName="deliveryType"
                                                setFieldValue={setFieldValue}
                                            />
                                            {errors.deliveryType && touched.deliveryType && (
                                                <div id={'touchedDeliveryTypeError'} className={styles.error}>
                                                    {errors.deliveryType}
                                                </div>
                                            )}
                                        </div>

                                        <div id={'CustomDropdownType'} className={styles.field}>
                                            <label id={'CustomDropdownLabel'}>Program Type</label>
                                            <CustomDropdown
                                                options={["PROFESSIONAL", "VOCATIONAL"]}
                                                selectedOption={selectedProgramType ? selectedProgramType : program1?.programType}
                                                onSelect={(option: string) =>
                                                    handleDropdownSelect("programType", option)
                                                }
                                                isOpen={dropdowns.programType}
                                                placeholder={"Select type"}
                                                toggleDropdown={() => toggleDropdown("programType")}
                                                fieldName="programType"
                                                setFieldValue={setFieldValue}
                                            />
                                            {errors.programType && touched.programType && (
                                                <div id={'programTypeErrorDiv'} className={styles.error}>{errors.programType}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div id={'DurationOuterDiv'} className={styles.one}>
                                        <div id={'DurationMonthInner'} className={styles.field}>
                                            <label id={'labelDurationMonth'}>Duration in Months</label>
                                            <Field
                                                type="number"
                                                name="programDuration"
                                                placeholder="E.g 1"
                                                style={{
                                                    borderColor:
                                                        errors.programName && touched.programName
                                                            ? "red"
                                                            : "inherit",
                                                }}
                                            />
                                            {errors.programDuration && touched.programDuration && (
                                                <div id={'programDurationDiv'} className={styles.error}>
                                                    {errors.programDuration}
                                                </div>
                                            )}
                                        </div>

                                        <div id={'modeProgramDropdown'} className={styles.field}>
                                            <label id={'modeProgramLabel'}>Program Mode</label>
                                            <CustomDropdown
                                                options={["PART_TIME", "FULL_TIME"]}

                                                selectedOption={selectedProgramMode ? selectedProgramMode : program1?.programMode}
                                                onSelect={(option: string) =>
                                                    handleDropdownSelect("programMode", option)
                                                }
                                                isOpen={dropdowns.programMode}
                                                placeholder={"Select type"}
                                                toggleDropdown={() => toggleDropdown("programMode")}
                                                fieldName="programMode"
                                                setFieldValue={setFieldValue
                                                }
                                                type="programMode"
                                            />

                                            {errors.programMode && touched.programMode && (
                                                <div id={'programModeDiv'} className={styles.error}>{errors.programMode}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div id={'textAreaSectionOuterDiv'} className={styles.textAreaSection}>
                                    <div id={'textAreaSectionInnerDiv'} className={styles.field}>
                                        <label id={'textAreaSectionDiscription'}>Program Description</label>
                                        <Field
                                            as="textarea"
                                            name="programDescription"
                                            placeholder="About this program"
                                            className={styles.textArea}
                                            style={{
                                                borderColor:
                                                    errors.programDescription &&
                                                    touched.programDescription
                                                        ? "red"
                                                        : "inherit",
                                            }}
                                        />
                                        {errors.programDescription &&
                                            touched.programDescription && (
                                                <div id={'programDescriptionError'} className={styles.error}>
                                                    {errors.programDescription}
                                                </div>
                                            )}
                                    </div>

                                    <div id={'buttonsDivCancel'} className={styles.buttons}>
                                        <button
                                            id={'cancelBtn'}
                                            className={styles.cancelBtn}
                                            onClick={props.onClose}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            id={'addProgramBtn'}
                                            className={styles.addProgramBtn}
                                            type="submit"
                                        >
                                            {isLoading ? (
                                                <div id={'loadingLoop'} className="flex items-center justify-center">
                                                    <Icon id={'loadingLoopIcon'} icon={loadingLoop} width={24} height={24}/>
                                                </div>
                                            ) : (
                                                props.buttonText
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
}

export default Index;