export const setSessionItem = (key: string, value: string): void => {
    return sessionStorage.setItem(key, value);
 };
 
 export const getSessionItem = (key: string): string | null => {
    return sessionStorage.getItem(key) || null;
 };
 
 export const clearData = (): void => {
     localStorage.clear();
     sessionStorage.clear();
 }
 
 export const clearUserDetails = (): void => {
    removeItem('userDetails');
    removeItem('token');
 };
 
 export const removeItem = (key: string): void => {
    return sessionStorage.removeItem(key);
 };
 
 export const storeUserToken = (token: string): void => {
    return setSessionItem('token', token);
 };
 
 export const getUserToken = (): string | null => {
    return getSessionItem('token');
 };
 