import React from "react";
import ViewTraining from "./ViewTraining";
import Layout from "../../../../components/reusables/layout/Layout";

const Index = () => {

    return (
        <div id={'mainContainer'}>
            <Layout page="View Training">
                <ViewTraining/>
            </Layout>
        </div>
    )


}
export default Index;