import React, {useLayoutEffect, useState} from "react";
import instituteService from '../../../../services/institute.service';
import {InstituteResponse} from "../../../../types/institute.type";
import {useAppSelector} from "../../../../redux/hooks/hooks";
import {useLocation, useParams} from "react-router-dom";
import {InstituteDetails} from "../ViewAllTrainingInstitute/InstituteData";
import {getInstituteId} from "../../../../utils";
import utils from "../../../../utils/utils";


const InstituteLoanDetails = () => {

    const [data, setData] = useState<InstituteResponse>({} as InstituteResponse)
    const pmInstituteId = useAppSelector(state => state.institute.instituteId);
    const instituteId = getInstituteId() ?? pmInstituteId;

    const fetchData = async () => {
        const requestBody: InstituteDetails = {instituteId: instituteId};
        try {
            const institutesResponse = await instituteService.getInstitute(requestBody,);
            setData(institutesResponse);
        } catch (error) {
            utils.handleRequestError(error);
        }
    };
    useLayoutEffect(() => {
        fetchData();
    }, [])

    return (
        <div  id={'mainContainer'} className="bg-white  p-5 pt-8 rounded-sm   grid grid-cols-4 grid-rows-2 gap-4 ml-5">
            <div id={'innerContainerRequests'}>
                <h2 id={'RequestsHTag'} className="font-sans font-[400] size-[14] text-[#72757A]">Loan Requests</h2>
                <p id={'RequestsPTag'} className="font-sans font-[500] size-[16] text-[#101828]">{data.totalHistoricalDebt || 0}</p>
            </div>
            <div id={'innerContainerOffer'}>
                <h2 id={'OffersHTag'} className="font-sans font-[400] size-[14] text-[#72757A]">Pending Loan Offers</h2>
                <p id={'OffersPTag'} className="font-sans font-[500] size-[16] text-[#101828]">{data.totalHistoricalDebt || 0}</p>
            </div>
            <div id={'PerformingInner'}>
                <h2 id={'PerformingHTag'} className="font-sans font-[400] size-[14] text-[#72757A]">Performing Loans</h2>
                <p id={'PerformingPTag'} className="font-sans font-[500] size-[16] text-[#101828]">{data.totalHistoricalDebt || 0}</p>
            </div>
            <div id={'Non-PerformingInner'}>
                <h2 id={'Non-PerformingHTag'} className="font-sans font-[400] size-[14] text-[#72757A]">Non-Performing Loans</h2>
                <p id={'Non-PerformingPTag'} className="font-sans font-[500] size-[16] text-[#101828]">{data.totalHistoricalDebt || 0}</p>
            </div>
            <div id={'HistoricalInner'}>
                <h2 id={'HistoricalHTag'} className="font-sans font-[400] size-[14] text-[#72757A]">Total Historical Debt</h2>
                <p id={'HistoricalPTag'} className="font-sans font-[500] size-[16] text-[#101828]">{data.totalHistoricalDebt || 0}</p>
            </div>
            <div id={'PaidInner'}>
                <h2 id={'PaidHTag'} className="font-sans font-[400] size-[14] text-[#72757A]">Total Amount Paid</h2>
                <p id={'PaidPTag'} className="font-sans font-[500] size-[16] text-[#101828]">{data.totalDebtRepaid || 0}</p>
            </div>
            <div id={'OutstandingInner'}>
                <h2 id={'OutstandingHTag'} className="font-sans font-[400] size-[14] text-[#72757A]">Total Outstanding</h2>
                <p id={'OutstandingPTag'} className="font-sans font-[500] size-[16] text-[#101828]">{data.totalCurrentDebt || 0}</p>
            </div>
            <div id={'MoratoriumInner'}>
                <h2 id={'MoratoriumHTag'} className="font-sans font-[400] size-[14] text-[#72757A]">Total In Moratorium</h2>
                <p id={'MoratoriumPTag'} className="font-sans font-[500] size-[16] text-[#101828]">{data.totalHistoricalDebt || 0}</p>
            </div>


        </div>
    );
};

export default InstituteLoanDetails;
