import {fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {store} from "../store";


// export const baseUrl= fetchBaseQuery({
//     baseUrl: process.env.REACT_APP_API_BASEURL
// })

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASEURL,
    prepareHeaders: (headers) => {
        const token = store.getState().auth.token?.access_token;

        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
            headers.set("Content-Type", "application/json-patch+json");
        }
        return headers;
    }
})
export const POST = (url: string, body: any) => ({
    url,
    method: "POST",
    body,
});