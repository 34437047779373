import React, {useLayoutEffect, useState} from "react";
import Modal from "../../../../components/Modal";
import styles from "./UpdateInstitute.module.css";
import {GrClose} from "react-icons/gr";
import {Icon} from "@iconify/react";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import {ErrorMessage, Field, Form, Formik, FormikValues} from "formik";
import * as Yup from "yup";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks/hooks";
import {getInstituteId, phoneRegExp} from "../../../../utils";
import {Toast, ToastTypes} from "../../../../components/Toast";
import utils from "../../../../utils/utils";
import instituteService from "../../../../services/institute.service";
import { instituteActions } from "../../../../redux/slices/institutesSlice";

interface IProps {
    open: boolean;
    close: () => void;
}

const UpdateInstitute = ({open, close}: IProps) => {

    const dispatch = useAppDispatch();

    const [isLoading, setLoading] = useState(false);
    const details = useAppSelector(state => state.institute.instituteDetails);

    const handleSubmit = async (formValues: FormikValues) => {
        setLoading(true);
        const values = {
            instituteId: getInstituteId(),
            ...formValues
        }

        try {
            const response = await instituteService.updateInstitute(values);
            if (response) {
                Toast({content: "Institute Details Updated Successfully", type: ToastTypes.SUCCESS});
                dispatch(instituteActions.setInstituteUpdate(true));
                window.location.reload();
                close();
            }
        } catch (err) {
            utils.handleRequestError(err);
        } finally {
            setLoading(false);
        }
    }

    const validationSchema = Yup.object().shape({
        instituteEmail: Yup.string().email("Invalid Email Address").nullable(),
        institutePhoneNumber: Yup.string().matches(phoneRegExp, "Invalid Phone Number").nullable(),
        instituteAddress: Yup.string().nullable(),
    })


    const initialValues = {
        instituteEmail: details?.instituteEmail ?? "",
        instituteAddress: details?.instituteAddress ?? "",
        institutePhoneNumber: details?.institutePhoneNumber ?? "",
    }


    return (
        <Modal isOpen={open}>
            <div id={'ModalMainDiv'} className={`py-[30px] px-[30px] border-b border-[#E7E8EA]`}>
                <div id={'ModalInnerHDiv'} className={`${styles.top}`}>
                    <h4 id={'editInstituteDetail'} className={`text-lg`}>Edit Institute Details</h4>
                    <button id={'editInstituteDetailButton'} className={``}
                            onClick={close}
                    >
                        <GrClose id={'GrClose'}/>
                    </button>
                </div>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({resetForm, dirty}) => (
                    <>
                        <Form className={styles.form}>
                            <div id={'formGroup'} className={styles.formGroup}>
                                <label id={'htmlForEmail'} htmlFor="id">Email Address</label>
                                <Field type="text" name="instituteEmail" placeholder=""/>
                                <ErrorMessage name="instituteEmail" component="div" className={styles.error}/>
                            </div>

                            <div id={'PhoneDiv'} className={styles.formGroup}>
                                <label id={'PhoneNumberLabel'} htmlFor="id">Phone Number</label>
                                <Field type="text" name="institutePhoneNumber" placeholder=""/>
                                <ErrorMessage name="institutePhoneNumber" component="div" className={styles.error}/>
                            </div>

                            <div id={'addressDiv'} className={styles.formGroup}>
                                <label id={'addressLabel'} htmlFor="id">Address</label>
                                <Field type="text" name="instituteAddress" placeholder=""/>
                                <ErrorMessage name="instituteAddress" component="div" className={styles.error}/>
                            </div>
                            <div
                                id={'discardDiv'}
                                className="mt-[20px] flex justify-between align-middle border border-b-3 py-4 px-[30px]">
                                <button
                                    id={'discardButton'}
                                    className={styles.discard}
                                    type="button"
                                    onClick={() => {
                                        resetForm({values: initialValues});
                                    }}
                                >
                                    Discard Changes
                                </button>

                                <button
                                    id={"saveButtonIsLoading"}
                                    className={!dirty ? styles.disabled : styles.save}
                                    type="submit"
                                    disabled={!dirty || isLoading}
                                >
                                    {isLoading ? (
                                        <div id={"isLoadingDiv"} className="flex items-center justify-center">
                                            <Icon id={"loadingLoopIcon"} icon={loadingLoop} width={24} height={24} />
                                        </div>
                                    ) : (
                                        "Save Changes"
                                    )}
                                </button>

                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </Modal>
    )
}

export default UpdateInstitute;