import {useEffect} from 'react';
import {redirect} from 'react-router-dom';
import {useAppDispatch, useAppSelector } from '../redux/hooks/hooks';
import {authActions} from "../redux/slices/authSlice";

function Redirect() {
    const {isAuthenticated} = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();

    const urlParams = new URLSearchParams(window.location.search);
    const path = urlParams.get('path');
    let flag: string | undefined = undefined;
    let count = 0;
    urlParams.forEach((value, key) => {
        count++;
        if (count === 2) {
            flag = value;
            return;
        }
    });

    const param = { path, flag };
    dispatch(authActions.setParam(param));

    useEffect(() => {
        if (isAuthenticated && path && flag) {
            redirect(`/${path}`);
        } else {
            redirect("/login");
        }
    }, [isAuthenticated, dispatch, path, flag]);


    return null;
}

export default Redirect;


