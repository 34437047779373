import React, {MouseEvent, useState} from "react";
import AuthBackground from "../../reuseables/background/AuthBackground";
import PageForm from "../../reuseables/form/Form";
import PasswordInput from "../../reuseables/password/PasswordInput";
import Button from "../../reuseables/button/Button";
import PasswordValidator from "../../reuseables/password/checker/PasswordValidator";
import {FooterNote, InnerPageFormDiv, MobileButtonDiv,} from "../forgetPassword/Index.style";
import {Link, useLocation, useNavigate} from "react-router-dom";
import authService from "../../../../services/auth.service";
import styles from "../createPassword/createPassword.module.css";
import utils from "../../../../utils/utils";
import jwtDecode from "jwt-decode";
import {authActions} from "../../../../redux/slices/authSlice";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks/hooks";



function CreatePassword() {
    const [password, setPassword] = useState("");
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [passwordConditionError, setPasswordConditionError] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const dispatch = useAppDispatch();


    const [confirmPassword, setConfirmPassword] = useState("");

    const handlePasswordChange = (value: string) => {
        setPassword(value);
        setIsPopoverOpen(true);
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const handleConfirmPasswordChange = (value: string) => {
        setConfirmPassword(value);
    };
    const passwordMatch = password === confirmPassword;
    const checkPasswordConditions = () => {
        const hasMinimumLength = password.length >= 8;
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        // const hasSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);
        const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>~`_\-/;]/g.test(password);
        const hasNumber = /\d/.test(password);
        const hasNoSpaces = /^\S*$/.test(password);

        return (
            hasMinimumLength &&
            hasUppercase &&
            hasLowercase &&
            hasSpecialCharacter &&
            hasNumber &&
            hasNoSpaces
        );
    };

    const userEmail = useAppSelector(state => state.auth?.resetPasswordEmail);
    let email: any;
    const token = searchParams.get("token");
    if(token) {
        const decoded: any = jwtDecode(token);
        const {sub} = decoded;
        email = sub;
    }else email = userEmail;



    const handleButtonClick = async (e: MouseEvent) => {
        e.preventDefault();

        if (!checkPasswordConditions()) return setPasswordConditionError("Password criteria not met!");
        else if (!passwordMatch) return setPasswordConditionError("Password does not match!");
        else setPasswordConditionError("");

        const createPasswordData: { password: string, token: string | null } = { password, token };
        const loginData: { email: string, password: string } = { email: email, password };

        try {
            setIsLoading(true);
            let response;
            if (token) {
                response = await authService.createPassword(createPasswordData);
            }else{
                response = await authService.reset_NewPassword(loginData);
            }
            if (response) {
                try {
                    const loginResponse = await authService.login(loginData);
                    if (loginResponse.access_token !== null) {
                        navigate("/");
                    }
                } catch (err: any) {
                    utils.handleRequestError(err);
                }
                setIsLoading(false);
            }
            dispatch(authActions.setResetPasswordEmail(""));
        } catch (err) {
            utils.handleRequestError(err);
        }finally{
            setIsLoading(false);
        }
    };



    return (
        <AuthBackground>
            <PageForm>
                <InnerPageFormDiv>
                    <div id={'createPasswordDivvhgvh'} className="flex flex-col pb-5">
                        <h5 id={'createPasswordHjhbvjhjbjh'}>Create Password?</h5>
                    </div>

                    {error && <div id={'errorMessagebjbjbj'} className={styles.errorMessage}>{error}</div>}
                    <label id={'errorMessagePasswordbjkjhkj'} className="my-0 font-medium text-sm text=[#344054]">
                        Password
                    </label>
                    <PasswordInput
                        value={password}
                        onChange={handlePasswordChange}
                        showPassword={showPassword}
                        togglePasswordVisibility={togglePasswordVisibility}
                        componentId={`ubcdjhks`}
                    >
                        <PasswordValidator
                            password={password}
                            isOpen={isPopoverOpen}
                            setIsOpen={setIsPopoverOpen}
                        />
                    </PasswordInput>
                    <label id={'confirmPasswordLabeljhbhbjhb'} className=" font-medium text-sm text=[#344054] mt-[10px]">
                        Confirm Password
                    </label>
                    <PasswordInput
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        showPassword={showPassword}
                        togglePasswordVisibility={togglePasswordVisibility}
                        componentId={`acjsi`}
                    ></PasswordInput>
                </InnerPageFormDiv>
                <MobileButtonDiv>
                    <Button
                        text={"Create Password"}
                        style={{
                            backgroundColor: "#45a049",
                            marginTop: "3rem",
                        }}
                        onClick={(e: MouseEvent) => handleButtonClick(e)}
                        loading={isLoading}
                    />
                    <Link id={`nvsjkdlsj`} to="/create-password-success"></Link>
                    <FooterNote>{""}</FooterNote>
                    <p id={'passwordConditionError'} className={`!text-center !text-[12px] !text-red-900`}>{passwordConditionError}</p>
                </MobileButtonDiv>
            </PageForm>

        </AuthBackground>
    );
}

export default CreatePassword;
