import {
    IAcceptInvite,
    IForgetPasswordRequest,
    IForgetPasswordResponse,
    ILoginRequest,
    IPasswordRequest,
    ITokenResponse,
    TokenPayload,
} from "../types/auth.type";
import {store} from "../redux/store";
import {authActions} from "../redux/slices/authSlice";
import jwt from "jwt-decode";
import ApiInstance from "./api";
import apiRequest from "./apiRequest.service";


class AuthService {
    private apiInstance: ApiInstance;

    constructor(token?: string) {
        this.apiInstance = new ApiInstance();
    }


    public async login(data: ILoginRequest): Promise<ITokenResponse> {
        try {
            const response = await this.apiInstance.getInstance().post("/auth/login", data);
            sessionStorage.setItem("access_token", response.data.access_token)
            if (response.data.access_token !== null) {
                await this.performLogin(response.data);
                await this.grabUserAssociatedData();
            }
            return response.data;
        } catch (err) {
            throw err;
        }
    }


    public async forgetPassword(data: IForgetPasswordRequest): Promise<IForgetPasswordResponse> {
        try {
            const response = await this.apiInstance.getInstance().post("/auth/forget-password", data);
            return response.data;
        } catch (err: any) {
            throw err;
        }
    }

    public async resetPassword(data: IPasswordRequest): Promise<IForgetPasswordResponse> {
        const apiInstanceWithTokens = new ApiInstance(data.authorization, data.authentication);
        try {
            const response = await apiInstanceWithTokens.getInstance().put("/api/v1/users/password");
            return response.data;
        } catch (err: any) {
            throw err;
        }
    }

    public async createPassword(data: IAcceptInvite) {
        const api = new ApiInstance();
        try {
            const response = await api.getInstance().post("/auth", data)
            return response.data;
        } catch (err: any) {
            throw err;
        }
    }



    public logout() {
        const api = new ApiInstance(sessionStorage.getItem("token"))
        api.getInstance().post("/auth/logout")
            .then(() => {
                //Do Nothing
            })
            .catch((err) => {
                // Handle any error, or just ignore it
            })
            .finally(() => {
                store.dispatch(authActions.logout());
            });
    }

    public async validateOtp(data: any){
        // return apiRequest.request(`auth/validate-otp`, "post", data);
        try {
            const response = await this.apiInstance.getInstance().post("/auth/validate-otp", data)
            return response.data;
        } catch (err: any) {
            throw err;
        }
    }

    public async reset_NewPassword(data: any){
        // return apiRequest.request(`auth/reset`, "post", data);
        try {
            const response = await this.apiInstance.getInstance().post("/auth/reset", data)
            return response.data;
        } catch (err: any) {
            throw err;
        }
    }

    public async resendEmail(data: any){
        // return apiRequest.request(`auth/resend-password-email`, "post", data);
        try {
            const response = await this.apiInstance.getInstance().post("/auth/resend-password-email", data)
            return response.data;
        } catch (err: any) {
            throw err;
        }
    }

    public getUserDetails(token: string) {
        try {
            const decodedToken = jwt(token) as TokenPayload;
            store.dispatch(authActions.setUserDetails(decodedToken));
        } catch (error) {
            // console.log("An Error Occurred")
        }
    }

    public async performLogin(token: ITokenResponse) {
        if (token?.access_token !== null) {
            store.dispatch(authActions.login(token));
            this.getUserDetails(token?.access_token)
        } else {
            this.logout();
        }
    }

    public async revalidate() {
        // @ts-ignore
        const tokenResponse = store.getState().auth.token;
        if (tokenResponse) await this.performLogin(tokenResponse as ITokenResponse);
    }

    private async grabUserAssociatedData() {
        // @ts-ignore
        const user = store.getState().auth.user
        const email = user?.email;
        const patten = /^[A-Z_]+$/
        //@ts-ignore
        const filteredRoles = user?.realm_access?.roles?.filter(v => patten.test(v))
        // const role = user?.realm_access?.roles[0];
        const role = filteredRoles?.length ? filteredRoles[0] : undefined;
        if (!!user) {
            await authService.userAssociatedData({email, role});
        }
    }

    private async userAssociatedData(data: any) {
        try {
            // @ts-ignore
            const api = new ApiInstance(store.getState().auth.token?.access_token);
            const response = await api.getInstance().post("/user/info", data);
            store.dispatch(authActions.setAssociatedData(response.data));
        } catch (error) {
            // console.log(error)
        }
    }
}

const authService = new AuthService();
export default authService;
