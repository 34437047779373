import React, {useEffect, useState} from 'react';
import utils from "../../../../../utils/utils";
import instituteService from "../../../../../services/institute.service";
import {useAppSelector} from "../../../../../redux/hooks/hooks";
import styles from "./InstituteAdmins.module.css";

interface UserData {
    adminFirstName: string;
    adminLastName: string;
    adminEmail: string;
    status: string;
}

const InstituteAdmins: React.FC = () => {
    const [userData, setUserData] = useState<UserData[]>([]);
    const instituteId = useAppSelector(state => state.institute.instituteId)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await instituteService.viewAllInstituteAdmins({instituteId});
                const data = response?.content || [];
                setUserData(data);
            } catch (error) {
                utils.handleRequestError(error);
            }
        };

        fetchData().then();
    }, [instituteId]);

    function formatUserData(data: UserData[]): { name: string; adminEmail: string; status: string }[] {
        return data.map(item => ({
            name: `${item.adminFirstName} ${item.adminLastName}`,
            adminEmail: item.adminEmail,
            status: utils.toSentenceCase(item.status),
        }));
    }

    const formattedUserData = formatUserData(userData);

    const getStatusStyles = (status: string) => {

        let bgColor = "";
        let textColor = "";

        switch (status) {
            case "Inactive":
                bgColor = "#FBF9E7";
                textColor = "#9A8A0A";
                break;
            case "Active":
                bgColor = "#E7F7ED";
                textColor = "#0D9B48";
                break;
            case "Deactivated":
                bgColor = "#FFE6E6";
                textColor = "#AF2C05";
                break;
            default:
                break;
        }

        return { backgroundColor: bgColor, color: textColor };
    };

    return (
        <div id={'acmd'}>
            <p id={'acdsl'} className={`font-bold text-[25px] mx-8 my-5`}>Institute Admins</p>
            <div id={'scews'}>
                <table
                    id={'acowea'}
                    className={`w-full mx-8 my-5 text-left ${styles.table}`}
                    style={{width: "-webkit-fill-available", borderCollapse: "collapse"}}
                >
                    <thead id={'acjnwkds'} className={` bg-white mb-[12px]`}>
                    <tr id={'cadscew'} className={`border rounded-[10px]`}>
                        <th id={'oeiod'} className={styles.f_th}></th>
                        <th id={'ewpjfkd'} className="px-4 py-2">Name</th>
                        <th id={'acedl'} className="px-4 py-2">Email Address</th>
                        <th id={'casdlp'} className="px-4 py-2">Status</th>
                    </tr>
                    </thead>
                    <tbody id={'acowee'} className={`border bg-white`}>
                    {formattedUserData.map((user, index) => (
                        <tr id={'oepjdc'} key={index} className={``}>
                            <td id={'nckend'}></td>
                            <td id={'caodsnj'} className="px-4 py-2" style={{ width: "30%" }}>{user.name}</td>
                            <td id={'coikd'} className="px-4 py-2">{user.adminEmail}</td>
                            <td id={'ewoicd'} className="px-4 py-2 ">
                                <div id={'acoidsk'} className={`inline-block min-w-min py-1 px-4 rounded-[16px]`} style={getStatusStyles(user.status)}>{user.status}</div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default InstituteAdmins;
