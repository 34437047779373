import React, {useMemo, useState} from "react";

import {useAppSelector} from "../../../../redux/hooks/hooks";
import utils from "../../../../utils/utils";

interface IViewProgramProps {
  programId: string;
}

const ViewProgramIndex: React.FC<IViewProgramProps> = (props) => {
  const storedProgram = useAppSelector((state)=> state.program.program);
  const programDetails = useMemo(() => storedProgram?.content.find((p:any) => p.programId === props.programId), [storedProgram, props.programId]);
  console.log("Program details here",  props.programId)
  console.log("Program d here",  programDetails)


  return (
      <div id={'oweio'} className="  bg-white p-[10px]">
        <div id={'jerhjds'} className="flex justify-between p-5 ">
          <h1 id={'hjds'} className="text-lg font-bold">Program Details</h1>
        </div>
        <div id={'ahdj'} className="flex flex-col w-full">
          <div id={'fdhkj'} className="grid grid-cols-4 gap-10  mt-8 mx-5">
            <div id={'oihed'} className="">
              <h2 id={'cjhsdkl'} className="text-base font-medium text-[#72757A]">
                Program Name
              </h2>
              <p id={'piejd'} className="font-bold text-lg">{programDetails?.programName}</p>
            </div>
            <div id={'pierdk'} className="">
              <h2 id={'peuhdsjk'} className="text-base font-medium text-[#72757A]">
                Duration{" "}
              </h2>
              <p id={'poiepwhf'} className="font-bold text-lg">
                {`${programDetails?.programDuration} ${programDetails?.durationStatus}`}
              </p>
            </div>
            <div id={'pweuhfdj'} className=" ">
              <h2 id={'oehfd'} className="text-base font-medium text-[#72757A]">
                Program Type{" "}
              </h2>
              <p id={'pquwiejm'} className="font-bold text-lg">{programDetails?.programType}</p>{" "}
            </div>
            <div id={'weydh'} className="">
              <h2 id={'oqyge'} className="text-base font-medium text-[#72757A]">
                Program Mode{" "}
              </h2>
              <p id={'oqukd'} className="font-bold text-lg">{programDetails?.programMode}</p>
            </div>

            <div id={'uqywtekhj'} className="">
              <h2 id={'iewyhjn'} className="text-base font-medium text-[#72757A]">
                Program Delivery Type{" "}
              </h2>
              <p id={'ygdhs'} className="font-bold text-lg">
                {programDetails?.deliveryType}
              </p>
            </div>
            <div id={'iqygevh'} className=" ">
              <h2 id={'qiughdns'} className="text-base font-normal text-[#72757A]">
                Number Of Cohorts{" "}
              </h2>
              <p id={'tqwveghdbsh'} className="font-bold text-lg">
                {programDetails?.numberOfCohort}
              </p>
            </div>
            <div id={'ouqegfhjd'} className=" ">
              <h2 id={'oudio'} className="text-base font-medium text-[#72757A]">
                Number Of Trainees{" "}
              </h2>
              <p id={'efkdj'} className="font-bold text-lg">
                {programDetails?.numberOfTrainees}
              </p>
            </div>
            <div id={'qgehjdn'} className=" ">
              <h2 id={'pieuhrufd'} className="text-base font-medium text-[#72757A]">
                Program Status{" "}
              </h2>
              <p id={'uieod'} className="font-bold text-lg">
                {programDetails?.programStatus}
              </p>
            </div>

            <div id={'iewrd'} className="">
              <h2 id={'owue'} className="text-base font-medium text-[#72757A]">
                Total Amount Disbursed{" "}
              </h2>
              <p id={'wehdj'} className="font-bold text-lg">
                {!!programDetails?.amountDisbursed ? utils.formatAmount(programDetails?.amountDisbursed) : "₦0.00"}
              </p>
            </div>
            <div id={'uhed'} className=" ">
              <h2 id={'hfjdm'} className="text-base font-medium text-[#72757A]">
                Total Amount Repaid{" "}
              </h2>
              <p id={'eoirufdjm'} className="font-bold text-lg">
                {!!programDetails?.amountRepaid ? utils.formatAmount(programDetails?.amountRepaid) : "₦0.00"}
              </p>
            </div>
            <div id={'uahdjhskjd'} className=" ">
              <h2 id={'uyergdhbg'} className="text-base font-medium text-[#72757A]">
                Total Outstanding{" "}
              </h2>
              <p id={'hvjedg'} className="font-bold text-lg">
                {!!programDetails?.outstandingAmount ? utils.formatAmount(programDetails?.outstandingAmount) : "₦0.00"}
              </p>
            </div>
            <div id={'uejkds'} className=" ">
              <h2 id={'pqeui'} className="text-base font-medium text-[#72757A]">
                Repayment Rate{" "}
              </h2>
              <p id={'quiwe'} className="font-bold text-lg">
                {programDetails?.repaymentPlan || "N/A"}
              </p>
            </div>
            <div id={'quyew'} className=" ">
              <h2 id={'quewew'} className="text-base font-medium text-[#72757A]">
                Debt Percentage{" "}
              </h2>
              <p id={'ouiqw'} className="font-bold text-lg">
                {" "}
                {programDetails?.debtPercentage || "N/A"}
              </p>
            </div>

            <div id={'quetw'} className="">
              <h2 id={'yerwe'} className="text-base font-medium text-[#72757A]">
                Average Starting Income{" "}
              </h2>
              <p id={'qwiuey'} className="font-bold text-lg">
                {programDetails?.averageStartingIncome || "N/A"}
              </p>
            </div>
            <div id={'oqpueiw'} className=" ">
              <h2 id={'iuerf'} className="text-base font-medium text-[#72757A]">
                Completion Rate{" "}
              </h2>
              <p id={'wieryu'} className="font-bold text-lg">
                {programDetails?.completionRate || "N/A"}
              </p>
            </div>
            <div id={'uywerf'} className=" ">
              <h2 id={'uiyewhd'} className="text-base font-medium text-[#72757A]">
                Employment Rate{" "}
              </h2>
              <p id={'hdsj'} className="font-bold text-lg">
                {programDetails?.employmentRate || "N/A"}
              </p>
            </div>
          </div>

          <div id={'yqtewghjdg'} className="justify-center  items-center mt-8 mx-5">
            {
              <div id={'wlrjek'} className=" ">
                <h2 id={'wiuehd'} className=" font-medium text-sm text-[#72757A]">
                  Program Description{" "}
                </h2>
                <p id={'owureir'} className="font-medium text-lg">
                  {programDetails?.description}
                </p>
              </div>
            }
          </div>
        </div>
             
     </div>
  );
}

export default ViewProgramIndex;
