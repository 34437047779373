import React from 'react';
import TableTop from "../../../../components/reusables/tableTop/TableTop";
import Layout from "../../../../components/reusables/layout/Layout";
import InvestmentDataTable from "./InvestmentDataTable";
import BreadCrumbs from "../../../../components/breadCrumb/BreadCrumbs";

function Index() {

    return (
        <Layout>
            <div id={'TableTopOuterDiv'} className="flex flex-col h-full py-[20px] px-[40px]">
                <BreadCrumbs/>
                <TableTop
                    placeholder={"Find Investment"}
                    setButtonNeeded={false}
                    tableTitle={'Investments'}
                />
                <div id={'investmentDataTable'} className="flex-1 mt-5">
                    <InvestmentDataTable/>
                </div>
            </div>
        </Layout>
    );
}

export default Index;