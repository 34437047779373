import React, {useState} from 'react';
import TraineeForm from "../form/TraineeForm";
import styles from "../styles/LoanApplicationDetails.module.css";
import {Icon} from "@iconify/react";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import Success from "../successModal/Success";
import traineeService from "../../../../services/trainee.service";
import utils from "../../../../utils/utils";
import {getUserAssociatedData} from "../../../../utils";
import {useAppSelector} from "../../../../redux/hooks/hooks";

function ConfirmApplicationDetails() {
    const [isChecked, setChecked] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleCheckboxChange = () => {
        setChecked(!isChecked);
    };
    const additionalInfo = useAppSelector(state => state.trainee.additionalInformation)

    const data = {
        traineeId: getUserAssociatedData()?.trainee?.traineeId,
        alternatePhoneNumber: additionalInfo?.alternatePhoneNumber,
        alternateEmail: additionalInfo?.alternateEmailAddress,
    }

    console.log("Trainee Data", data);
    const loanReferralId = sessionStorage.getItem("loanReferralId")
    const handleBtnClick = async()=> {
        try {
            setLoading(true);
            const response = await traineeService.completeLoanApplication(data);
            if (response) {
                try {
                    const complete = await traineeService.confirmLoanApplication({loanReferralId})
                    complete && setOpen(true);
                }catch(error: any){
                    utils.handleRequestError(error)
                }
            }
        } catch (err:any) {
            utils.handleRequestError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <TraineeForm formName={`Confirm Application Details`}>
            <div id={`nocijkd`} className={`flex gap-4 items-start p-6`}>
                <input
                    id={`vahuieoad`}
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className={`h-[29px] rounded-[4px]`}
                    style={{ borderRadius: '4px' }}
                />

                <p id={`cobhdfjfr`} className={`w-[420px]`}>
                    By ticking the box and clicking ‘Confirm’, you acknowledge that all the information entered is accurate to the best of your knowledge.
                    This includes personal details, financial information, and any supporting documentation submitted.
                </p>
            </div>
            <div id={`sjbdrjenkmf`} className="mt-[20px] flex justify-end align-middle border border-b-3 p-2">
                <button id={`sijdbnivrkfml`} className={isChecked ? styles.continue : styles.disabled} onClick={handleBtnClick} disabled={!isChecked}>
                    {isLoading ? (
                        <div id={`vsidinjdv`} className="flex items-center justify-center">
                            <Icon id={`dblkdnfk`} icon={loadingLoop} width={24} height={24}/>
                        </div>
                    ) : (
                        "Confirm"
                    )}
                </button>
            </div>
            <Success open={open}/>
        </TraineeForm>
    );
}

export default ConfirmApplicationDetails;