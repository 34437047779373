import React from "react";
import {ButtonDiv} from "./Button.style";
import {Icon} from "@iconify/react";
import loadingLoop from "@iconify/icons-line-md/loading-loop";

interface IProps {
  text: string;
  type?: string;
  style?: React.CSSProperties;

  onClick?: any;
  disabled?: boolean;
  loading?: boolean;
  className?: any;
  clickable?: any;
}

const Button: React.FC<IProps> = ({
  text,
  style = {},
  onClick,
  disabled = false,
  loading = false,
  className,
}) => {
  return (
    <ButtonDiv
        id={'ButtonDiv'}
      style={style}
      onClick={onClick}
      disabled={disabled || loading}
      className={className}
    >
      {loading ? (
        <div id={'loadingLoop'} className="flex items-center justify-center">
          <Icon id={'icon'} icon={loadingLoop} width={24} height={24} />
        </div>
      ) : (
        text
      )}
    </ButtonDiv>
  );
};

export default Button;
