import React, {useLayoutEffect, useState} from "react";
import ProgramDataTable from "../programTable";
import Skeleton from "@mui/material/Skeleton";
import {useAppSelector} from "../../../../redux/hooks/hooks";
import programService from "../../../../services/program.service";
import utils from "../../../../utils/utils"
import LoadingLoop from "../../../../components/loadingLoop/LoadingLoop";

function Index() {
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const instituteId = useAppSelector(state => state.institute.instituteId)
    const pageNumber = useAppSelector(state => state.table?.pageNumber)
    const data = {instituteId, pageNumber};

    function formatProgram(data: { content: any[] | null | undefined }) {
        return {
            ...data,
            content: Array.isArray(data?.content) ? data.content.map(item => ({
                ...item,
                programDuration: `${item.programDuration} ${item.durationStatus}`,
                amountDisbursed: utils.formatAmount(item.amountDisbursed),
                amountRepaid: utils.formatAmount(item.amountRepaid),
                outstandingAmount: utils.formatAmount(item.outstandingAmount),
            })) : <LoadingLoop/>
        };
    }

    const programData = useAppSelector(state => state.program?.program);
    const ProgramTableData = formatProgram(programData!);


    const fetchProgramData = async () => {
        if (!!instituteId) {
            setSkeletonLoader(true)
            const response = await programService.viewAllProgram(data).catch((error) => {
                if (error.message === "Request failed with status code 401") {
                    error.message = "You are currently not allowed/unauthorized to view this page."
                }
                utils.handleRequestError(error);
            })
            setSkeletonLoader(false)
        }
    };
    useLayoutEffect(() => {
        fetchProgramData().then();
    }, [pageNumber])

    return (
            <>
                {/*<Layout>*/}
                <div id={'placeholderTitle'} className={`flex flex-col h-full py-[20px] px-[40px] w-screen md:w-auto`}>
                    <div id={'skeletonLoaderDiv'} className="flex-1">
                        {skeletonLoader ?
                            <div id={'SkeletonDiv'} className={`flex flex-col gap-3`}>
                                <Skeleton variant="rectangular" height={40}/>
                                <Skeleton variant="rectangular" height={40}/>
                                <Skeleton variant="rectangular" height={40}/>
                                <Skeleton variant="rectangular" height={40}/>
                                <Skeleton variant="rectangular" height={40}/>
                            </div> :
                            <ProgramDataTable programData={ProgramTableData}/>
                        }
                    </div>

                </div>
            </>

    );
}

export default Index;