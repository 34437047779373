import {TableData} from "../commons.type";


export interface viewAllCohortDetailsInProgram {
    cohortName: string;
    numberOfTrainees: number;
    startDate: Date;
    endDate: Date;
    tuitionAmount: number,
    totalAmountRequested: number;
    totalAmountReceived: number;
    totalAmountOutstanding: number;
}

export interface ICreateCohort {
    name: string;
    description: string;
}

export interface CohortData {
    cohortLoanDetailResponse: CohortLoanDetails;
    startDate?: Date;
    endDate?: Date;
    name?: string;
    tuition?: any;
    dropouts?: number
    cohortId?: string;
    programName?: string;
}

export interface CohortLoanDetails {
    cohortId?: string;
    totalAmountRequested: number;
    totalAmountDisbursed: number;
    totalAmountRepaid: number;
    totalOutstanding: number;
    repaymentPercentage?: number;
}

// export interface CohortDetails {
//     programName: string;
//     cohortName: string;
//     name: string;
//     numberOfTrainees: number;
//     cohortStartDate: Date | null;
//     tuitionAmount: number | null;
//     totalAmountRequested: number | null;
//     totalAmountReceived: number | null;
//     totalAmountOutstanding: number | null;
//     cohortEndDate: Date | null;
//     repaymentPercentage: number;
//     employmentRate: number;
//     totalAmountRepaid: number | null;
//     cohortDropouts: number;
// }

export interface CohortDetails {
    cohortLoanDetailResponse: {
        cohortId: string,
        totalAmountRequested: number | null,
        totalAmountDisbursed: number | null,
        totalOutstanding: number | null,
        totalAmountRepaid: number | null,
        repaymentPercentage: number,
    }
    programName: string;
    name: string;
    numberOfTrainee: number;
    startDate: Date | null;
    tuition: number | null;
    endDate: Date | null;
    // employmentRate: number;
    dropouts: number;
}


export const emptyCohortData: TableData<CohortData> = {
    pageNo: 1,
    pageSize: 10,
    totalElement: 0,
    total: 0,
    last: true,
    content: [],
}

// export const CohortEmptydata: CohortDetails = {
//     programName: '',
//     // cohortName: '',
//     name: '',
//     numberOfTrainees: 0,
//     cohortStartDate: null,
//     tuitionAmount: null,
//     totalAmountRequested: null,
//     totalAmountReceived: null,
//     totalAmountOutstanding: null,
//     cohortEndDate: null,
//     repaymentPercentage: 0.0,
//     employmentRate: 0.0,
//     totalAmountRepaid: null,
//     cohortDropouts: 0,
// };

export const CohortEmptyData: CohortDetails = {
    cohortLoanDetailResponse: {
        cohortId: "",
        totalAmountRequested: null,
        totalAmountDisbursed: null,
        totalOutstanding: null,
        totalAmountRepaid: null,
        repaymentPercentage: 0,
    },
    programName: "",
    name: "",
    numberOfTrainee: 0,
    startDate: null,
    tuition: null,
    endDate: null,
    // repaymentPercentage: 0,
    // employmentRate: 0,
    dropouts: 0,
}
export const emptyData: CohortData = {
    cohortLoanDetailResponse: {
        cohortId: "",
        totalAmountRequested: 0,
        totalAmountDisbursed: 0,
        totalAmountRepaid: 0,
        totalOutstanding: 0,
        repaymentPercentage: 0,
    },
    startDate: new Date(),
    endDate: new Date(),
    name: "",
    tuition: null,
    dropouts: 0,
    cohortId: "",
    programName: "",
}

interface cohortLoanDetailResponse {
    cohortId: string,
    totalAmountRequested: null,
    totalAmountDisbursed: null,
    totalOutstanding: null,
    totalAmountRepaid: null,
    repaymentPercentage: 0,
}


export interface ids {
    instituteId?: string | undefined;
    programId?: string | undefined;
    cohortId?: string | undefined;
}


export interface CohortRequest {
    instituteId: string;
    programId: string;
    tuition: number;
    description: string;
    name: string;
    startDate: string;
    loanBreakdowns?: any[];
    endDate: string;

}


export interface CreateCohortResponse {
    cohortName: string;
    description: string;
    tuition: number;
    programName: string;
    startDate: string;
    endDate: string;


}


export const emptyViewAllCohortPage: TableData<viewAllCohortDetailsInProgram> = {
    pageNo: 1,
    pageSize: 10,
    totalElement: 0,
    total: 0,
    last: true,
    // content: viewCurrentCohortMock,
    content: [],
}
