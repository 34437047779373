import {styled} from "styled-components";

export const ModalParagraph = styled.p`
  color: black;
  font-family: "IBM Plex Sans",sans-serif;
`;

export const ModalTitle = styled.h5`
  font-style: normal;
  font-weight: bolder;
  font-size: 1.2rem;
  line-height: 27px;
  color: #101828;

  @media (max-width: 768px) {
    font-size: 0.5rem;
  }
`;

export const ModalButton = styled.button`
  width: 190px;
  height: 38px;
  padding: 0.5rem;
  border-radius: 20px;
  outline: none;
  border: none;
  background-color: white;
  color: green;

  @media (max-width: 768px) {
    width: 120px;
    height: 32px;
    padding: 0.5px;
    font-size: 0.7rem;
  }
`;
