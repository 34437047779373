import styles from "../../../../components/reusables/layout/styles/TopBar.module.css";
import dummyProfileImage from "../../../../features/dashboard/assets/images/dummyProfileImage.svg"
import {GiHamburgerMenu} from "react-icons/gi";
import {IoIosArrowDown} from "react-icons/io";
import {FiBell} from "react-icons/fi";
import learnSpaceLogo from "../../../../features/dashboard/assets/images/learnspaceLogo.svg";
import learnSpaceLetters from "../../../../features/dashboard/assets/images/learnspaceLetters.svg";
import React, {Dispatch, SetStateAction, useState} from "react";
import {Link} from "react-router-dom";
import ProfileDropdown from "../../../../components/reusables/layout/ProfileDropdown";
import utils from "../../../../utils/utils";
import SideNavButton from "../../../../components/reusables/layout/SideNavButton";
import Notifications from "../../../../components/reusables/layout/Notifications";
import {useAppSelector} from "../../../../redux/hooks/hooks";
import {roles} from "../../../../utils/roles";

interface IProps {
    setMobileOpen?: any;
    setSelectedName?: Dispatch<SetStateAction<string | null>>;
}
function TraineeTopBar({setMobileOpen, setSelectedName}: IProps) {
    const {user} = useAppSelector((state) => state.auth);
    const [role, setRole] = useState(roles.TRAINEE);
    const [showInviteModal, setShowInviteModal] = useState(false);

    
    const handleCloseInviteModal = () => {
        setShowInviteModal(false);
      };
   

    // console.log("user",user)
    const fullName = user?.name;
    const userRole = user?.realm_access?.roles[0];

    const [arrowToggled, setArrowToggled] = useState(false);
    const [bellClicked, setBellClicked] = useState(false);
    const [hamburger, setHamburger] = useState(false);

    const toggleArrow = () => {
        setArrowToggled(!arrowToggled);
    };
    const clickBell = () => {
        setBellClicked(!bellClicked);
    };
    const clickHamburger = () => {
        setHamburger(!hamburger);
    };


    return (
        <>
            <div id={`ufchsbouobcuhb`} className={styles.topNavigation}>
                <Link id={`bsjcb`} to="/fund">
                    <div id={`sjbldskj`} className="flex items-center">
                        <img id={`vsnjpf`} src={learnSpaceLogo} className="mr-2" alt="learn-space-logo"/>
                        <img id={`ancijioljd`} src={learnSpaceLetters} className={styles.learnSpaceLetters} alt="learn-space-logo"/>
                    </div>
                </Link>
                
                <div id={`csnjnvknpeds`} className={styles.rightTopNav}>
                    <div id={`scnijnaoij`}>
                        <button id={`caninpjkase`} className={styles.bellDiv} onClick={clickBell}>
                            <FiBell id={`ncsdodfe`} className="text-[30px] "/>
                        </button>
                        {bellClicked && 
                        <Notifications
                         close={clickBell}
                         />}
                    </div>

                    <img id={`snojnowe`} src={dummyProfileImage} className={styles.profileImage} alt="profileImage"/>
                    <div id={`ncaijnie`} className={`relative`}>

                        <button id={`cnjpoweknjfpwe`} onClick={clickHamburger} className={styles.hamburger}>
                            <GiHamburgerMenu/>
                        </button>
                        {hamburger &&
                            <div id={`mcijsnows`} className={styles.mobileTopNav}>
                                <SideNavButton/>
                            </div>
                        }
                    </div>

                    <div id={`cijnijskwds`} className={styles.rightTopNavText}>
                        <div id={`csdnfoe`} className="flex justify-between items-center w-[150px]">
                            <p id={`ncnjisdkf`} className={styles.fullName}>{fullName}</p>
                            <div id={`kjfsdjj`} className={styles.profileArrow}>
                                <div id={`hcuiskjdmf`}>
                                    <button id={`nacujijfs`} onClick={toggleArrow}>
                                        <IoIosArrowDown/>
                                    </button>
                                    {arrowToggled && <ProfileDropdown/>}
                                </div>
                            </div>
                        </div>
                        <p id={`sdfuviubfewre`}>{utils.formatRole(userRole)}</p>
                    </div>
                </div>
                
                
            </div>
            <div id={`wkjfhubcijs`} className={`h-[100px] relative ${styles.space}`}/>

          
        </>
    );
}

export default TraineeTopBar;