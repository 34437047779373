import React, {useState} from "react";
import Profile from "../../assets/images/Investor.svg";
import {useNavigate} from "react-router-dom";
import {HiArrowLeft} from "react-icons/hi";
import styles from "../../trainee/traineeDetails/TraineeDetails.module.css";
import BreadCrumbs from "../../../../components/breadCrumb/BreadCrumbs";
import {useAppSelector} from "../../../../redux/hooks/hooks";
import dummyProfileImage from "../../assets/images/dummyProfileImage.svg";

const BioData: React.FC = () => {

    const [showDropdown, setShowDropdown] = useState(false);
   const handleToggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const investorBiodata = useAppSelector(state => state.investor.investorDetail);

    return (
        <div id={'mainDiv'} className="flex justify-center items-center flex-col p-12">
            {/* First Container */}
            <div id={'innerDiv'} className={`flex justify-between items-center mx-12 mb-[20px] w-full`}>
                <BreadCrumbs/>
                <div id={'buttonUpperDiv'} className="relative">
                    <button
                        id={'handleToggleDropdown'}
                        className="rounded-full bg-white w-[40px] h-[40px] text-lg font-bold"
                        // onClick={handleToggleDropdown}
                    >
                        ⋮
                    </button>
                    {showDropdown && (
                        <div
                            id={'showDropdown'}
                            className={`absolute bg-white rounded-md shadow-lg mt-3.5 w-[100px] transform -translate-x-1/2 left-1/2 ${styles.dropdown}`}>
                            <button
                                id={'showDropdownButton'}
                                className="block px-4 py-2 text-sm text-gray-800 hover:text-[#0D9B48] hover:font-bold"
                                // onClick={}
                            >
                                A
                            </button>
                            <button
                                id={'showDropdownButtonBTwo'}
                                className="block px-4 py-2 text-sm text-gray-800 hover:text-[#0D9B48] hover:font-bold"
                                // onClick={}
                            >
                                B
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div id={'statusTopDiv'} className="bg-white p-10 flex flex-col w-full">
                <div id={'statusImagDiv'} className="flex w-[100% ] ">
                    <img id={'profileInvestor'} src={dummyProfileImage} alt="investor"/>
                    <div id={'profileInvestorHenryDiv'} className="flex justify-between items-center w-full ">
                        <h1 id={'HenryProfileInvestorH1'} className="p-3 font-semibold text-lg">{`${investorBiodata?.investorName}`}</h1>
                        <h2 id={'statusHProfileInvestor'} className="ml-auto">
                            Status:
                            <span id={'activeSpan'} className="font-medium text-sm text-[#OD9B48]">Active</span>{" "}
                        </h2>
                    </div>
                </div>
                <div id={'acctTypeDivOuter'} className="grid grid-cols-4 my-5  ">
                    <div id={'acctTypeDivInner'}>
                        <h1 id={'acctTypeHTag'} className="font-medium text-lg">Account Type</h1>
                        <small id={'smallAcctTypeIndividual'} className="font-semicold text-base text-[#5F646F]">
                            {investorBiodata?.accountType || "N/A" }
                        </small>
                    </div>

                    <div id={'emailAddressDiv'}>
                        <h1 id={'emailAddressH'} className="font-medium text-lg">Email Address</h1>
                        <small id={'motungeorge6Small'} className="font-semicold text-base text-[#5F646F]">
                            {investorBiodata?.email}
                        </small>
                    </div>

                    <div id={'phoneNumberDiv'}>
                        <h1 id={'phoneNumberH'} className="font-medium text-lg">Phone Number</h1>
                        <small id={'phoneNumberSmall'} className="font-semicold text-base text-[#5F646F]">
                            {investorBiodata?.phoneNumber}
                        </small>
                    </div>
                    <div id={'addressDiv'}>
                        <h1 id={'addressH1'} className="font-medium text-lg">Address</h1>
                        <small id={'addressSmallAlagomeji'} className="font-semicold text-base text-[#5F646F]">
                            {/*Plot 323 Borno Way Alagomeji Yaba Lagos Nigeria*/}
                            {investorBiodata?.address || 'N/A'}
                        </small>
                    </div>
                </div>
            </div>

            <div id={'nextOfKinDiv'} className="w-full">
                <div id={'nextOfKinInnerDiv'} className="text-left font-normal text-base my-5">
                    <h1 id={'nextOfKinH1'}>Next Of Kin Info</h1>
                </div>
                <form id={'formTag'} action="">
                    <div id={'formMainDiv'} className="bg-white p-12 flex flex-col">
                        <div id={'formInnerDiv'} className="grid grid-cols-3 mb-5 gap-12">
                            <div id={'firstNameDiv'}>
                                <h1 id={'firstName'} className="font-medium text-lg">First Name</h1>
                                <p id={'IdowuP'}>N/A</p>
                            </div>

                            <div id={'lastNameDiv'}>
                                <h1 id={'lastNameH1'} className="font-medium text-lg">Last Name</h1>
                                <p id={'MotunP'}>N/A</p>
                            </div>

                            <div id={'phoneNumberDiv'}>
                                <h1 id={'PhoneNumberH'} className="font-medium text-lg">Phone Number</h1>
                                <p id={'numberP'}>N/A</p>
                            </div>

                            <div id={'contactAddressDiv'}>
                                <h1 id={'contactAddressH1'} className="font-medium text-lg">Contact Address</h1>
                                <p id={'HerbertP'}>N/A</p>
                            </div>

                            <div id={'emailAddressDivMontun'}>
                                <h1 id={'emailAddressH1Tag'} className="font-medium text-lg">Email Address</h1>
                                <p id={'motunPTag'}>N/A</p>
                            </div>

                            <div id={'RelationshipDiv'}>
                                <h1 id={'RelationshipH1Kin'} className="font-medium text-lg">
                                    Next Of Kin Relationship
                                </h1>
                                <p id={'BrotherP'}>N/A</p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BioData;
