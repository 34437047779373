import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TableData} from "../../types/commons.type";
import {buttonNames} from "../../utils/roles";
import {PURGE} from "redux-persist";

interface IProgramState {
    program?: TableData<any> | undefined;
    buttonName: string;
    programId: string;
    programName: string;
}

const initialState: IProgramState = {
    // program: {}
    buttonName: buttonNames.PROGRAMS,
    programId: "",
    programName: ""

};

const programSlice = createSlice({
    name: 'program',
    initialState,
    reducers: {
        setProgram: (state, action: PayloadAction<any>) => {
            state.program = action.payload;
        },
        programSideButton: (state, action: PayloadAction<buttonNames>) => {
            state.buttonName = action.payload;
        },

        setProgramName: (state, action: PayloadAction<any>) => {
            state.programName = action.payload;
        },

        setProgramId: (state, action: PayloadAction<any>) => {
            state.programId = action.payload;
        },
        // setEditProgram: (state, action: PayloadAction<any>) => {
        //     state.program = action.payload;
        //     console.log(state.program)
        // }
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.program = undefined;
            state.buttonName = "";
            state.programName = "";
            state.programId = "";
        });
    },
});

export const programAction = programSlice.actions;

export default programSlice.reducer;