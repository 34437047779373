import React, {useState} from "react";
import Layout from "../../../../components/reusables/layout/Layout";
import InviteMidSection from "./InviteMidSection";
import InviteModal from "./InviteModal";

function InviteColleaguePage() {
  const [openModal, setOpenModal] = useState(false);


  const openInviteModal = () => {
    setOpenModal(true);
  };

  const closeMainModal = (e: any) => {
    setOpenModal(false);
  };

  return (
    <Layout page={"Institute"}>
      <InviteMidSection openModal={openInviteModal} />
      <InviteModal open={openModal} onClose={closeMainModal} />
    </Layout>
  );
}

export default InviteColleaguePage;
