import React, {ReactNode} from 'react';
import {
    AuthBgFooter,
    AuthBgFormContainer,
    AuthBgLearnSpaceLogo,
    AuthBgLogoLeft,
    AuthBgLogoRight,
    MainContainer
} from "./AuthBackground.style";
import bgLeftLogo from "../background/assets/bg-logo-left.svg";
import bgRightLogo from "../background/assets/bg-logo-right.svg";
import learnSpaceLogo from "../background/assets/learnspace-logo.svg";

interface IProps{
    children: ReactNode;
}
function AuthBackground(props: IProps) {
    return (
        <MainContainer id={'MainContainer'}>
            <AuthBgLogoLeft id={'AuthBgLogoLeft'}>
                <img id={'firstImage'} src={bgLeftLogo} alt="learnspace logo" />
            </AuthBgLogoLeft>

            <AuthBgLogoRight id={'AuthBgLogoRight'}>
                <img id={'bgRightLogo'} src={bgRightLogo} alt="learnspace logo" />
            </AuthBgLogoRight>
            <AuthBgFormContainer id={'AuthBgFormContainer'}>
                <AuthBgLearnSpaceLogo id={'AuthBgLearnSpaceLogo'}>
                    <img
                        id={'AuthBgLearnSpaceLogo'}
                        src={learnSpaceLogo}
                        alt="learnspace logo"/>
                </AuthBgLearnSpaceLogo>
                {props.children}
            </AuthBgFormContainer>
            <AuthBgFooter id={'AuthBgFooter'}>
                <p id={'LearnSpace'}>
                    &copy; {new Date().getFullYear()} LearnSpace. All rights reserved
                </p>
            </AuthBgFooter>
        </MainContainer>
    );
}

export default AuthBackground;