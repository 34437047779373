import React, {CSSProperties, ReactNode} from "react";
import eyeSlash from "../password/assets/eye-slash.svg";
import eyeOpen from "../password/assets/eye-open.svg";
import {InputDiv} from "./password.style";

interface IPasswordInputProps {
  value: string;
  onChange: (value: string) => void;
  style?: CSSProperties;
  showPassword: boolean; // Receive showPassword prop
  togglePasswordVisibility: () => void; // Receive toggle function prop
  children?: ReactNode;
  componentId?: string;
}

function PasswordInput({
  value,
  onChange,
  style,
  showPassword,
  togglePasswordVisibility, componentId,
  children,
}: IPasswordInputProps) {
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <InputDiv id={`inputDIv${componentId}`}>
      <input
          id={'handlePasswordChange'}
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={handlePasswordChange}
        style={style}
      />

      {showPassword ? (
        <img
            id={'Slash'}
          src={eyeSlash}
          alt="Eye Slash"
          onClick={togglePasswordVisibility}
        />
      ) : (
        <img id={'eyeOpen'} src={eyeOpen} alt="Eye" onClick={togglePasswordVisibility} />
      )}

      {children}
    </InputDiv>
  );
}

export default PasswordInput;
