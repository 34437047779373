import React, {useState} from "react";
import {GrClose} from "react-icons/gr";
import {ErrorMessage, Field, FieldArray, Form, Formik} from "formik";
import * as Yup from "yup";
import Modal from "../../../../components/Modal/index";
import styles from "./styles/InviteModal.module.css";
import {Icon} from "@iconify/react";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import {Toast, ToastTypes} from "../../../../components/Toast/index";
import utils from "../../../../utils/utils";
import colleagueService from "../../../../services/colleague.service";
import {setColleagues} from "../../../../redux/slices/colleagueSlice";
import {useAppDispatch} from "../../../../redux/hooks/hooks";
import {nameRegExp, nameValidationMessage} from "../../../../utils";
// import {useInviteColleagueMutation} from "../../../../redux/features/portfolioManager/api";

function InviteModal({open, onClose}) {
    const validationSchema = Yup.object().shape({
        members: Yup.array().of(
            Yup.object().shape({
                firstName: Yup.string().required("First Name is required")
                    .matches(nameRegExp, nameValidationMessage),
                lastName: Yup.string().required("Last Name is required")
                    .matches(nameRegExp, nameValidationMessage),
                email: Yup.string()
                    .email("Invalid email")
                    .required("Email is required")
                    .test("custom-email", "Invalid domain", function (value) {
                        const validDomainRegex =
                            /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
                        return validDomainRegex.test(value);
                    }),
            })
        ),
    });

    const initialValues = {
        members: [{firstName: "", lastName: "", email: ""}],
    };

    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const fetchColleagues = async () => {
        try {
            const response = await colleagueService.getAllColleague();
            dispatch(setColleagues(response.content));
        } catch (error) {
            utils.handleRequestError(error)
        }
    };
    const handleSubmit = async (values, formikHelpers) => {
        setIsLoading(true);

        try {
            const response = await colleagueService.inviteColleague(values);

            if (response) {
                Toast({content: "Colleague Successfully Invited", type: ToastTypes.SUCCESS});
                fetchColleagues().then();
                onClose(true);
            }
        } catch (err) {
            if (err.response) {

                const errorMessage = err?.response?.data?.message;
                const emailPattern = /with\s+(\S+)\s+already/;
                const existingEmailMatch = errorMessage.match(emailPattern);

                if (existingEmailMatch) {
                    const existingEmail = existingEmailMatch[1];
                    const index = values.members.findIndex(
                        (member) => member.email === existingEmail
                    );
                    if (index !== -1) {
                        formikHelpers.setFieldError(
                            `members.${index}.email`,
                            "Email Already Exists!"
                        );
                    }
                }
                utils.handleRequestError(err);
            }

        } finally {
            setIsLoading(false);
        }
    };

    // const [inviteColleagueCall, {isLoading}] = useInviteColleagueMutation();
    //
    // const handleSubmit = async (values, formikHelpers) => {
    //     console.log("Values =>", values)
    //
    //     inviteColleagueCall(values.members[0])
    //         .unwrap()
    //         .then((response) => {
    //             console.log("Response from API:", response);
    //             Toast({content: "Colleague Successfully Invited", type: ToastTypes.SUCCESS});
    //             onClose(true);
    //         })
    //         .catch((err) => {
    //             console.log("Error Response:", err);
    //             if (err.response) {
    //
    //                 const errorMessage = err?.response?.data?.message;
    //                 const emailPattern = /with\s+(\S+)\s+already/;
    //                 const existingEmailMatch = errorMessage.match(emailPattern);
    //
    //                 if (existingEmailMatch) {
    //                     const existingEmail = existingEmailMatch[1];
    //                     const index = values.members.findIndex(
    //                         (member) => member.email === existingEmail
    //                     );
    //                     if (index !== -1) {
    //                         formikHelpers.setFieldError(
    //                             `members.${index}.email`,
    //                             "Email Already Exists!"
    //                         );
    //                     }
    //                 }
    //                 utils.handleRequestError(err);
    //             }
    //         })
    // };

    const InputField = ({name, placeholder, type, className}) => (
        <div id={'dksnf'}>
            <p id={'dmpd'} className={styles.mobileLabel}>{placeholder}</p>
            <Field
                type={type}
                name={name}
                placeholder={placeholder}
                className={className}
            />
            <ErrorMessage name={name} component="div" className={styles.error}/>
        </div>
    );

    return (
        <Modal isOpen={open}>
            <div id={'dienic'} className={styles.modalContainer}>
                <div id={'miced'} className={styles.closeButton} onClick={() => onClose(false)}>
                    <GrClose/>
                </div>
                <div id={'mpod'} className={styles.invitePrompt}>
                    <p id={'orcd'}>Invite Colleague</p>
                    <p id={'ciom'}>
                        Invite Colleague, this will help them add more value to your
                        institution and oversight function
                    </p>
                </div>
                <div id={'moea'} className={styles.inputsAndLabels}>
                    <div id={'miceim'} className={styles.labels}>
                        <p id={'ciems'}>First Name</p>
                        <p id={'cinedk'}>Last Name</p>
                        <p id={'cmidk'}>Email</p>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({values, isValid, touched, errors}) => (
                            <Form>
                                <FieldArray name="members">
                                    {({remove, push}) => (
                                        <>
                                            {values.members.map((_, index) => (
                                                <div id={`dc${index}div`} key={index} className={styles.inputRow}>
                                                    <InputField
                                                        name={`members.${index}.firstName`}
                                                        placeholder="First Name"
                                                        type="text"
                                                        className={`${styles.firstName}
                                                         ${
                                                            touched.members?.[index]?.firstName &&
                                                            errors.members?.[index]?.firstName ? styles.errorInput : ""
                                                        }`}
                                                    />
                                                    <InputField
                                                        name={`members.${index}.lastName`}
                                                        placeholder="Last Name"
                                                        type="text"
                                                        className={`${styles.lastName} 
                                                        ${
                                                            touched.members?.[index]?.lastName &&
                                                            errors.members?.[index]?.lastName ? styles.errorInput : ""
                                                        }`}
                                                    />
                                                    <InputField
                                                        name={`members.${index}.email`}
                                                        placeholder="Email Address"
                                                        type="email"
                                                        className={`${styles.email}
                                                        ${
                                                            touched.members?.[index]?.email &&
                                                            errors.members?.[index]?.email ? styles.errorInput : ""
                                                        }`}
                                                    />

                                                </div>
                                            ))}

                                        </>
                                    )}
                                </FieldArray>
                                <button
                                    id={'dnasu'}
                                    type="submit"
                                    className={
                                        !isValid ? styles.disabledInvite : styles.inviteButton
                                    }
                                    disabled={!isValid}
                                >
                                    {isLoading ? (
                                        <div id={'mwei'} className="flex items-center justify-center">
                                            <Icon icon={loadingLoop} width={24} height={24}/>
                                        </div>
                                    ) : (
                                        "Invite"
                                    )}
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
}

export default InviteModal;
