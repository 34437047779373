import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {HiArrowLeft} from "react-icons/hi";
import styles from "../../trainee/traineeDetails/TraineeDetails.module.css";
import ViewTraineeLayout from "./viewTraineeLayout";
import TraineeCard from "./traineeCard";
import wallet from "../../assets/entypo_wallet.svg";


const Index: React.FC = () => {

    const [showDropdown, setShowDropdown] = useState(false);

    const navigate = useNavigate();

    const handleToggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    return (
        <ViewTraineeLayout>
            <div id={`ncsjbdied`} className="flex justify-center items-center flex-col p-12">

                <div id={`hnxaiubcus`} className={`flex justify-between items-center m[50px] mr-[120px] my-[20px] w-[90%]`}>
                    <div
                        id={`ncisnicni`}
                        className="bg-white rounded-full w-[40px] h-[40px] cursor-pointer flex justify-center items-center"
                        onClick={() => navigate("")}
                    >
                        <HiArrowLeft id={`cjabjbos`} />
                    </div>
                    <div id={`aknfjcl`} className="relative">
                        <button
                            id={`ncasoiee`}
                            className="rounded-full bg-white w-[40px] h-[40px] text-lg font-bold"
                            onClick={handleToggleDropdown}
                        >
                            ⋮
                        </button>
                        {showDropdown && (
                            <div
                                id={`sbouhbvihbf`}
                                className={`absolute bg-white rounded-md shadow-lg mt-3.5 w-[100px] transform -translate-x-1/2 left-1/2 ${styles.dropdown}`}>
                                <button
                                    id={`hnadoijfpw`}
                                    className="block px-4 py-2 text-sm text-gray-800 hover:text-[#0D9B48] hover:font-bold"
                                >
                                    A
                                </button>
                                <button
                                    id={`sbbbvhjbdjkeo`}
                                    className="block px-4 py-2 text-sm text-gray-800 hover:text-[#0D9B48] hover:font-bold"
                                >
                                    B
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div id={`nsvobnosnjjd`} className="bg-white p-4 flex flex-col ">
                    <div id={`nsvlsvdvnonienv`} className="grid grid-cols-4 mb-5  gap-10 ">
                        <div id={`nsbjvbj`}>
                            <h1 id={`sjnvjsnlkjfnvei`} className="font-medium text-lg">Training Institute</h1>
                            <small id={`ncsbjldfg`} className="font-semicold text-base text-[#5F646F]">
                                Brownhill International
                            </small>
                        </div>

                        <div id={`slnvojnjfve`}>
                            <h1 id={`snijnjls`} className="font-medium text-lg">Program Name</h1>
                            <small id={`lsnjnlsdoiepr`} className="font-semicold text-base text-[#5F646F]">
                                International Business Management
                            </small>
                        </div>

                        <div id={`sobjvleol3e`}>
                            <h1 id={`nsvlkjvnsjv34`} className="font-medium text-lg">Cohort Name</h1>
                            <small id={`sklncnlrefd`} className="font-semicold text-base text-[#5F646F]">
                                Unicorns
                            </small>
                        </div>
                        <div id={`sjbjsjld`}>
                            <h1 id={`wcacesdvhciheu`} className="font-medium text-lg">Tuition Amount</h1>
                            <small id={`wghejlojhvdf`} className="font-semicold text-base text-[#5F646F]">
                                3000000
                            </small>
                        </div>
                        <div id={`dfuyhvjpir`}>
                            <h1 id={`dfjgsjgobdd`} className="font-medium text-lg">Credit Worthiness</h1>
                            <small id={`gdjzkiujs`} className="font-semicold text-base text-[#5F646F]">
                                Good
                            </small>
                        </div>
                        <div id={`askmdnfjkj`}>
                            <h1 id={`osopwls`} className="font-medium text-lg">Identity Verified?</h1>
                            <small id={ `sokwefmsd`} className="font-semicold text-base text-[#5F646F]">
                                Yes
                            </small>
                        </div>
                        <div id={`kskweqavoelw`}>
                            <h1 id={`apwppfjwkoe`} className="font-medium text-lg">Fitness to work Rating</h1>
                            <small id={`acojcbpoafniew`} className="font-semicold text-base text-[#5F646F]">
                                Yes
                            </small>
                        </div>
                        <div id={`ajblofboabufjeci`}>
                            <h1 id={`aclkjkjpwijfds`} className="font-medium text-lg">Initial Deposit</h1>
                            <small id={`anjosoinwjekij`} className="font-semicold text-base text-[#5F646F]">
                                1000000                            
                                </small>
                        </div>
                    </div>
                </div>

                <div id={`jsaboaufhwjes`} className="w-full">
                    <div id={`obsviekdf`} className="text-left font-normal text-base mt-5">
                        <h1 id={`sbojbivlekd`}>Next Of Kin Info</h1>
                    </div>
                    <form id={`asnoijewjdf`} action="">
                        <div id={`bibaoiuewk`} className="bg-white p-12 flex flex-col">
                            <div id={`snoireojdfrf`} className="grid grid-cols-5 ">
                                <div id={`caboewo9iek`}>
                                    <TraineeCard id={`jfiejdk`} title="Loan Referral Date " src={wallet} text="TBD"/>
                                </div>

                                <div id={`bcaiukjhbn`}>
                                <TraineeCard id={`ekjfkl`} title="Interest Rate" src={wallet} text="TBD"/>

                                </div>

                                <div id={`nvsjdnvid`}>
                                <TraineeCard id={`ehcudjshjk`} title="Amount Requested" src={wallet} amount={800000}/>

                                </div>

                                <div id={`bdbjvdfger`}>
                                <TraineeCard id={`hjehsjkdf`} title="Total Amount Repaid" src={wallet} amount={0}/>

                                </div>

                                <div id={`sbaxaocjemdg`}>
                                <TraineeCard id={`ygsdfg`} title="Total OutStanding" src={wallet} amount={0}/>

                                </div>

                                <div id={`bcuhodhejlr`}>
                                <TraineeCard id={`hsjkd`} title="Loan Moratorium" src={wallet} text="TBD"/>

                                </div>
                                <div id={`nsvbjfe`}>
                                <TraineeCard id={`fsfhdjh`} title="Loan Status" src={wallet} text="TBD"/>

                                </div>
                                
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </ViewTraineeLayout>
    );
};

export default Index;
