import React from 'react';
import TopBar from "../../../../components/reusables/layout/TopBar";
import Stepper from "../stepper/Stepper";
import styles from "../styles/Main.module.css"
import {Outlet} from "react-router-dom";

function TraineeFlowLayout() {
    return (
        <div id={`cjsknvnsk`} className={styles.background}>
            <TopBar/>
            <div id={`nckdjnnsl`} className={styles.form}>
                <Stepper/>
                <div id={`cjsdjnldf`}>
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}

export default TraineeFlowLayout;