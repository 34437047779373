import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {buttonNames} from "../../utils/roles";
import {TableData} from "../../types/commons.type";
import {IFundProductData} from "../../features/dashboard/fundProduct/fundProductTable";
import {CohortData} from "../../types/cohort/cohort.type";
import {PURGE} from "redux-persist";

interface ICohort{
    buttonName: string;
    cohortId: string;
    programId: string;
    cohortName: string;
    programName: string;
    cohortStartDate:string;
    cohortEndDate:string;
    tuitionAmount:number
    cohortDetails: any;
    incomingCohortData?: TableData<any>;
    allCohortData?: TableData<any>;
    programsCohortButtonName: string;
}

const initialState: ICohort = {
    buttonName: buttonNames.INCOMING_COHORT,
    programsCohortButtonName: buttonNames.ALL_COHORT,
    cohortId: "",
    programId: "",
    cohortName: "",
    programName: "",
    cohortStartDate: "",
    cohortEndDate:"",
    tuitionAmount:0.00,
    cohortDetails: {},
}

const cohortSlice = createSlice({
    name: "cohort",
    initialState,
    reducers: {
        cohortSideBarButton: (state, action:PayloadAction<string>)=>{
            state.buttonName = action.payload;
        },
        setCohortId:(state, action: PayloadAction<any>) =>{
            state.cohortId = action.payload;
            sessionStorage.setItem("cohortId", state.cohortId);
            console.log("cohort id =", state.cohortId);
        },
        setProgramId:(state, action: PayloadAction<any>) =>{
            state.programId = action.payload;
            sessionStorage.setItem("programId", state.programId);
        },
        setCohortName:(state, action: PayloadAction<string>)=>{
            state.cohortName = action.payload;
            sessionStorage.setItem("cohortName", state.cohortName)
        },
        setProgramName:(state, action: PayloadAction<string>)=>{
            state.programName = action.payload;
            sessionStorage.setItem("programName", state.programName);
        },
        setCohortStartDate:(state, action: PayloadAction<string>)=>{
            state.cohortStartDate = action.payload;
        },
        setCohortEndDate:(state, action: PayloadAction<string>)=>{
            state.cohortEndDate = action.payload;
        },
        setTuitionAmount:(state, action: PayloadAction<number>)=>{
            state.tuitionAmount = action.payload;
        },
        setCohortDetails: (state, action:PayloadAction<any>)=> {
            state.cohortDetails = action.payload;
        },
        setIncomingCohortData: (state, action:PayloadAction<any>)=>{
            state.incomingCohortData = action.payload;
            // console.log("incoming cohorts =>", state.incomingCohortData);
        },
        setAllCohortData: (state, action:PayloadAction<any>)=>{
            state.allCohortData = action.payload;
        },
        programsCohort: (state, action: PayloadAction<buttonNames>) => {
            state.programsCohortButtonName = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.buttonName= "";
            state.cohortId = "";
            state.programId = "";
            state.cohortName = "";
            state.programName = "";
            state.cohortStartDate = "";
            state.cohortEndDate = "";
            state.tuitionAmount = 0.00;
            state.cohortDetails = undefined;
            state.incomingCohortData = undefined;
            state.allCohortData = undefined;
            state.programsCohortButtonName = "";
        });
    },
})

export const cohortActions = cohortSlice.actions;
export default cohortSlice.reducer;