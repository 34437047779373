import React, {useState} from 'react';
import {TableData} from '../../../../types/commons.type';
import {useAppSelector} from "../../../../redux/hooks/hooks";
import ProgramDataTable from "../../programs/programTable";
import {buttonNames} from "../../../../utils/roles";
import ViewTraining from "../ViewTrainingInstitute/ViewTraining";
import LoanRequestTable from "../../trainee/loanRequest/LoanRequestTable";
import {emptyLoanRequest, loanRequestData} from "../../../../types/trainee/trainee.type";
import TableTop from "../../../../components/reusables/tableTop/TableTop";
import AllCohortsInProgram from "../../cohort/AllCohortsInProgram/allCohortIndex";
import InstituteAdmins from "../ViewInstituteAdmins/newDesign/InstituteAdmins";
import ProgramDashboard from "../../programs/programDashboard";
import InstituteProgramsPm from "../../programs/instituteProgramsPm";

interface IProps {

}

const InstituteSideBar = (props: IProps) => {
    const [loanRequestData, setLoanRequestData] = useState<TableData<loanRequestData>>(emptyLoanRequest);
    const selectedButton = useAppSelector(state => state.institute.buttonName)

    switch (selectedButton) {
        case buttonNames.INSTITUTES_DETAILS:
            return (
                <ViewTraining/>
            );
        case buttonNames.PROGRAMS:
            return (
                <div id={'PROGRAMSMainDiv'} className={`flex flex-col h-full py-[20px] px-[40px] w-screen md:w-auto`}>
                    <div id={'goToPreviousPageOuterDiv'} className="flex justify-end">
                        <TableTop
                            placeholder={"Find Program"}
                            setButtonNeeded={false}
                        />
                    </div>
                    <div id={'ProgramDataTableDiv'} className={`flex-1 h-full`}>
                        <InstituteProgramsPm/>
                    </div>

                </div>
            );
        case buttonNames.ALL_COHORT:
            return (
                <div id={'CURRENT_COHORTDiv'} className={`flex flex-col h-full py-[20px] px-[40px] w-screen md:w-auto`}>
                    <div id={'findCohortDiv'}>
                        <TableTop
                            placeholder={"Find Cohort"}
                            setButtonNeeded={false}
                        />
                    </div>
                    <div id={'CurrentCohortTable'} className={`flex-1`}>
                        <AllCohortsInProgram/>
                    </div>
                </div>
            );
        case buttonNames.TRAINEES:
            return (
                <div id={'fundTraineeDiv'} className={`flex flex-col h-full py-[20px] px-[40px] w-screen md:w-auto`}>
                    <TableTop
                        placeholder={"Find Trainee"}
                        setButtonNeeded={false}
                    />
                    <div id={'LoanRequestTable'} className={`flex-1`}>
                        <LoanRequestTable tableData={loanRequestData}/>
                    </div>
                </div>
            );
        case buttonNames.ADMINS:
            return (
                <InstituteAdmins/>
            );
        default:
            return null;
    }
}

export default InstituteSideBar;