import React, {useEffect, useState} from 'react';
import Modal from "../../../../components/Modal";
import {GrClose} from "react-icons/gr";
import {Field, Form, Formik} from "formik";
import styles from "./ReferTraineeModal.module.css";
import {Icon} from "@iconify/react";
import loadingLoop from "@iconify/icons-line-md/loading-loop";
import * as Yup from "yup";
import traineeService from '../../../../services/trainee.service';
import {getInstituteId} from "../../../../utils";
import utils from "../../../../utils/utils";

interface IProps {
    isOpen: boolean;
    close: () => void;
}

interface Option {
    id: number;
    value: string;
    label: string;
}

const initialValues = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    amountRequested: null,
    initialDeposit: null
}
const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    emailAddress: Yup.string().email('Invalid email address').required('Email Address is required'),
    amountRequested: Yup.number().required('Amount Requested is required').positive('Amount must be positive'),
    initialDeposit: Yup.number().required('Initial Deposit is required').positive('Initial Deposit must be positive'),
});
const handleSubmit = () => {

}


function ReferTraineeModal({isOpen, close}: IProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState<string>('');
    const [options, setOptions] = useState<Option[]>([]);
    const fetchData = async () => {
        try {
            const cohortsData = await traineeService.getCohorts(getInstituteId);
            setOptions(cohortsData);
        } catch (error) {
            // utils.handleRequestError(error)
        }
    };

    useEffect(() => {
        fetchData().then();
    }, []);

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedValue(event.target.value);
    };



    return (
        <Modal isOpen={isOpen}>
            <div id={"cjks"} className={`min-w-[700px] ${styles.border}`}>
                <div id={"dlskcd"} className={`py-[30px] md:px-[80px] px-[30px] border-b border-black`}>
                    <div id={"scdjnj"} className={`${styles.top}`}>
                        <h4 id={"mkcd"} className={`text-lg`}>Refer Trainee</h4>
                        <button id={"cjns"} className={``}
                                onClick={close}>
                            <GrClose id={"cmdkok"}/>
                        </button>
                    </div>
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors, touched, setFieldValue, values }) => (
                        <Form>
                            <div id={"siao"} className={`px-20 py-16 ${styles.formFirstDiv}`}>
                                <div id={"cdoewad"} className={`flex md:gap-[20px] gap-0 flex-col`}>
                                    <div id={"asmciew"} className={`flex flex-col md:flex-row w-full justify-between`}>
                                        <div id={"dbchsb"} className={`${styles.field}`}>
                                            <label id={"dsnje"}>First Name</label>
                                            <Field
                                                type={`text`}
                                                name={`firstName`}
                                                placeholder={`Henry`}
                                                style={{
                                                    borderColor:
                                                      errors.firstName && touched.firstName
                                                        ? "red"
                                                        : "inherit",
                                                  }}
                                            />
                                         {errors.firstName && touched.firstName && (<div id={"cskiews"} className={styles.error}>{errors.firstName}</div>)}
                                         
                                         </div>

                                        <div id={"dcoewnc"} className={`${styles.field}`}>
                                            <label id={"dcmiweus"}>Last Name</label>
                                            <Field
                                                type={`text`}
                                                name={`lastName`}
                                                placeholder={`Makanaki`}
                                                style={{
                                                    borderColor:
                                                      errors.lastName && touched.lastName
                                                        ? "red"
                                                        : "inherit",
                                                  }}
                                            />
                                            {errors.lastName && touched.lastName && (<div id={"vmfk"} className={styles.error}>{errors.lastName}</div>)}
                                        </div>
                                    </div>

                                    <div id={"labelEmailDiv"} className={`flex flex-col md:flex-row w-full justify-between`}>
                                        <div id={"innerEmailDiv"} className={`${styles.field}`}>
                                            <label id={"emailLabelAddress"}>Email Address</label>
                                            <Field
                                                type={`text`}
                                                name={`emailAddress`}
                                                placeholder={`henrymaka@gmail.com`}
                                                style={{
                                                    borderColor:
                                                      errors.emailAddress && touched.emailAddress
                                                        ? "red"
                                                        : "inherit",
                                                  }}
                                            />
                                         {errors.emailAddress && touched.emailAddress && (<div id={"cndsio"} className={styles.error}>{errors.emailAddress}</div>)}
                                        </div>

                                        <div id={"kbtcg"} className={`${styles.field}`}>
                                            <label id={"klrx"}>Cohort</label>
                                            <select id={"bjvv"} className={`${styles.select}`} value={selectedValue} onChange={handleSelectChange}>
                                                {options.map((option) => (
                                                    <option id={"mklodaw"} key={option.id} value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                        
                                        </div>
                                    </div>

                                    <div id={"qxdfcg"} className={`flex flex-col md:flex-row w-full justify-between`}>
                                        <div id={"ohrd"} className={`${styles.field}`}>
                                            <label id={"xfg"}>Amount Requested</label>
                                            <Field
                                                type={`number`}
                                                name={`amountRequested`}
                                                placeholder={`₦3,000,000`}
                                                style={{
                                                    borderColor:
                                                    errors.amountRequested && touched.amountRequested
                                                        ? "red"
                                                        : "inherit",
                                                }}
                                            />
                                         {errors.amountRequested && touched.amountRequested && (<div className={styles.error}>{errors.amountRequested}</div>)}

                                        </div>
                                        <div id={"njuybb"} className={`${styles.field}`}>
                                            <label id={"oicxz"}>Initial Deposit</label>
                                            <Field
                                                type={`number`}
                                                name={`initialDeposit`}
                                                placeholder={`₦1,000,000`}
                                                style={{
                                                    borderColor:
                                                    errors.initialDeposit && touched.initialDeposit
                                                        ? "red"
                                                        : "inherit",
                                                }}
                                            />
                                            {errors.initialDeposit && touched.initialDeposit && (<div id={"sdnisd"} className={styles.error}>{errors.initialDeposit}</div>)}

                                        </div>
                                    </div>

                                    <div id={"nunsa"} className={`flex flex-col md:flex-row w-full justify-between`}>
                                        <div id={"onuybt"} className={`${styles.field}`}>
                                            <p id={"program"}>Program</p>
                                            <p id={"designsacx"}>Design Thinking</p>
                                        </div>
                                        <div id={"sdnuje"} className={`${styles.field}`}>
                                            <p id={"sdcomd"} className={`md:text-right `}>Cohort Start Date</p>
                                            <p id={"Cidsn"} className={``}>10 September 2024</p>
                                        </div>
                                    </div>
                                </div>
                                <div id={"csodpdc"} className={`flex justify-between md:items-center mt-[40px] gap-3 ${styles.buttons}`}>
                                    <button
                                        id={"csnio"}
                                        className={`border border-solid border-[#0D9B48] rounded-[4px] py-[10px] w-[191px] ${styles.eachButton}`}
                                        onClick={close}>
                                        Cancel
                                    </button>
                                    <button
                                        id={"cskn"}
                                        className={`bg-[#0D9B48] text-white rounded-[4px] py-[10px] w-[191px] ${styles.eachButton}`}
                                        type="submit"
                                    >
                                        {isLoading ? (
                                            <div id={"dkja"} className="flex items-center justify-center">
                                                <Icon id={"djshj"} icon={loadingLoop} width={24} height={24}/>
                                            </div>
                                        ) : (
                                            "Refer Trainee"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
}

export default ReferTraineeModal;