import ApiInstance from "./api";
import {FormikValues} from "formik";
import {IAllInstitute} from "../types/institute.type";
import apiRequest from "./apiRequest.service";
import {TableData} from "../types/commons.type";
import {viewIncomingCohortDetails} from "../types/cohort/incomingCohort.type";
import {viewCurrentCohortDetails} from "../types/cohort/currentCohort.type";
import {store} from "../redux/store";
import {cohortActions} from "../redux/slices/cohortSlice";
import {viewAllCohortDetailsInProgram} from "../types/cohort/cohort.type";
import {log} from "node:util";


class CohortService {

    public async createCohort(values: IAllInstitute): Promise<any> {
        return apiRequest.request(`/cohorts/create`, "post", values)
    }

    public async editCohort(values: FormikValues): Promise<any> {
        return apiRequest.request(`/cohorts/edit`, "post", values)
    }

    public async getAllCohorts(data: any): Promise<any> {
        return apiRequest.request(`/cohorts/view-all-institute`, "post", data)
    }

    public async getCohort(data: any): Promise<any> {
        return apiRequest.request("/cohorts/view", "post", data)
    }

    public async delete(data:any): Promise<any> {
        return apiRequest.request("/cohorts/delete", "post", data);
    }

    public async viewIncomingCohort(data:any): Promise<TableData<viewIncomingCohortDetails>>{
        try {
            const apiInstance = new ApiInstance(sessionStorage.getItem("token"));
            const response = await apiInstance.getInstance().post("/cohorts/view-all-cohorts-details", data);
            // store.dispatch(cohortActions.setCohortDetails(response.data));
            store.dispatch(cohortActions.setIncomingCohortData(response.data));
            return response.data;
        } catch (err) {
            throw err;
        }
    }
    public async viewIncomingCohortInProgram(data:any): Promise<TableData<viewIncomingCohortDetails>>{
        try {
            const apiInstance = new ApiInstance(sessionStorage.getItem("token"));
            const response = await apiInstance.getInstance().post("/cohorts/view-all", data);
            store.dispatch(cohortActions.setCohortDetails(response.data));
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    public async viewAllCohortInProgram(data:any): Promise<TableData<viewAllCohortDetailsInProgram>>{
        try {
            const apiInstance = new ApiInstance(sessionStorage.getItem("token"));
            const response = await apiInstance.getInstance().post("/cohorts/view-all-institute", data);
            store.dispatch(cohortActions.setCohortDetails(response.data));
            return response.data;
        } catch (err) {
            throw err;
        }
    }
    public async viewCurrentCohorts(data: any): Promise<TableData<viewCurrentCohortDetails>> {
        return apiRequest.request("/cohorts/view-all-cohorts-details", "post", data)
    }

    public async viewGraduatedCohorts(data:any): Promise<any>{
        return apiRequest.request("/cohorts/view-all-cohorts-details", "post", data)
    }

    public async getLoanBreakdown(data: { cohortId: any }): Promise<any>{
        return apiRequest.request(`/loan-breakdown/view-all`, "post", data)
    }

}


const cohortService = new CohortService();
export default cohortService;