import {useAppSelector} from "../../../../../redux/hooks/hooks";
import {useEffect, useState} from "react";
import loanService from "../../../../../services/loan.service";
import {loanActions} from "../../../../../redux/slices/loanSlice";
import TopBar from "../../../../../components/reusables/layout/TopBar";
import BreadCrumbs from "../../../../../components/breadCrumb/BreadCrumbs";
import styles from "./styles/loanOfferDetailPage.module.css";
import {store} from "../../../../../redux/store";
import React from "react";
import utils from "../../../../../utils/utils";
import {toast} from "react-toastify";

function ViewLoanOfferIndex() {
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [loanOfferData, setLoanOfferData] = useState<any>({});
    const storedLoanOfferId = useAppSelector((state) => state.loan.loanId);
    const [isEmpty, setIsEmpty] = useState(false);
    const [isLoanOfferFailed, setIsLoanOfferFailed] = useState(false);
    const [isLoanOfferError, setIsLoanOfferError] = useState(false);
    const trainee = loanOfferData?.loanRequest?.trainee;
    const user = loanOfferData?.loanRequest?.trainee?.user;
    const cohort = loanOfferData?.loanRequest?.trainee?.cohort;
    const program = loanOfferData?.loanRequest?.trainee?.cohort?.program;
    const institute = loanOfferData?.loanRequest?.trainee?.cohort?.program.institute;

    const loanId = loanOfferData?.id;
    const traineeId = trainee?.traineeId;
    const closeModal = () => {
        console.log('sdjk')
        setOpenModal(false);
    };


    const data = {loanOfferId: storedLoanOfferId};


    const fetchLoanOfferDetails = async () => {
        try {
            const loanOffer = await loanService.viewLoanOffer(data);
            setLoanOfferData(loanOffer.data);

        } catch (error) {
            utils.handleRequestError(error)
        }
    };
    useEffect(() => {
        fetchLoanOfferDetails().then();
    }, []);

    const handleSubmit = async () => {

        const data2 = {loanId, traineeId}
        const loanDisbursal: { loanId: any; traineeId: any } = data2;
        try {
            setIsLoading(true);
            const response = await loanService.disburseLoan(loanDisbursal);
            if (response) {
                setIsLoanOfferFailed(false);
                setIsLoanOfferError(false);
                setIsEmpty(true);
                toast.success("Loan Disbursed Successfully!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }}
        catch(error){
            setIsLoanOfferError(true);
            toast.error("Error Disbursing Loan!",{
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setIsLoading(false);
        }
    };


    return (

        <>
            <TopBar/>
            <div id={"coeijcsnk"} className={"ml-48 my-4"}>
                <BreadCrumbs/>
            </div>

            <div id={'hewaidjs'} className={`${styles.wrapper} mx-auto`}>
                <div id={'ahdska'} className={styles.top}>
                    <h1 id={`aklflk`} className={styles.label2}>TRAINEE DETAILS</h1>
                </div>
                <div className=" w-full flex flex-col justify-center pb-5 pl-4 ">
                    <div className="grid grid-cols-2 mt-6 mx-8 gap-8">
                        <div id={`hjslhkjdf`}>
                            <h2 id={`jalfj`} className="text-base font-normal text-[#72757A]">
                                Trainee Name
                            </h2>
                            <p id={`ajkkfah`}
                               className="font-bold text-md">{user?.firstName + user?.lastName}</p>
                        </div>

                        <div id={`hjslhkjdf`}>
                            <h2 id={`jhskhkjfd`} className="text-base font-normal text-[#72757A]">
                                Email
                            </h2>
                            <p id={`sknvskj`} className="font-bold text-md">{user?.email}</p>
                        </div>

                        <div id={`hjslhkjdf`}>
                            <h2 id={`kjsdhslhdkjk`} className="text-base font-normal text-[#72757A]">
                                Phone Number
                            </h2>
                            <p id={`cnjnkajkjnknjanckjs`}
                               className="font-bold text-md">{trainee?.phoneNumber}</p>
                        </div>

                        <div id={`hjslhkjdf`}>
                            <h2 id={`jdfjkshksj`} className="text-base font-normal text-[#72757A]">
                                Gender
                            </h2>
                            <p id={`skkdjljlk`} className="font-bold text-md">{trainee?.gender}</p>
                        </div>

                        <div id={`hjslhkjdf`}>
                            <h2 id={`jhskjslkjl`} className="text-base font-normal text-[#72757A]">
                                Date of Birth
                            </h2>
                            <p id={`auihciduhs`}
                               className="font-bold text-md">{utils.formatDateFromArray(trainee?.dateOfBirth)}</p>
                        </div>
                        <div id={`hjslhkjdf`}>
                            <h2 id={`khfahijsknikwe`} className="text-base font-normal text-[#72757A]">Martial
                                Status</h2>
                            <p id={`jaojaconenj`} className="font-bold text-md">{trainee?.maritalStatus}</p>
                        </div>
                        <div id={`hjslhkjdf`}>
                            <h2 id={`sjkjhksahfkhkajlhksa`} className="text-base font-normal text-[#72757A]">
                                Nationality
                            </h2>
                            <p id={`hkjasdjkhskdll`} className="font-bold text-md">{trainee?.nationality} </p>
                        </div>
                        <div id={`hjslhkjdf`}>
                            <h2 id={`jkdjhvksj`} className="text-base font-normal text-[#72757A]">
                                State of Origin
                            </h2>
                            <p id={`jsjjojoijsoj`} className="font-bold text-md">{trainee?.stateOfOrigin} </p>
                        </div>
                        <div id={`kjskdkljfawes`}>
                            <h2 id={`akjkjlsjlsd`} className="text-base font-normal text-[#72757A]">
                                State of Residence
                            </h2>
                            <p id={`alkjjdihifheuijak`}
                               className="font-bold text-md">{trainee?.stateOfResidence}</p>
                        </div>
                        <div id={`klajanaucndjkn`}>
                            <h2 id={`ajfjanidfjnjd`} className="text-base font-normal text-[#72757A]">Residential
                                Address</h2>
                            <p id={`kansjiefjnaidja`} className="font-bold text-md">{trainee?.address}</p>
                        </div>

                    </div>
                </div>

                <div id={`afyfhbfkfujk`} className={styles.top}>
                    <h1 id={`bbdsiierujf`} className={styles.label2}>TRAINEE ADDITIONAL DETAILS</h1>
                </div>
                <div id={'ncjanksj'} className=" w-full flex flex-col justify-center pb-5 pl-4 ">
                    <div id={'peqiuiods'} className="grid grid-cols-2 mt-6 mx-8 gap-8">
                        <div id={'hcdush'}>
                            <h2 id={'peuhjsd'} className="text-base font-normal text-[#72757A]">
                                Alternate Phone Number
                            </h2>
                            <p id={`sakjjskd`} className="font-bold text-md">{trainee?.alternatePhoneNumber}</p>
                        </div>
                        <div id={`lknajnijn`}>
                            <h2 id={`ncajsklad`} className="text-base font-normal text-[#72757A]">
                                Alternate Email Address
                            </h2>
                            <p id={`cjabcahbuhuce`}
                               className="font-bold text-md">{trainee?.alternateEmail}</p>
                        </div>

                        {/*<div id={`cajniwje`}>*/}
                        {/*    <h2 id={`cyasuabsuyuehd`} className="text-base font-normal text-[#72757A]">*/}
                        {/*        Alternate Contact*/}
                        {/*    </h2>*/}
                        {/*    <p id={`caucsbubdjshbchuhebuhd`}*/}
                        {/*       className="font-bold text-md">{trainee?.alternateContactAddress}</p>*/}
                        {/*</div>*/}

                        {/*<div id={`jjcajnnicsjkk`}>*/}
                        {/*    <h2 id={`najcbhbcujsbahds`} className="text-base font-normal text-[#72757A]">*/}
                        {/*        Next Of Kin Name*/}
                        {/*    </h2>*/}
                        {/*    <p id={`canciaonioajfnw9e`}*/}
                        {/*       className="font-bold text-md">{trainee?.nextOfKinFirstName + trainee?.nextOfKinLastName}</p>*/}
                        {/*</div>*/}

                        {/*<div id={`uiwaejno93fiwjen93w`}>*/}
                        {/*    <h2 id={`djiahijaikanek4e`} className="text-base font-normal text-[#72757A]">*/}
                        {/*        Next of Kin Phone Number*/}
                        {/*    </h2>*/}
                        {/*    <p id={`cuabdc8euiwwo8ei`}*/}
                        {/*       className="font-bold text-md">{trainee?.nextOfKinPhoneNumber}</p>*/}
                        {/*</div>*/}
                        {/*<div id={`caniucinjekm`}>*/}
                        {/*    <h2 id={`kacnewuouanniockew`} className="text-base font-normal text-[#72757A]">Next of Kin*/}
                        {/*        Address</h2>*/}
                        {/*    <p id={`faujfnojeiwknfifwek`}*/}
                        {/*       className="font-bold text-md">{trainee?.nextOfKinAddress}</p>*/}
                        {/*</div>*/}
                        {/*<div id={`uacjnciunjdncaiek`}>*/}
                        {/*    <h2 id={`candsijkcajiksdk`} className="text-base font-normal text-[#72757A]">*/}
                        {/*        Next of Kin Email Address*/}
                        {/*    </h2>*/}
                        {/*    <p id={`cjbaiuhjdvbhduibhjhjks`}*/}
                        {/*       className="font-bold text-md">{trainee?.nextOfKinEmailAddress} </p>*/}
                        {/*</div>*/}
                        {/*<div id={`ahbjcheawusuhjdasd`}>*/}
                        {/*    <h2 id={`cakjdncuwebcebjhbfhaj`} className="text-base font-normal text-[#72757A]">*/}
                        {/*        Next of Kin Relationship*/}
                        {/*    </h2>*/}
                        {/*    <p id={`aufuybahbuhfbuhej`}*/}
                        {/*       className="font-bold text-md">{trainee?.nextOfKinRelationship} </p>*/}
                        {/*</div>*/}

                    </div>
                </div>
                <div id={`cabuyuebhjdja`} className={styles.top}>
                    <h1 id={`abyebcweuhjds`} className={styles.label2}>TRAINING DETAILS</h1>
                </div>
                <div id={'qpidsd'} className=" w-full flex flex-col justify-center pb-5 pl-4 ">
                    <div id={'cehjdsa'} className="grid grid-cols-2 mt-6 mx-8 gap-8">
                        <div id={'pquefhjd'}>
                            <h2 id={'uoqwed'} className="text-base font-normal text-[#72757A]">
                                Institute Name
                            </h2>
                            <p id={`cabhbjhsdabkjabs`} className="font-bold text-md">{institute?.instituteName}</p>
                        </div>
                        <div id={`acybcuybhbjsbhj`}>
                            <h2 id={`ajniucancjiuiewnjk`} className="text-base font-normal text-[#72757A]">
                                Program Name
                            </h2>
                            <p id={`jcadsncijniajsinckds`}
                               className="font-bold text-md">{program?.name}</p>
                        </div>

                        <div id={`uaudsbhbuyhjd`}>
                            <h2 id={`jajscini`} className="text-base font-normal text-[#72757A]">
                                Cohort Name
                            </h2>
                            <p id={`ishdviuishd`} className="font-bold text-md">{cohort?.name}</p>
                        </div>
                    </div>
                </div>
                <div id={`nfjdsinids`} className={styles.top}>
                    <h1 id={`sivhiisho`} className={styles.label2}>LOAN DETAILS</h1>
                </div>

                <div className=" w-full flex flex-col justify-center pb-5 pl-4 ">
                    <div className="grid grid-cols-2 mt-6 mx-8 gap-8">
                        <div>
                            <h2 className="text-base font-normal text-[#72757A]">
                                Loan Amount Requested
                            </h2>
                            <p id={`caniidsjk`}
                               className="font-bold text-md">{utils.formatAmount(trainee?.totalLoanAmountRequested)}</p>
                        </div>
                        <div id={`cundsincidjdc`}>
                            <h2 id={`cidusduhdkl`} className="text-base font-normal text-[#72757A]">
                                Tuition Amount
                            </h2>
                            <p id={`cninaonos`}
                               className="font-bold text-md">{utils.formatAmount(cohort?.tuition)}</p>
                        </div>

                        <div id={`cnanjnioa`}>
                            <h2 id={'ajdceww'} className="text-base font-normal text-[#72757A]">
                                Initial Deposit
                            </h2>
                            <p id={`sfsjla`}
                               className="font-bold text-md">{utils.formatAmount(trainee?.initialDeposit)}</p>
                        </div>
                    </div>
                </div>

                <div id={"rightGridContainer"} className={` " w-full flex justify-end  py-4 pr-[28px] "`}>
                    <button
                        id={`titleIsButtonNeededButton`}
                        // className={`text-white text bg-[#0D9B48] w-[220px] h-[30px] rounded-[4px] cursor-pointer border-0 px-10  pb-10`}
                        className={`text-white text bg-[#D0D5DD] w-[220px] h-[30px] rounded-[4px] border-0 px-10  pb-10 cursor-not-allowed`}
                        // onClick={handleSubmit}
                        type={`submit`}
                    >
                        <p id={`titlePtag`} className='w-[150px] h-[20px] mt-2'>Disburse Loan</p>
                    </button>
                </div>
            </div>

        </>


    )

}

export default ViewLoanOfferIndex;
