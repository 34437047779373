import ApiInstance from "./api";
import {store} from "../redux/store";

class ApiRequest{
    public async request(endpoint: string, method: 'get' | 'post' | 'delete' | 'put' | 'patch', data?: any): Promise<any> {
        try {
            // @ts-ignore
            const apiInstance = new ApiInstance(store.getState().auth.token?.access_token);
            const response = await apiInstance.getInstance()[method](endpoint, data);
            return response.data;
        } catch (err) {
            throw err;
        }

    }
}

const apiRequest = new ApiRequest();
export default apiRequest;